import React, { useEffect, useState, useContext } from 'react'
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import UserContext from '../../../context/UserContext'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFaultId } from '../../../store/actions';

function MyFaults(props) {
    const { user, getUser } = useContext(UserContext);
    const [fault, setFault] = useState([]);

    useEffect(() => {
        if (!user) {
            setFault([]);
            return;
        }
        const getFault = async () => {
            await getUser();

            await axios.get(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/list/user/${user}`)
                .then(res => {
                    setFault(res.data.sort((a, b) => {
                        return new Date(b.date.split("-").reverse().join("-")) - new Date(a.date.split("-").reverse().join("-"))
                    }))
                })
        }
        getFault();
    }, [user, getUser])

    const onClickDetail = async (id) => {
        await props.setFaultId(id);
        window.location.replace("/fault-detail/" + id);
    }

    const onClickUpdate = async (id) => {
        await props.setFaultId(id);
        window.location.replace("/fault-update/" + id);
    }

    const data = {
        columns: [
            {
                label: 'Tren Adı',
                field: 'trainname',
                sort: 'asc',

            },
            {
                label: 'Üst Arıza',
                field: 'topfault',
                sort: 'asc',
            },
            {
                label: 'Alt Arıza',
                field: 'subfault',
                sort: 'asc',
            },
            {
                label: 'Tarih',
                field: 'date',
                sort: 'asc',
            },
            {
                label: 'İşlemler',
                field: 'operation',
                sort: 'asc',
            },
        ],

        rows: fault.map(i => {
            return {
                trainname: i.trainInformation[0].name,
                topfault: i.topFaultInformation[0].name,
                subfault: i.subFault,
                date: i.date,
                operation: <>
                    <button className='btn update-button mr-2' onClick={() => onClickDetail(i._id)}>
                        Detay
                    </button>
                    <button className='btn delete-button mr-2' onClick={() => onClickUpdate(i._id)}>
                        Güncelle
                    </button>
                </>
            }
        })

    };


    return (
        <div className='back-right'>
            <div className='part-header'>
                Arızalarım
            </div>
            <div className='part-body'>
                <MDBDataTable
                    entriesOptions={[5, 10, 25]}
                    hover
                    striped
                    bordered
                    data={data ? data : null}
                    searchLabel='Filtrele'
                    entrieslabel='aasdasdasd'
                    infoLabel={["", "-", "sonuç", ""]}
                    paginationLabel={["Geri", "İleri"]}
                    noRecordsFoundLabel='Sonuç bulunamadı'
                    noBottomColumns={true}
                />
            </div>
        </div>
    )
}
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setFaultId
    }, dispatch)
)

export default connect(null, mapDispatchToProps)(MyFaults);