import React, { Component } from 'react'
import Schema from '../../Images/topraklamasema.jpg'
import Footer from '../Footer';
import Navbar from '../Navbar'
class Ground extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2  >
                        TOPRAKLAMA
                    </h2>
                    <p>
                        Trende personelin yüksek voltajlı cihazlara voltaj veriliyken erişmelerini önleyen uygun kilit sistemleri vardır. Koruma sistemi aşağıda açıklanan şekilde, karşılıklı olarak kilitlenen çeşitli anahtarları kullanan bir topraklama kombinasyonuna dayalıdır.
                    </p>
                    <h3 className="text-center">
                        Tren topraklama sekansı
                    </h3>
                    <ul>
                        <li>
                            M anahtarını almak için sürüş vagonunu devre dışı bırakın. M anahtarı topraklama sekansı sırasında pantografların inik durumda kalmasını sağlamak için üçlü valfa (U10) erişim sağlar.
                        </li>
                        <li>
                            M anahtarını üçlü valfa (U10) takarak kolu 90º sola, yük boşaltma pozisyonuna çevirin ve iki B anahtarı (TCB vagonuysa) veya iki R anahtarını (TCF vagonuysa) ve M anahtarını çıkartın.
                        </li>
                    </ul>

                    <h3 className="text-center">
                        Topraklama Sekansı Şeması
                    </h3>
                    <div className="w-75 mx-auto text-center">
                        <img src={Schema} alt={Schema} />
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Ground;