import React, { Component } from 'react'
import Footer from '../../../Footer';
import Navbar from '../../../Navbar'

class FirstSafetyCircuit extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                <h4>
                    Birincil Emniyet Devresi:
                </h4>
                <p>Birincil emniyet devresi şu koşullardan biri ortaya çıktığında acil durum freni tatbik edilir.</p>
                <ul>
                    <li>
                        Mod seçici kolu N (nötr) pozisyonuna getirildiğinde.
                    </li>
                    <li>
                        Ana kumandada sevk/fren kolu EM (acil durum fren) pozisyonuna getirildiğinde.
                    </li>
                    <li>
                        Acil durum mantar düğme aktive edildiğinde.
                    </li>
                    <li>
                        ERTMS ile acil durum fren komutu gönderildiğinde.
                    </li>
                    <li>
                        ATS ile acil durum fren komutu gönderildiğinde.
                    </li>
                    <li>
                        ATMS ile acil durum fren komutu gönderildiğinde.
                    </li>
                    <li>
                        İkincil emniyet devresi koşulları yerine getirilmediğinde.
                    </li>
                    <li>
                        Sürücü tarafından koşum takımı çözme komutu gönderildiğinde.
                    </li>
                    <li>
                        Totman modülü (DM) aktive edilmediğinde.
                    </li>
                    <li>
                        Ayrıca PAE yolcu alarm devresi aktif edildiğinde. Tren tünel içinde ise fren uygulamaz.
                    </li>
                </ul>

                <h4>
                    İkincil Emniyet Devresi:
                </h4>
                <b>İkincil emniyet devresi tüm tren boyunca uzanır ve şu koşulların yerine getirilmesini sağlar:</b>
                <ul>
                    <li>
                        MP minimum basıncı, her iki kabinde tespit edilir.
                    </li>
                    <li>
                        BP minimum basıncı, her iki kabinde tespit edilir.
                    </li>
                    <li>
                        Tüm vagonlarda BCU’lar tarafından acil durum fren komutu gönderilmemiş olması.
                    </li>
                    <li>
                        Tüm vagonlarda park freninin serbest olması.
                    </li>
                </ul>
                <p> Kabin tavanı sol panelinde bulunan ACİL DURUM KAPI KONTROL baypas anahtarının (61S03) aktive edilmesi aktif olan kabinde tüm ikincil devre koşullarını geçersiz kılar.
                </p>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default FirstSafetyCircuit;