import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar';

class CafTrainTest extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2>
                        CAF FREN TESTLERİ
                    </h2>
                    <p>Bu bölümde konular özet şeklinde açıklanacak. Ayrıntılı bilgi doküman ve şemalarda vardır.</p>
                    <h5>KISALTMALAR</h5>
                    <ul>
                        <li>
                            BP: Kondivit basıncı
                        </li>
                        <li>
                            MP: Ana boru basıncı
                        </li>
                        <li>
                            SIFA: Acil fren durumunda BP yi boşaltan valf
                        </li>
                        <li>
                            BCU: Fren kontrol ünitesi
                        </li>
                        <li>
                            ABT : Otomatik fren testi
                        </li>
                    </ul>
                    <h6>
                        6500 serisi CAF setlerinle 5 çeşit fren vardır.
                    </h6>
                    <ul>
                        <li>
                            SERVİS FRENİ
                        </li>
                        <li>
                            DİNAMİK FREN
                        </li>
                        <li>
                            ACİL FREN (EB)
                        </li>
                        <li>
                            YARDIMCI FREN
                        </li>
                        <li>
                            PARK FRENİ
                        </li>
                        <li>
                            HOLDING BREAK( TUTMA FRENİ)
                        </li>

                    </ul>
                    Setler servise başlamadan önce TCB veya TCF aktif kabinden mutlaka ABT (Otomatik fren testi) yapılması gerekmektedir.

                    <h5>ABT Neyi kontrol eder? ABT nasıl yapılır?</h5>
                    <ul>
                        <li>
                            TCB ve TCF z1 fren panelleri arasındaki kondivit hattında hava kaybı olup olmadığını test eder.
                        </li>
                        <li>
                            BP kaçağı
                        </li>
                        <li>
                            BP sürekliliği ve güvenlik döngüsü ile BCU müdahalesi
                        </li>
                        <li>
                            Aktif kabin ve son kabindeki SIFA valfleri 8.1 ve 8.2 yi test eder
                        </li>
                        <li>
                            BCU nun emniyet devresini açıp açmayacağını
                        </li>
                        <h6>
                            Nasıl Yapılır:
                        </h6>
                        <ul>
                            <li>
                                MP basıncı 6,5 bardan yüksek olmalıdır.
                            </li>
                            <li>
                                Yön kolu ilerde olmalı.
                            </li>
                            <li>
                                Fren kolu N de olmalı.
                            </li>
                            <li>
                                Sıcas da menü 9-2 de abt test butonuna basılmalı ve sürecin bitmesi beklenmeli. Test başarılı olursa ikon <b>yeşil</b> olacaktır.
                            </li>

                        </ul>
                        <h6>
                            Test başarısız ABT geçmiyor:
                        </h6>
                        Körük arası küresel vanalarda ve Z1 panellerde hava kaybı araştırılmalıdır.
                    </ul>

                </div>
                <Footer />
            </div>
        )
    }
}
export default CafTrainTest;