import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { selectFilterByTrain, selectTrainList } from '../../../store/selectors'
import { setFilterByTrain } from '../../../store/actions'
import axios from 'axios'

class Filter extends Component {

    state = {
        "train": "",
        "faultlist": "",
        "date1": "",
        "date2": "",
    }

    componentDidMount = async () => {

        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/list")
            .then(async (res) => {
                this.setState({
                    faultlist: res.data
                })
                await this.props.setFilterByTrain(res.data)
            }).catch(()=>{
                window.location.replace("/login")
            })
    }

    onChangeTrain = e => {
        this.setState({
            train: e.target.value
        });
    }

    onChangeDate = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onClearDateFields = () => {
        this.setState({
            date1: "",
            date2: ""
        })
    }

    onClickSave = async (e) => {
        e.preventDefault();
        const { train, date1, date2 } = this.state;
        let filterFaultList;

        if (date1 !== "" && date2 !== "") {
            if (train !== "") {
                filterFaultList = this.state.faultlist.filter(fault => fault.trainNumber === train);
                filterFaultList = filterFaultList.filter(f => new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())
                await this.props.setFilterByTrain(filterFaultList);
            }

            else {
                filterFaultList = this.state.faultlist.filter(f => new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())
                await this.props.setFilterByTrain(filterFaultList)
            }
        }

        else if (date1 === "" && date2 === "") {
            if (train !== "") {
                filterFaultList = this.state.faultlist.filter(fault => fault.trainNumber === train);
                await this.props.setFilterByTrain(filterFaultList);
            }

            else {
                await this.props.setFilterByTrain(this.state.faultlist)
            }
        }

        else {
            alert("Lütfen başlangıç tarihini ve bitiş tarihini seçin.")
        }
    }

    render() {
        return (
            <div className='px-3 pt-5 mb-5'>
                <div className="form-group row">
                    <label htmlFor="LeftTrain" className="col-sm-3 col-form-label">
                        Tren Numarası:
                    </label>
                    <div className="col-sm-8" >
                        <select className="col-sm-12 form-select p-2" id="LeftTrain" name="LeftTrain"
                            value={this.state.train} onChange={this.onChangeTrain} >
                            <option value="">Tüm trenler</option>
                            {
                                this.props.trains.map(train => (
                                    <option key={train._id} value={train._id}>{train.name}</option>
                                ))
                            }

                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="date1" className="col-sm-3 col-form-label">
                        Başlangıç Tarihi:
                    </label>
                    <div className="col-sm-8" >
                        <input type="date" max={this.state.date2} className="col-sm-12" rows="6" name="date1" value={this.state.date1} onChange={this.onChangeDate} required />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="date2" className="col-sm-3 col-form-label">
                        Bitiş Tarihi:
                    </label>
                    <div className="col-sm-8" >
                        <input type="date" min={this.state.date1} className="col-sm-12" rows="6" name="date2" value={this.state.date2} onChange={this.onChangeDate} required />
                    </div>
                </div>

                <div className=" row text-right mt-4">
                    <div className="col-sm-11">
                        <button className="btn right-part-clear-date mr-2 " onClick={this.onClearDateFields}>
                            Tarihi Temizle
                        </button>
                        <button className="btn right-part-button" onClick={this.onClickSave}>
                            Filtrele
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    trains: selectTrainList(),
    faultlist: selectFilterByTrain(),
});

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({ setFilterByTrain }, dispatch)
    )
);


export default connect(mapStateToProps, mapDispatchToProps)(Filter)