import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import Footer from './Footer';
import Navbar from './Navbar';
import UserContext from '../context/UserContext';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserAuthority } from '../store/actions';
import axios from 'axios';

const USERS = [
    { id: "caf-tren-testleri-abt", name: 'CAF TREN TESTLERİ - ABT' },
    { id: "servis-ve-acil-fren-testi", name: 'SERVİS FRENİ TESTİ - ACİL FREN (B15 MANTAR BUTON)' },
    { id: "yardimci-dinamik-holding-fren-testi", name: 'YARDIMCI FREN TESTİ - DİNAMİK FREN TESTİ - HOLDİNG BREAK(TUTMA FRENİ)' },
    { id: "wsp-bcu-ariza", name: 'WSP TESTİ-BCU DA ARIZA TAKİBİ' },
    { id: "aracin-calistirilmasi-ariza", name: 'ARACIN ÇALIŞTIRILMASI VE ARIZA TESPİTİ' },
    { id: "uyari-tasit-kabin-emniyet", name: 'UYARILAR İKAZLAR - TAŞITIN ÇALIŞTIRILMASINDA ÖNCELİKLER - KABİNDE GÖSTERGELER - EMNİYET DÜZENLEMELERİ' },
    { id: "topraklama", name: 'TOPRAKLAMA' },
    { id: "kabini-aktif-soguk-park", name: 'KABİNİ AKTİF (SICAK) YAPMAK - SOĞUK PARK' },
    { id: "calistirmadan-önce-genel-muayene", name: 'ÇALIŞTIRMADAN ÖNCE GENEL MUAYENE' },
    { id: "pantograf-hscb", name: 'PANTOGRAF-HSCB' },
    { id: "tren-surulmesi", name: 'TRENİN SÜRÜLMESİ' },
    { id: "tren-surulmesi-1", name: 'YARDIMCI FREN MODU - AZALTILMIŞ MOD BYPASS - PARK FRENİ' },
    { id: "tren-surulmesi-2", name: 'TOTMAN MODÜLÜ - OTOMATİK FREN TESTİ - MANUEL FREN TESTİ' },
    { id: "tren-surulmesi-3", name: 'ABT UYGULAMASI - ACİL DURUM ÇALIŞTIRMA' },
    { id: "kapi-acil-durum-fren-devresi", name: 'KAPI DEVRESİ - ACİL DURUM FREN DEVRESİ - YOLCU ACİL DURUM ALARM DEVRESİ' },
    { id: "birincil-ikincil-emniyet-devresi", name: 'BİRİNCİL-EMNİYET-DEVRESİ - İKİNCİL-EMNİYET-DEVRESİ' },
    { id: "acil-atms-bypass", name: 'TREN SÜRÜLMESİ AZALTILMIŞ MOD BYPASS - ATMS BAYPAS - ACİL DURUM MODU' },
    { id: "cer-aktif-bypass", name: 'CER AKTİF BYPASS' },
    { id: "yardimci-redresör-batarya-sarj-arizasi", name: 'YARDIMCI REDRESÖR ARIZASI - BATARYA ŞARJ CİHAZI ARIZASI - BCU ARIZALARI' },
    { id: "mp-kirilma-bp-arizasi", name: 'PNÖMATİK SİSTEM AZALTILMIŞ HALDE ÇALIŞTIRMA (Mp Kırılma) - BP ARIZASI(İkincil Süspansiyonda Arıza)' },
    { id: "mvb", name: 'MVB VE MVB ARIZALARI' },
    { id: "hvac-sogutma-dongusu-sigorta-kontrol", name: 'HVAC SOĞUTMA DÖNGÜSÜ - SİGORTA PANOSU - KONTOL KARTLARINDAN SİSTEMİN İZLENMESİ' },
    { id: "hvac-sogutma-isitma", name: 'HVAC SOĞUTMA VE ISITMA TESTİ' },
    { id: "hvac-manuel-faults", name: 'HVAC KLİMANIN MANUEL ÇALIŞTIRILMASI - MUHTELİF KLİMA ARIZALARI' },
    { id: "ip-program", name: '65000 DE KULLANILAN IPLER VE PROGRAMLAR' },
    { id: "harici-besleme", name: 'HARİCİ BESLEME' },
    { id: "atms-ariza", name: 'ATMS ARIZALARI' },
    { id: "sicaklik-esikleri", name: 'SICAKLIK EŞİKLERİ' },
    { id: "fren-arizalari-1", name: 'MGS2 Sistem Devre Dışı/DNRA Sistem Devre Dışı - VAGON DOLAYLI FRENDE KALIYOR' },
    { id: "fren-arizalari-2", name: 'DCL BASINÇ ARIZASI - KONDİVİT BASINCI YÜKSELMİYOR - ABT TESTİNİ GEÇMİYOR' },
    { id: "kaliper-yolcu-kapi-izole", name: 'KALİPER SONSUZ DİŞLİ ve YOLCU KAPILARI İZOLE KARESİ' },
    { id: "yolcu-giris-kapi-arizalari", name: 'YOLCU GİRİŞ KAPILARI VE ARIZALARI' },
    { id: "yolcu-kapi-switch", name: 'YOLCU KAPILARI VE SWİTCHLER' },
    { id: "wc-egitim", name: 'WC EĞİTİMİ(Ledlerin Durumu)' },
    { id: "wc-egitim-ariza", name: 'WC EĞİTİMİ ARIZALARIN NEDENLERİ VE GİDERME YÖNTEMLERİ' },
    { id: "set-soguk", name: 'SETİN SOĞUK ÇEKİLMESİ' },
    { id: "imdat-modu", name: 'İMDAT MODU' },
    { id: "ertmsledcontrol", name: 'ERTMS LED KONTROL' },
    { id: "cafht65000evclog", name: 'CAF HT65000 SICAS, CESIS, JRU, EVC LOG ALMA' },
    { id: "equipment-controls", name: 'ERTMS EKİPMAN KONTROL' },
    { id: "ertms-onboard", name: 'ERTMS ONBOARD LED GÖSTERGE DÖKÜMANI' },
    { id: "connector-connection", name: 'ERTMS KONNEKTÖR BAĞLANTILARI' },
    { id: "evc-save", name: 'EVC SİSTEMİNDEN KAYIT ALMA' },
];

function HomePage(props) {
    const [name, setName] = useState('');
    const [foundUsers, setFoundUsers] = useState(USERS);
    const [yol, setYol] = useState("");
    const { user, getUser } = useContext(UserContext);

    useEffect(() => {
        if (!user) {
            return;
        }
        const getAuthority = async () => {
            await getUser();
            await axios.get(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/list/${user}`)
                .then(res => {
                    props.setUserAuthority(res.data.authority)
                }).catch(() => {
                    window.location.replace("/login")
                })
        }
        getAuthority();
    }, [user])



    const filter = async (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = USERS.filter((user) => {
                return user.name.indexOf(keyword.toUpperCase()) >= 0
            });

            setFoundUsers(results);
        } else {
            setFoundUsers(USERS);
        }
        setName(keyword);
    };

    const onClickInput = (n, y) => {
        setName(n)
        setYol(y)
    }

    const onChangeSelect = (e) => {
        setYol(e.target.value)
    }

    return (
        <div>
            <Navbar />
            <div className="w-75 mx-auto p-3">
                <div className="filter-part">
                    <div className="container">
                        <input
                            type="search"
                            value={name}
                            onChange={filter}
                            className="input form-control mr-sm-2"
                            placeholder="Filtre"
                        />
                        <div className={name === "" ? "d-none" : "user-list"}>

                            {foundUsers && foundUsers.length > 0 ? (
                                foundUsers.map((user) => (
                                    <li key={user.name} className="user" >
                                        <span className="user-name" onClick={() => onClickInput(user.name, user.id)}>{user.name}</span>
                                    </li>
                                ))
                            ) : (
                                <p>Sonuç Bulunamadı!</p>
                            )}
                        </div>
                    </div>

                    <select className="select form-control mr-2" name="cars" id="cars" value={yol} onChange={onChangeSelect}>
                        <option value="">65000 ile ilgili konu başlıklarını seçiniz</option>
                        {

                            USERS.map(user => {
                                return (
                                    <option value={user.id} key={user.id} >{user.name}</option>
                                )

                            })
                        }
                    </select>

                    <Link to={"/" + yol} className="btn button">Ara</Link>
                </div>

                <div className="mb-3">
                    <ul>
                        <li>
                            <Link to="/caf-tren-testleri-abt" >
                                CAF TREN TESTLERİ - ABT
                            </Link>
                            <ul>
                                <li>
                                    <Link to="/servis-ve-acil-fren-testi">SERVİS FRENİ TESTİ-ACİL FREN (B15 MANTAR BUTON)</Link>
                                </li>
                                <li>
                                    <Link to="/yardimci-dinamik-holding-fren-testi">YARDIMCI FREN TESTİ-DİNAMİK FREN TESTİ-HOLDİNG BREAK(TUTMA FRENİ)</Link>
                                </li>
                                <li>
                                    <Link to="/wsp-bcu-ariza">WSP TESTİ-BCU DA ARIZA TAKİBİ</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/caf-tren-testleri-abt" aria-current="page">
                                ERTMS
                            </Link>
                            <ul>
                                <li>
                                    <Link to="/ertmsledcontrol">ERTMS LED KONTROL</Link>
                                </li>
                                <li>
                                    <Link to="/cafht65000evclog">CAF HT65000 SICAS, CESIS, JRU, EVC LOG ALMA</Link>
                                </li>
                                <li>
                                    <Link to="/equipment-controls">ERTMS EKİPMAN KONTROL</Link>
                                </li>
                                <li>
                                    <Link to="/ertms-onboard">ERTMS ONBOARD LED GÖSTERGE DÖKÜMANI</Link>
                                </li>
                                <li>
                                    <Link to="/connector-connection">ERTMS KONNEKTÖR BAĞLANTILARI</Link>
                                </li>
                                <li>
                                    <Link to="/evc-save">EVC SİSTEMİNDEN KAYIT ALMA</Link>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <Link to="/aracin-calistirilmasi-ariza">
                                ARACIN ÇALIŞTIRILMASI VE ARIZA TESPİTİ
                            </Link>
                            <ul>
                                <li>
                                    <Link to="/uyari-tasit-kabin-emniyet">UYARILAR İKAZLAR - TAŞITIN ÇALIŞTIRILMASINDA ÖNCELİKLER - KABİNDE GÖSTERGELER - EMNİYET DÜZENLEMELERİ</Link>
                                </li>
                                <li>
                                    <Link to="/topraklama">TOPRAKLAMA</Link>
                                </li>
                                <li>
                                    <Link to="/calistirmadan-önce-genel-muayene">ÇALIŞTIRMADAN ÖNCE GENEL MUAYENE</Link>
                                </li>
                                <li>
                                    <Link to="/kabini-aktif-soguk-park">KABİNİ AKTİF (SICAK) YAPMAK - SOĞUK PARK</Link>
                                </li>
                                <li>
                                    <Link to="/pantograf-hscb">PANTOGRAF - HSCB</Link>
                                </li>
                                <li>
                                    <Link to="/tren-surulmesi">TRENİN SÜRÜLMESİ</Link>
                                    <ul>
                                        <li>
                                            <Link to="/tren-surulmesi-1">YARDIMCI FREN MODU - AZALTILMIŞ MOD BYPASS - PARK FRENİ</Link>
                                        </li>
                                        <li>
                                            <Link to="/tren-surulmesi-2">TOTMAN MODÜLÜ - OTOMATİK FREN TESTİ - MANUEL FREN TESTLERİ</Link>
                                        </li>
                                        <li>
                                            <Link to="/tren-surulmesi-3">ABT UYGULAMASI - ACİL DURUM ÇALIŞTIRMA</Link>
                                            <ul>
                                                <li>
                                                    <Link to="/kapi-acil-durum-fren-devresi">Kapı Devresi - Acil Durum Fren Devresi - Yolcu Acil Durum Alarm Devresi</Link>
                                                </li>
                                                <li>
                                                    <Link to="/birincil-ikincil-emniyet-devresi">Birincil Emniyet Devresi - İkincil Emniyet Devresi</Link>
                                                </li>
                                                <li>
                                                    <Link to="/acil-atms-bypass">Azaltılmış Mod Baypas - ATMS Baypas - Acil Durum Modu</Link>
                                                </li>
                                                <li>
                                                    <Link to="/cer-aktif-bypass">Cer Aktif Baypas</Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to="/sicas" aria-current="page">SICASTA GÖRÜLEN ARIZALAR VE İZLENECEK YÖNTEMLER</Link>
                                            <ul>
                                                <li>
                                                    <Link to="/yardimci-redresör-batarya-sarj-arizasi">YARDIMCI REDRESÖR ARIZASI - BATARYA ŞARJ CİHAZI ARIZASI - BCU ARIZALARI</Link>
                                                </li>
                                                <li>
                                                    <Link to="/mp-kirilma-bp-arizasi">PNÖMATİK SİSTEM AZALTILMIŞ HALDE ÇALIŞTIRMA (Mp Kırılma) - BP ARIZASI(İkincil Süspansiyonda Arıza)</Link>
                                                </li>
                                            </ul>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/mvb">MVB ve MVB Arızaları</Link>
                        </li>

                        <li>
                            <Link to="/tren-surulmesi" aria-current="page">HVAC (KLİMA SİSTEMİ)</Link>
                            <ul>
                                <li>
                                    <Link to="/hvac-sogutma-dongusu-sigorta-kontrol">SOĞUTMA DÖNGÜSÜ - SİGORTA PANOSU - KONTOL KARTLARINDAN SİSTEMİN İZLENMESİ</Link>
                                </li>
                                <li>
                                    <Link to="/hvac-sogutma-isitma">SOĞUTMA TESTİ - ISITMA TESTİ</Link>
                                </li>
                                <li>
                                    <Link to="/hvac-manuel-faults">HVAC KLİMANIN MANUEL ÇALIŞTIRILMASI - MUHTELİF KLİMA ARIZALARI</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/ip-program">65000'DE KULLANILAN IPLER VE PROGRAMLAR</Link>
                        </li>
                        <li>
                            <Link to="/harici-besleme">HARİCİ BESLEME</Link>
                        </li>
                        <li>
                            <Link to="/atms-ariza">ATMS ARIZALARI</Link>
                            <ul>
                                <li>
                                    <Link to="/sicaklik-esikleri">Sıcaklık Eşikleri</Link>
                                </li>
                                <li>
                                    <Link to="/fren-arizalari" aria-current="page">Fren Arızaları</Link>
                                    <ul>
                                        <li>
                                            <Link to="/fren-arizalari-1">MGS2 Sistem Devre Dışı/DNRA Sistem Devre Dışı - VAGON DOLAYLI FRENDE KALIYOR</Link>
                                        </li>
                                        <li>
                                            <Link to="/fren-arizalari-2">DCL BASINÇ ARIZASI - KONDİVİT BASINCI YÜKSELMİYOR - ABT TESTİNİ GEÇMİYOR</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/kaliper-yolcu-kapi-izole">KALİPER SONSUZ DİŞLİ ve YOLCU KAPILARI İZOLE KARESİ</Link>
                        </li>
                        <li>
                            <Link to="/yolcu-giris-kapi-arizalari">YOLCU GİRİŞ KAPILARI VE ARIZALARI</Link>
                        </li>
                        <li>
                            <Link to="/yolcu-kapi-switch">YOLCU KAPILARI VE SWİTCHLER</Link>
                        </li>
                        <li>
                            <Link to="/wc-egitim">WC EĞİTİMİ</Link>
                            <ul>
                                <li>
                                    <Link to="/wc-egitim">WC EĞİTİMİ(Ledlerin Durumu)</Link>
                                </li>
                                <li>
                                    <Link to="/wc-egitim-ariza">Arızaların Nedenleri ve Giderme Yöntemleri</Link>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <Link to="/set-soguk">SETİN SOĞUK ÇEKİLMESİ</Link>
                        </li>
                        <li>
                            <Link to="/imdat-modu">İMDAT MODU</Link>
                        </li>
                        <li>
                            <Link to="/emergency-status-shooting-process">BKM-TUR-TLM-083-V01 ACİL DURUMLARDA ÜNİTENİN ÇEKİLMESİ UYGULAMALARI</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({ setUserAuthority }, dispatch)
    )
)

export default connect(null, mapDispatchToProps)(HomePage);