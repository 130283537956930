import React, { Component } from 'react'
import LeftDown from './LeftDown'
import LeftPopup from './LeftPopup'

class Left extends Component {

    render() {
        return (
            <div className='fault-left'>
                <LeftDown/>
                <LeftPopup />
            </div>


        )
    }
}
export default Left;