import React, { Component } from 'react'
import AddTopFaults from './AddTopFaults';
import UpdateTopFault from './UpdateTopFault';

class LeftSide extends Component {
    render() {
        return (
            <div className='left-train-part'>
                <AddTopFaults />
                <UpdateTopFault />
            </div>
        )
    }
}
export default LeftSide;