import React, { Component } from 'react'
import Chart from 'react-google-charts';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectFaultList, selectTrainList } from '../../../store/selectors';

class ByFaultsPie extends Component {

    pie = () => {
        let dat = [['Train', 'Fault counts']]
        let count = "";
        this.props.trains.forEach(train => {
          count = this.props.faultlist.filter(fault => fault.trainNumber === train._id).length
          dat.push([train.name,count])
        })
        return dat
    }

    render() {
        return (
            <div className='px-3 pb-3'>
                 <Chart
                    width={'100%'}
                    height={'300px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data ={
                        this.pie()
                    }        
                    options={{
                        title: 'Analiz Grafiği',
                        is3D: true,
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    trains:selectTrainList(),
    faultlist:selectFaultList(),
});

export default connect(mapStateToProps)(ByFaultsPie);