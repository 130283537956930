import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserIstatistics } from '../../../store/actions';

class FilterPart extends Component {

    state = {
        "dateStart": "",
        "dateEnd": "",
        "userList": [],
        "faultList": []
    }

    componentDidMount = async () => {
        Promise.all([
            await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/list")
                .then(async (res) => {
                    this.setState({
                        faultList: res.data
                    })
                }).catch(() => {
                    window.location.replace("/login")
                }),

            await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/list")
                .then(async (res) => {
                    this.setState({
                        userList: res.data
                    })
                })
                .catch(() => {
                    window.location.replace("/login")
                })
        ]);

        let newUserList = [];
        let personelFaults = [];

        newUserList = this.state.userList.map(user => {
            personelFaults = this.state.faultList.filter(fault => fault.userName === user._id)
            return {
                userName: user.name + user.surname,
                faultCount: personelFaults.length
            }
        }
        )
        this.props.setUserIstatistics(newUserList)
    }

    onChangeDate = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onClearDateFields = () => {
        this.setState({
            dateStart: "",
            dateEnd: ""
        })
    }

    onClickSave = () => {
        const { dateStart, dateEnd, userList, faultList } = this.state;
        let filterFaultList;
        let newUserList = [];
        let personelFaults = [];

        if ((dateEnd && !dateStart) || (!dateEnd && dateStart)) {
            alert("Başlangıç ve bitiş tarihlerinin ikisini de seçiniz.")
        }

        else if (dateEnd && dateStart) {
            filterFaultList = faultList.filter(f =>
                new Date(dateStart).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(dateEnd).getTime())
            newUserList = userList.map(user => {
                personelFaults = filterFaultList.filter(fault => fault.userName === user._id)
                return {
                    userName: user.name + user.surname,
                    faultCount: personelFaults.length
                }
            }
            )
            this.props.setUserIstatistics(newUserList)
        }


        else {
            newUserList = userList.map(user => {
                personelFaults = faultList.filter(fault => fault.userName === user._id)
                return {
                    userName: user.name + user.surname,
                    faultCount: personelFaults.length
                }
            }
            )
            this.props.setUserIstatistics(newUserList)
        }
    }

    render() {
        const { dateStart, dateEnd } = this.state;

        return (
            <div className='istatistics-filter-part'>
                <div className="form-group row">
                    <label htmlFor="dateStart" className="col-sm-3 col-form-label">
                        Başlangıç Tarihi:
                    </label>
                    <div className="col-sm-8" >
                        <input type="date" max={dateEnd} className="col-sm-12" rows="6" name="dateStart" value={dateStart} onChange={this.onChangeDate} required />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="dateEnd" className="col-sm-3 col-form-label">
                        Bitiş Tarihi:
                    </label>
                    <div className="col-sm-8" >
                        <input type="date" min={dateStart} className="col-sm-12" rows="6" name="dateEnd" value={dateEnd} onChange={this.onChangeDate} required />
                    </div>
                </div>

                <div className=" row text-right mt-4">
                    <div className="col-sm-11">
                        <button className="btn right-part-clear-date mr-2 " onClick={this.onClearDateFields}>
                            Tarihi Temizle
                        </button>
                        <button className="btn right-part-button" onClick={this.onClickSave}>
                            Filtrele
                        </button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setUserIstatistics
    }, dispatch)
)
export default connect(null, mapDispatchToProps)(FilterPart);