import React from 'react'
import EquipmentControlsFile from '../../Images/ERTMS/ERTMS-equipments-control.pdf';
import Footer from '../Footer';
import Navbar from '../Navbar';

function EquipmentControls() {
    return (
        <div>
            <Navbar />
            <div className="w-100 mx-auto p-3">
                <div className="py-5 our-documents">

                    <div id="Iframe-Master-CC-and-Rs w-50" class="set-margin set-padding set-border set-box-shadow center-block-horiz">
                        <div className="responsive-wrapper responsive-wrapper-wxh-572x612">

                            <iframe src={EquipmentControlsFile} style={{ height: "800px", width: "100%" }} title="safetypolicy">
                                <p style={{ fontSize: "110%" }}><em><strong>ERROR: </strong>
                                    An &#105;frame should be displayed here but your browser version does not support &#105;frames. </em>Please update your browser to its most recent version and try again.</p>
                            </iframe>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
export default EquipmentControls;