import axios from 'axios'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
 
import { selectUserId } from '../../../../store/selectors'

class UserInformation extends Component {
    
    state = {
        "username": "",
        "Name": "",
        "Surname": "",
        "Email": "",
        "Authority": "",
        "Message":""
    }

    componentDidMount = async () => {

        await axios.get(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/list/${this.props.userId}`)

        .then(res => this.setState({
                username: res.data.username,
                Name: res.data.name,
                Surname: res.data.surname,
                Email: res.data.email,
                Authority: res.data.authority
            }))
            .catch(() => {
                alert("Sorun oluştu. İnternet bağlantınızı kontrol ediniz.")
            })
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            Message:""
        });
    }

    onChangeAuthority = e => {
        this.setState({
            Authority: e.target.value,
            Message:""
        })
    }

    onClickSave = async(e) => {
        e.preventDefault();
        const {Name,Surname,Email,Authority} = this.state;

        await axios.put(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/update/${this.props.userId}`,{
            Name: Name,
            Surname: Surname,
            Email: Email,
            Authority: Authority
        })
        .then(res => {
            this.setState({
                Message:res.data.Message
            })
        })
        .catch(()=>{
            alert("İnternet bağlantınızı kontrol ediniz. Sorun yaşanmaya devam ederse yetkiliye bildiriniz.")
        })
    }

    render() {
        const { username, Name, Surname, Email, Authority, Message } = this.state;
        return (
            <div className='back-left'>

                <div className='part-header'>
                    Bilgileri Güncelle
                </div>

                <div className='part-body'>
                    <form onSubmit={this.onClickSave}>
                    {Message ? <div className="alert alert-success">{Message}</div> : <div></div>}
                        <div className="form-group row">
                            <label htmlFor="UserName" className="col-sm-3 col-form-label">
                                Kullanıcı Adı:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control" id="UserName" name="UserName" value={username} disabled />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Name" className="col-sm-3 col-form-label" >
                                İsim:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control pt-1" id="Name" name="Name"
                                    value={Name} onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Surname" className="col-sm-3 col-form-label" >
                                Soyisim:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control pt-1" id="Surname" name="Surname"
                                    value={Surname} onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Email" className="col-sm-3 col-form-label" >
                                Email:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="email" className="form-control pt-1" id="Email" name="Email"
                                    value={Email} onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Authority" className="col-sm-3 col-form-label">
                                Yetki:
                            </label>
                            <div className="col-sm-9" >
                                <select className="col-sm-12 form-select p-2" id="Authority" name="Authority"
                                    value={Authority} onChange={this.onChangeAuthority}>
                                    <option value="Manager">Yönetici</option>
                                    <option value="User">Kullanıcı</option>
                                </select>
                            </div>
                        </div>

                        <div className=" row text-right mt-4">
                            <div className="col-sm-12">
                                <button type="submit" className="btn right-part-button">
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    userId:selectUserId(),
})

export default connect(mapStateToProps)(UserInformation);