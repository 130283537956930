import React, { Component } from 'react'
import ImageThree from '../../Images/Image-3.PNG'
import ImageFour from '../../Images/Image-4.PNG'
import Footer from '../Footer'
import Navbar from '../Navbar'
class WarningVehicle extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h3>
                        UYARILAR, İKAZLAR
                    </h3>
                    <p className="text-danger font-weight-bold">
                        UYARILAR ZORUNLU VEYA ÖNEMLİ BİLGİLERE DİKKAT ÇEKMEK İÇİNDİR
                        <ul>
                            <li>
                                Yaralanma
                            </li>
                            <li>
                                Can kaybı
                            </li>
                            <li>
                                Ekipmanın hasarı
                            </li>
                            <li>
                                Ekipmanın tahribatı
                            </li>
                            <li>
                                Uzun vadeli sağlık tehlikeleri
                            </li>
                        </ul>
                    </p>

                    <h3>
                        TAŞITIN ÇALIŞTIRILMASINDA ÖNCELİKLER
                    </h3>
                    <p className="font-weight-bold">
                        <ul>
                            <li>
                                Emniyet düzenlemeleri
                            </li>
                            <li>
                                Çalıştırmadan önce genel düzenlemeler
                            </li>
                            <li>
                                Tren durumları
                            </li>
                            <li>
                                Tren Soğuk Parkta
                            </li>
                            <li>
                                Kabin Değişim Prosedürü
                            </li>
                            <li>
                                GSM Modülünden Batarya bağlantısı
                            </li>
                            <li>
                                Trenin sürülmesi
                            </li>
                            <li>
                                Sınırlı Hız Modu
                            </li>
                            <li>
                                Yardımcı Fren Modu
                            </li>
                            <li>
                                Azaltılmış Mod
                            </li>
                            <li>
                                Park Freni
                            </li>
                            <li>
                                Totman modülü
                            </li>
                            <li>
                                Otomatik Fren Testi
                            </li>
                            <li>
                                Manüel fren testleri
                            </li>
                            <li>
                                Manüel fren testi B37 – P3 – P4
                            </li>
                            <li>
                                Manüel fren testi B15 – Acil durum mantar düğme
                            </li>
                            <li>
                                Manüel fren testi B12 – Yardımcı fren
                            </li>
                            <li>
                                Manüel fren testi B37 – Azaltılmış Mod
                            </li>
                            <li>
                                ACİL DURUM ÇALIŞTIRMASI
                            </li>
                            <li>
                                ENGEL ALGILAMA SİSTEMİ
                            </li>
                        </ul>
                    </p>

                    <h3>
                         KABİNDE GÖSTERGELER
                    </h3>

                    <div className="w-100 mx-auto d-flex">
                        <img src={ImageThree} alt={ImageThree} />
                        <img className="ml-5" src={ImageFour} alt={ImageFour} />
                    </div>
                    
                    <h3>
                        EMNİYET DÜZENLEMELERİ
                    </h3>

                    <p className="text-danger font-weight-bold">
                    Trende nominal voltaj 25.000 Vac seviyesindedir. Bir topraklama sistemi ile voltajsız bir alan oluşturulmadan aracın çatısına çıkmayın. <br/>
                    Pantograflar kalkık durumdayken :
                        <ul>
                            <li>
                            	Çatıya çıkmayın.
                            </li>
                            <li>
                                Yüksek voltajlı cihazların yakınına gitmeyin.
                            </li>
                            <li>
                                Düşük voltajlı cihazlara dokunmayın.
                            </li>
                            <li>
                                Kutuların kapaklarını çıkarmayın.
                            </li>
                        </ul>
                        Cer konvertörleri cer motorları, yardımcı konvertörlere müdahale etmeyin.
                    </p>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default WarningVehicle;