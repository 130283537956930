import React from 'react'
import Footer from '../../Footer'
import HeaderNav from '../../HeaderNav'
import Navbar from '../../Navbar'
import UserFaults from './UserFaults'
import UserInformation from './UserInformation'

export default function UserUpdate() {
    return (
        <div>
            <Navbar pageWrapId={"page-wrap"} />
            <div id="page-wrap">
                    <HeaderNav />
                    <div>

                        <div className='page-header'>
                            Kullanıcı Güncelle
                        </div>
                        
                        <div className='my-information'>
                            <UserInformation />

                            <UserFaults />
                        </div>
                    </div>
                </div >
            <Footer/>
        </div>
    )
}
