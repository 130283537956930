import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
const UserContext = createContext();

function UserContextProvider(props) {
    const [user, setUser] = useState(null);

    const getUser= async () =>{
        const userRes = await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/authentication/loggedIn");
        setUser(userRes.data);
    }

    useEffect(() => {
        getUser();  
    }, [])

    return (
        <UserContext.Provider value={{user, getUser}}>
            {props.children}
        </UserContext.Provider>
    )
}
export default UserContext;
export { UserContextProvider };