import React, { Component } from 'react'
import HeaderNav from '../HeaderNav';
import Navbar from '../Navbar';
import Information from './Information';
import MyFaults from './MyFaults';
import Footer from '../Footer';

class MyInformation extends Component {

    render() {
        return (
            <div>
                <Navbar pageWrapId={"page-wrap"} />

                <div id="page-wrap">
                    <HeaderNav />
                    <div>

                        <div className='page-header'>
                            Bilgilerim
                        </div>
                        
                        <div className='my-information'>
                            <Information />
                            <MyFaults />
                        </div>
                    </div>
                </div >
                <Footer/>
            </div >
        )
    }
}
export default MyInformation;