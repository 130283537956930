import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

export default class HelpMode extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h3 className="w-100 text-center">
                        TRENİN İMDAT MODUNDA SÜRÜLMESİ
                    </h3>
                    <ul>
                        <li>
                            MBV sistemi çöktüğünde veya 2 SICAS'ın da çökmesi durumunda tren hızlanma ve fren uygulamaları kontrol dışı kalır.
                        </li>
                        <li>
                            MVB'nin çöktüğü vagondan sonraki vagonların aydınlatmaları söner, klimalar devreden çıkar.
                        </li>
                        <li>
                            Trenin güvenli alana çekilebilmesi için <b>İMDAT MODU</b> kullanılır.
                        </li>
                    </ul>
                    <h3 className="w-100 text-center">
                    İMDAT MODUNA HAZIRLANMASI
                    </h3>
                    <ul>
                        <li>
                        Üst panel sağ taraftaki İmdat Modu anahtarı EM1 e çevrilir. SICAS kapanır. 
                        </li>
                        <li>
                        İmdat Modu anahtarı EM2 ye çevrilir. Dar pantoğraflar kalkar. Sonra EM1 e alınır.
                        </li>
                        <li>
                        Azaltılmış Mod By pass edilir. 
                        </li>
                        <li>
                        Yön kolu ileri alındığında kondivit dolmaya başlar.
                        </li>
                        <li>
                        Gaz kolu %50 ye alındığında set hareket eder. ERTMS fren uygulayabilir. ERTMS yi güvenli sürüş moduna almak gerekir. <p className="text-danger">( Kurum personeli sorumluluğundadır )</p>
                        </li>
                        <li>
                        %100  fren uygulamak için gaz kolu EB MAX‘a çekilir.
                        </li>
                    </ul>
                </div>
                <Footer />
            </div>
        )
    }
}
