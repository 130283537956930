import React, { Component } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'

class HeatThreshold extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>
                        SICAKLIK EŞİKLERİ:
                    </h2>
                    <ul>
                        <li>
                            <b>Dingil RULMANI: </b>
                            <ul>
                                <li>
                                    110 dereceye kadar normal, 110C de 20dk. uyarı verir, alarm devreye girer.
                                </li>
                                <li>
                                    130 derecede 10dk. içinde durdurur
                                </li>
                                <li>
                                    140derecede hemen durdurur
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Akselerasyon (ivmelenme): </b>3,5m/s2 uyarı başlar alarm aktif olur.
                                    4,7m/s2 de fren uygular
                        </li>

                        <li>
                        Sıcaklık eşikleri aşıldığında ilgili vagondaki 93K01 rölesi aktif olur güvenlik devresi açılır ATMS cihazının enerjisini keser ve cihaz içindeki alarm döngü kontağı açılıp alarmı devreye sokar.
                        </li>
                        <li>
                        Tüm fren –sıcaklık-hız arızaları Bojilerden ve üzerindeki ekipmandan gelir.MGS2 –DNRA olarak tanımlanır.
                        </li>
                    </ul>
                    <h6>TANIMLAR:</h6>
                    <ul>
                        <li>
                            <b>MGS2 (WSP): </b>Teker kayma önleme sistemi, motorlu ve treyler akslarda frenleme durumunda kayma durumunu izler.
                        </li>
                        <li>
                            <b>DNRA: </b>Dönmeyen tekerleri ,bloke olan aksları takip eden bir sistemdir
                        </li>
                        <li>
                        Frenleme durumunda Cer konvertörü, BCU , DUMP valfler ve  Kaliper bir bütün olarak çalışmaktadır.
                        </li>
                        <li>
                            <b>BCU: </b>Z2 panelindeki valfleri kontrol ederek tekerlere ne kadar fren basıncı uygulanacağını belirler Arızaları kaydeder. Kod şeklinde gösterir.
                        </li>
                        <li>
                            <b>DUMP WALF: </b>Tekere uygulanan fren basıncını tahliye eder.
                        </li>
                        <li>
                            <b>KALİPER: </b>Fren balatalarının takıldığı parçadır.Teker disklerini sıkıp açar.
                        </li>
                        <li>
                            <b>APLETİ: </b>Teker yüzeyinin bir noktasında aşınma-düzleşme olması durumudur.
                        </li>
                    </ul>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default HeatThreshold