import React, { Component } from 'react'
import Footer from '../../Footer';
import Navbar from '../../Navbar'

class AuxiliaryBrakeMode extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                <h2 >
                    YARDIMCI FREN MODU
                </h2>
                <ul>
                    <li>
                        Yardımcı Fren Modu, normal fren komutu başarısız olursa, örneğin aktif kabinde BCU başarısız olursa seçilir.
                    </li>
                    <li>
                        Sürücü masasında bulunan seçim anahtarı (60S02) kullanıcının Normal Moddan Yardımcı Fren Moduna geçiş yapmasını sağlar.
                    </li>
                    <li>
                        Çekiş eforu komutu ana kumandadaki (14A01) cer/fren kolu ile verilir, ama fren eforu yardımcı fren kolu (B12) ile modüle edilir.
                    </li>
                    <li>
                        Bu bilgi aktif kabinde oluşturulan YARDIMCI FREN AKTİF bilgisi tren hattı ((MVB)ile trendeki tüm BCU’lara gönderilir.
                    </li>
                    <li>
                        Bu durumda, tüm fren panelleri (Z2) çalışarak BP basıncına göre fren uygulamaktadır.
                    </li>
                    <li>
                        Normal modan Yardımcı Fren Moduna geçiş tren dururken yapılmalıdır, aksi takdirde ikincil emniyet devresi açılır ve acil durum freni uygulanır.
                    </li>
                    <li>
                        Bu sürüş modunda elektrik fren devre dışı kalır ve sadece pnömatik fren çalışır.
                    </li>
                </ul>
                <h2 className="text-center">
                AZALTILMIŞ MOD BYPASS
                </h2>
                <ul>
                    <li>
                    	MVB veri yolu çalışmazsa, tren hareket edemez, çünkü hareket veya fren komutu olmaz. Böyle durumlarda, tren Azaltılmış Moda girerek hareket ettirilebilir veya servis dışına alınabilir.
                    </li>
                    <li>
                    	Azaltılmış Mod kabin tavan sol panelinde bulunan AZALTILMIŞ MOD anahtarı (14S01) B pozisyonuna getirilerek seçilir. 
                    </li>
                    <li>
                    	Bu moda tüm çekiş ve fren kademeleri kullanılamaz cer ve fren % 50 çalışır.
                    </li>
                    <li>
                    Tren hızı 100 km/saat ile sınırlıdır
                    </li>
                </ul>

                <h2 >
                PARK FRENİ
                </h2>
                <ul>
                    <li>
                    Park freni uygulanması komutu sürücü masasındaki PARK FRENİ/AÇIK düğmesi (63S01) ile verilir. 
                     </li>
                    <li>
                    Park freni serbest bırakma komutu aktif kabinde sürücü masasındaki PARK FRENİ/KAPALI düğmesi (63S02) ile verilir. 
                    </li>
                    <li>
                    Park freni veya serbest bırakılmasında ya da lokal izlemede başarısızlık olursa, vagonun park freni sorunsuzca iptal edilebilir. Bu yalıtma işlemi ilgili vagonun fren panelinde (Z2) bulunan B65 anahtarına basılarak ve sonra her iki bojinin C10 veya C11 manüel serbest bırakma kolu kullanılarak yapılır.(Boji üstündeki kırmızı çekme halatı)
                    </li>
                    <li>
                    Tren hızı saatte 2 km altındaysa, park freni herhangi bir kabinden uygulanabilir.
                    </li>
                </ul>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default AuxiliaryBrakeMode;