import axios from 'axios';
import React, { Component } from 'react'
 
class AddUser extends Component {

    state = {
        "username": "",
        "name": "",
        "surname": "",
        "password": "",
        "passwordVerify": "",
        "email": "",
        "authority": "Manager",
        "ErrorMessage": "",
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            Message: "",
            ErrorMessage: ""
        });
    }

    onChangeAuthority = e => {
        this.setState({
            authority: e.target.value,
            Message: "",
            ErrorMessage: ""
        })
    }

    onClickSave = async (e) => {
        e.preventDefault();
        const { username, name, surname, email, authority, password, passwordVerify } = this.state;

        await axios.post("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/add/", {
            username,
            name,
            surname,
            email,
            authority,
            password,
            passwordVerify
        })
            .then(() => window.location.reload())
            .catch(err => {
                this.setState({
                    ErrorMessage: err.response.data.errorMessage
                });
            })
    }

    render() {
        const { username, name, surname, email, authority, password, passwordVerify, ErrorMessage } = this.state;
        return (
            <div className='add-user-part'>
                <div className='part-header'>
                    Kullanıcı Ekle
                </div>

                <div className='part-body'>
                    <form onSubmit={this.onClickSave}>
                        <div className="form-group row">
                            <label htmlFor="UserName" className="col-sm-3 col-form-label">
                                Kullanıcı Adı:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control" id="UserName" name="username" value={username}
                                    onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Password" className="col-sm-3 col-form-label">
                                Şifre:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="password" className="form-control" id="Password" name="password" value={password}
                                    onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="PasswordRepeat" className="col-sm-3 col-form-label">
                                Şifre(Tekrar):
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="password" className="form-control" id="PasswordRepeat" name="passwordVerify" value={passwordVerify}
                                    onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Name" className="col-sm-3 col-form-label" >
                                İsim:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control pt-1" id="Name" name="name"
                                    value={name} onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Surname" className="col-sm-3 col-form-label" >
                                Soyisim:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control pt-1" id="Surname" name="surname"
                                    value={surname} onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Email" className="col-sm-3 col-form-label" >
                                Email:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="email" className="form-control pt-1" id="Email" name="email"
                                    value={email} onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="Authority" className="col-sm-3 col-form-label">
                                Yetki:
                            </label>
                            <div className="col-sm-9" >
                                <select className="col-sm-12 form-select p-2" id="Authority" name="authority"
                                    value={authority} onChange={this.onChangeAuthority}>
                                    <option value="Manager">Yönetici</option>
                                    <option value="User">Kullanıcı</option>
                                </select>
                            </div>
                        </div>
                        {ErrorMessage ? <div className="alert alert-danger">{ErrorMessage}</div> : <div></div>}

                        <div className=" row text-right mt-4">
                            <div className="col-sm-12">
                                <button type="submit" className="btn right-part-button">
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default AddUser;