import React, { Component } from 'react';
import Footer from '../Footer';
import HeaderNav from '../HeaderNav';
import Navbar from '../Navbar';
import FilterPart from './FilterPart';
import UsersStatisticsList from './UserIstatisticsList';

export default class UsersStatistics extends Component {
  render() {
    return (
        <div>
        <Navbar pageWrapId={"page-wrap"} />

        <div id="page-wrap">
            <HeaderNav />
            <div className='user-istatistics'>
                <div className='number-parts'>
                    <div className="parts-header">
                        Arızaya Göre Analiz
                    </div>
                    <FilterPart />
                   <UsersStatisticsList />
                </div>
            </div>
        </div >
        <Footer/>
    </div >
    )
  }
}
