import React, { Component } from 'react'
import Image from '../Images/ImageMVB.PNG'
import Footer from './Footer';
import Navbar from './Navbar'
class MVB extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>
                        MVB VE MVB ARIZALARI
                    </h2>
                   <p>Çoklu veri aktarma yolu. Birden fazla sistemin tek hat üzerinden merkezi üniteye bağlanma yöntemidir.</p> 

                    <img src={Image} alt={Image}/>

                    <h4>
                        MVB ARIZALARI:
                    </h4>
                    <ul>
                        <li>
                        MVB veri yolu iletişim arızası.
                        </li>
                        <li>
                        SICAS 1-2 modül de giriş çıkış arızası.
                        </li>
                        <li>
                        Güç kaynağı yenileyici arızası.
                        </li>
                    </ul>
                    <h4>
                    ÇÖZÜM YOLLARI:
                    </h4>
                    <ul>
                        <li>
                        SICAS da sadece MVB veri yolu arızası 1-6  yazdığında  görsel olarak arızanın nereden kaynaklandığını tespit etmek mümkün değildir. Bu tespit için Windows un CMD programından bağlanıp sorunun hangi vagon ve cihazdan kaynaklandığını tespit edebiliriz.
                        </li>
                        <li>
                        Diğer arızalarda ise SICAS ın gösterdiği vagon CTlerinin MVB kartlarının  ledleri, Repeaterın ledleri ve CT güç kaynakları ledlerinden arıza tespiti yapılabilir.
                        </li>
                    </ul>

                    <h5>
                        GÜÇ KAYNAĞI YENİLEYİCİ ARIZASI:
                    </h5>
                    <p>Bu arızada ise çoğunlukla vagon MVB yenileyici arızalanmaktadır.</p>
                </div>
                <Footer/>
                </div>
        )
    }
}
export default MVB;