import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from '../Footer';
import Navbar from '../Navbar';
import ByFault from './ByFault/ByFault';
import ByTrain from './ByTrain/ByTrain';
import { setTrainList, setTopFaultList} from '../../store/actions'
import axios from 'axios';

class FaultAnalyst extends Component {

    componentDidMount = async() =>{
        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/train/list")
        .then(res => {
            this.props.setTrainList(res.data)
        }).catch(()=>{
            window.location.replace("/login")
        })

        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/list")
        .then(res => {
            this.props.setTopFaultList(res.data)
        }).catch(()=>{
            window.location.replace("/login")
        })
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="fault">
                    <ByFault />
                    <ByTrain />
                </div>
                <Footer/>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setTrainList, setTopFaultList
        },dispatch)
    )
)
export default connect(null, mapDispatchToProps)(FaultAnalyst);