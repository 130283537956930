import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectFilterByTrain, selectTopFaUltList } from '../../../store/selectors'

class FaultList extends Component {

    filter = (faultId) => {
        let newList = this.props.faultlist.filter(fault => fault.topFault === faultId)
        return newList.length
    }

    render() {
        return (
            <div className='parts-body'>
                <div className="parts-body-parts">
                    <table className="table table-striped" >
                        <thead className="thead">
                            <tr>
                                <th scope="col">Üst Arıza</th>
                                <th scope="col">Arıza Sayısı</th>
                            </tr>
                        </thead>

                        <tbody className="tbody">
                            {
                                this.props.topfaults.map(t => (
                                    <tr key={t._id}>
                                        <td>{t.name}</td>
                                        <td>{this.filter(t._id)} </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    topfaults: selectTopFaUltList(),
    faultlist: selectFilterByTrain(),
});

export default connect(mapStateToProps)(FaultList)