import React, { Component } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTopFaUltList } from '../../../store/selectors';
import axios from 'axios';

class AddSubFault extends Component {
    state = {
        "Topid": "",
        "name": "",
        "ErrorMessage": ""
    }

    onChange = e => {
        this.setState({
            name: e.target.value,
            ErrorMessage: ""
        });
    }

    onChangeTopFault = e => {
        this.setState({
            Topid: e.target.value
        })
    }

    onClickSave = async (e) => {
        e.preventDefault();

        await axios.post("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/subIssue/add", {
            subName: this.state.name,
            Topid: this.state.Topid

        }).then(() => {
            window.location.reload()
        }).catch(err => {
            this.setState({
                ErrorMessage: err.response.data.errorMessage
            })
        })
    }

    render() {
        const { name, Topid, ErrorMessage } = this.state;

        return (
            <div className='train-part'>
                <div className='part-header'>
                    Alt Arıza Ekle
                </div>

                <div className='part-body'>
                    <form onSubmit={this.onClickSave}>
                        <div className="form-group row">
                            <label htmlFor="LeftTop" className="col-sm-3 col-form-label">
                                Üst Arıza:
                            </label>
                            <div className="col-sm-9" >
                                <select className="col-sm-12 form-select p-2" id="LeftTop" name="LeftTop"
                                    value={Topid} onChange={this.onChangeTopFault} >
                                    <option value="">Tüm Üst Arızalar</option>
                                    {
                                        this.props.topFaultList.map(fault => (
                                            <option key={fault._id} value={fault._id}>{fault.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="TopFault" className="col-sm-3 col-form-label">
                                Arıza Adı:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control" id="TopFault" name="name" value={name}
                                    onChange={this.onChange} required />
                            </div>
                        </div>

                        {ErrorMessage ? <div className="alert alert-danger">{ErrorMessage}</div> : <div></div>}

                        <div className=" row text-right mt-4">
                            <div className="col-sm-12">
                                <button type="submit" className="btn right-part-button">
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    topFaultList: selectTopFaUltList(),
})

export default connect(mapStateToProps)(AddSubFault);