import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectTopFaUltList } from '../../../store/selectors'
import { MDBDataTable } from 'mdbreact';
import { bindActionCreators } from 'redux';
import { setSubFaultId } from '../../../store/actions';
import axios from 'axios';

class SubFaultList extends Component {
    state = {
        "Message": "",
        "subfaultlist": [],
    }

    componentDidMount = async () => {
        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/subIssue/list")
            .then(res => this.setState({
                subfaultlist: res.data
            }))
    }

    onClickDelete = async (id, topid) => {
        await axios.delete(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/subIssue/delete/${id}/${topid}`)
            .then(res => {
                this.setState({
                    ...this.state,
                    subfaultlist: this.state.subfaultlist.filter(u => u.subId !== id),
                    Message: res.data.Message
                })
            })
            .catch(err => {
                this.setState({
                    Message: err.response.data.errorMessage
                })
            })
    }

    onClickUpdate = async (id) => {
        this.props.setSubFaultId(id)
        window.location.reload();
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Alt Arıza Adı',
                    field: 'subfaultname',
                    sort: 'asc',

                },
                {
                    label: 'Üst Arıza Adı',
                    field: 'topfaultname',
                    sort: 'asc',

                },
                {
                    label: 'İşlemler',
                    field: 'operation',
                    sort: 'asc',
                }
            ],

            rows: this.state.subfaultlist.map(i => {
                return {
                    subfaultname: i.subname,
                    topfaultname: i.topname,
                    operation: <>
                        <button className='btn delete-button mr-2' onClick={this.onClickDelete.bind(this, i.subId, i.topId)}>
                            Sil
                        </button>
                        <button className='btn update-button mr-2' onClick={this.onClickUpdate.bind(this, i.subId)}>
                            Arıza Seç
                        </button>
                    </>
                }

            })
        };

        return (
            <div className='back-right' >
                <div className='my-fault'>
                    <div className='part-header'>
                        Alt Arıza Listesi
                    </div>
                    <div className='part-body'>
                        {this.state.Message ? <div className="alert alert-success">{this.state.Message}</div> : <div></div>}
                        <MDBDataTable
                            entriesOptions={[5, 10, 25]}
                            hover
                            striped
                            bordered
                            data={data}
                            searchLabel='Filtrele'
                            entrieslabel='aasdasdasd'
                            infoLabel={["", "-", "sonuç", ""]}
                            paginationLabel={["Geri", "İleri"]}
                            noRecordsFoundLabel='Sonuç bulunamadı'
                            noBottomColumns={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    topfaultlist: selectTopFaUltList(),
})

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({ setSubFaultId }, dispatch)
    )
)

export default connect(mapStateToProps, mapDispatchToProps)(SubFaultList)