import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectSubFaultId, selectTopFaUltList } from '../../../store/selectors';

class UpdateSubFault extends Component {
    state = {
        "subfault": "",
        "topfault": "",
        "ErrorMessage": "",
    }

    componentDidMount = async () => {
        if(this.props.subFaultId){
            await axios.get(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/subIssue/${this.props.subFaultId}`)
            .then(res => this.setState({
                topfault: res.data.topid,
                subfault: res.data.subname
            }))
        }
        else{
            return null
        }
        
    }

    onChangeTopFault = e => {
        this.setState({
            topfault: e.target.value
        })
    }

    onChange = e => {
        this.setState({
            subfault: e.target.value
        })
    }

    onClickSave = async (e) => {
        e.preventDefault();
        if (this.props.subFaultId) {
            await axios.put(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/subIssue/update/${this.props.subFaultId}`, {
                subName: this.state.subfault,
                id: this.state.topfault
            }).then(() => window.location.reload())
                .catch(err => this.setState({
                    ErrorMessage: err.response.data.errorMessage
                }))
        }
    }

    render() {
        const { subfault, topfault, ErrorMessage } = this.state;
        
        return (
            <div className='train-part mt-5'>
                <div className='part-header'>
                    Alt Arıza Güncelle
                </div>

                <div className='part-body'>
                    <form onSubmit={this.onClickSave}>
                        <div className="form-group row">
                            <label htmlFor="LeftTop" className="col-sm-3 col-form-label">
                                Üst Arıza:
                            </label>
                            <div className="col-sm-9" >
                                <select className="col-sm-12 form-select p-2" id="LeftTop" name="LeftTop"
                                    value={topfault} disabled >
                                    <option value="">Tüm Üst Arızalar</option>
                                    {
                                        this.props.topfaults.map(fault => (
                                            <option key={fault._id} value={fault._id}>{fault.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="Subname" className="col-sm-3 col-form-label">
                                Arıza Adı:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control" id="Subname" name="Subname" value={subfault}
                                    onChange={this.onChange} required />
                            </div>
                        </div>

                        {ErrorMessage ? <div className="alert alert-danger">{ErrorMessage}</div> : <div></div>}

                        <div className=" row text-right mt-4">
                            <div className="col-sm-12">
                                <button type="submit" className="btn right-part-button">
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    topfaults: selectTopFaUltList(),
    subFaultId: selectSubFaultId(),
});

export default connect(mapStateToProps)(UpdateSubFault);