import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectFaultList, selectTrainList } from '../../../store/selectors'

class FaultList extends Component {
    
    filter = (trainId) => {
        let newList = this.props.faultlist.filter(fault => fault.trainNumber === trainId)
        return newList.length
    }

    render() {
        return (
            <div className='parts-body'>
                <div className="parts-body-parts">
                    <table className="table table-striped" >
                        <thead className="thead">
                            <tr>
                                <th scope="col">Tren Numarası</th>
                                <th scope="col">Arıza Sayısı</th>
                            </tr>
                        </thead>

                        <tbody className="tbody">
                            {
                                this.props.trains.map(t => (
                                    <tr>
                                        <td>{t.name}</td>
                                        <td>{this.filter(t._id)} </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
const mapStateToProps = createStructuredSelector({
    faultlist: selectFaultList(),
    trains: selectTrainList(),
});

export default connect(mapStateToProps)(FaultList)