import React, { Component } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar';

class AuxilaryDynamicHoldingBrakeTest extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>
                        YARDIMCI FREN TESTİ
                    </h2>
                    <ul>
                        <li>
                            BCU kontrollü servis freninin yetersiz olduğu durumda, tekerlerin dolaylı frende kalması durumunda kullanılır. Kondivit in manuel boşalmasını sağlar.
                        </li>
                        <li>
                            Bu test B12 fren kolu ve dolaylı fren sisteminin kontrol edilmesini sağlar. Her iki kabinden de yapılması gerekir.
                        </li>
                        <li>
                            MP basıncı 6,5 bardan fazla olmalı.
                        </li>
                        <li>
                            Yardımcı fren anahtarı ON konumuna alınız.
                        </li>
                        <li>
                            Yön kolu ilerde, Gaz fren kolu N durumunda
                        </li>
                        <li>
                            Yardımcı fren kolunu ileri itiniz.
                        </li>
                        <li>
                            BP basıncı 5 bara yükselmelidir.
                        </li>
                        <li>
                            Yardımcı fren kolunu max fren konumuna alın.
                        </li>
                        <li>
                            BP basıncının 3,5 bara çıktığını kontrol edin.
                        </li>
                    </ul>

                    <h5>Yardımcı frende FREN BASINCI 3 BARA ÇIKMIYOR.1 BARDA KALIYOR:</h5>
                    <ul>
                        <li>
                        Aktif kabin BCU da <b>LEARNİNG</b> testi yapınız
                        </li>
                        <li>
                        Tren durmuş olacak.
                        </li>
                        <li>
                        Yardımcı fren anahtarı aktif olacak.
                        </li>
                        <li>
                        İmdat freni N olacak.
                        </li>
                        <li>
                        BCU da 1 ve 3 e aynı anda 1 sn basınız. Ekranda LRN çıkacak ekran 9999 oluncaya kadar bekleyiniz. Bu işlemi manometre normale dönünceye kadar yapınız.
                        </li>
                    </ul>

                    <h2>DİNAMİK FREN TESTİ</h2>
                    Dinamik fren testi set dururken yapılamaz. Ancak servis esnasında sürücünün Gaz kolunu %25 geri çekmesi ve motorların hızı yavaşlatmaya doğru zorlaması ile anlaşılır. Bu esnada Cer konvertörleri Katener hattına doğru ters akım üretir. SICAS menü 6 da izlenebilir.

                    <h2>HOLDİNG BREAK( TUTMA FRENİ)</h2>
                    Frenler çözüldüğünde trenin kaçmasını ve rampa kalkışlarında trenin geri kaçmasını önler.
                </div>
                <Footer/>
                </div>
        )
    }
}
export default AuxilaryDynamicHoldingBrakeTest;