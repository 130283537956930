import React, { Component } from 'react'
import HeaderNav from '../HeaderNav';
import Navbar from '../Navbar';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import Footer from '../Footer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFaultId } from '../../../store/actions';

class Faults extends Component {
    state = {
        "faultlist": [],
        "Message": "",
        "errorMessage": "",
    }
    
    componentDidMount = async () => {
        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/list")
            .then(res => this.setState({
                faultlist: res.data
            })).catch(()=>{
                window.location.replace("/login")
            })
    }

    onClickDelete = async (id) => {
        await axios.delete(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/delete/${id}`)
            .then(res => {
                this.setState({
                    ...this.state,
                    faultlist: this.state.faultlist.filter(f => f._id !== id),
                    Message: res.data.Message
                })
            }).catch(err => {
                this.setState({
                    errorMessage: err.response.data.errorMessage
                })
            })
    }

    onClickDetail = async (id) => {
       await this.props.setFaultId(id);
        window.location.replace("/fault-detail/" + id);
    }


    render() {
        const data = {
            columns: [
                {
                    label: 'Tren Numarası',
                    field: 'trainname',
                    sort: 'asc',

                },
                {
                    label: 'Üst Arıza',
                    field: 'topfault',
                    sort: 'asc',
                },
                {
                    label: 'Alt Arıza',
                    field: 'subfault',
                    sort: 'asc',
                },
                {
                    label: 'Kullanıcı',
                    field: 'username',
                    sort: 'asc',
                },
                {
                    label: 'Servis',
                    field: 'service',
                    sort: 'asc',
                },
                {
                    label: 'Tarih',
                    field: 'date',
                    sort: 'asc',
                },
                {
                    label: 'İşlemler',
                    field: 'operation',
                    sort: 'asc',
                },
            ],

            rows: this.state.faultlist.map(fault => {
                return {
                    trainname: fault.trainInformation[0].name,
                    topfault: fault.topFaultInformation[0].name,
                    subfault: fault.subFault,
                    username: `${fault.userInformation[0].name} ${fault.userInformation[0].surname}`,
                    service: fault.serviceInformation[0].name,
                    date: fault.date,
                    operation: <>
                        <button className='btn delete-button mr-2' onClick={this.onClickDelete.bind(this, fault._id)}>
                            Sil
                        </button>
                        <button className='btn update-button mr-2' onClick={this.onClickDetail.bind(this, fault._id)}>
                            Detay
                        </button>
                    </>
                }
            })
        };
        return (
            <div>
                <Navbar pageWrapId={"page-wrap"} />

                <div id="page-wrap">
                    <HeaderNav />
                    <div>
                        <div className='page-header'>
                            Arıza Listesi
                        </div>

                        <div className='users'>
                            <div className='fault-back'>
                                <div className='my-fault'>
                                    <div className='part-header'>
                                        Arıza Listesi
                                    </div>
                                    <div className='part-body'>
                                        {this.state.Message ? <div className="alert alert-success">{this.state.Message}</div> : <div></div>}
                                        <MDBDataTable
                                            entriesOptions={[5, 10, 25]}
                                            hover
                                            striped
                                            bordered
                                            data={data}
                                            searchLabel='Filtrele'
                                            entrieslabel='aasdasdasd'
                                            infoLabel={["", "-", "sonuç", ""]}
                                            paginationLabel={["Geri", "İleri"]}
                                            noRecordsFoundLabel='Sonuç bulunamadı'
                                            noBottomColumns={true}
                                        />
                                        {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div> : <div></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer/>
            </div >
        )
    }
}

const mapDispatchToProps = dispatch =>(
    (
        bindActionCreators({setFaultId}, dispatch)
    )
)

export default connect(null,mapDispatchToProps)(Faults);