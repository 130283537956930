import React from 'react'

function Footer() {
    return (
        <div className='pt-2 pb-1 mt-4 back-footer'>
            <marquee>
                Copyright © 2022 TMS, Tüm Hakları Saklıdır. Made by TMS AR-GE.
            </marquee>
        </div>
    )
}
export default Footer;