import React, { Component } from 'react'
import Footer from '../../../Footer';
import Navbar from '../../../Navbar'

class CerActiveBypass extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h4>
                        Cer Aktif Baypas:
                    </h4>
                    <p>
                        Trenin çalıştırılması için önce çekiş devresi kapatılması ve SICAS hareketi aktif hale getirmelidir. Şu durumlardan biri gerçekleştiği takdirde, SICAS hareket iznini iptal eder:
                        <ul>
                            <li>
                                Düşük BP basıncı.
                            </li>
                            <li>
                                8’den fazla frenli dingilin iptal edilmesi.
                            </li>
                            <li>
                                BCU’nun iletişiminin olmadığı ve dolaylı frene değişmeyen 8’den fazla yalıtılmış dingil (bu durumda, 4 çekici vagonlar için 4 yalıtılmış dingil ve motorlu vagonlar için 2 yalıtılmış dingil).
                            </li>
                            <li>
                            	Bir harici soket çıkışının bağlı olması. Bir kapının açık ve kapı devresi baypasının aktive edilmemiş olması.
                            </li>
                            <li>
                            	ERTMS tarafından çekişin kesilmesinin istenmesi.
                            </li>
                            <li>
                            	ERTMS’nin baypas yapılmaması.
                            </li>
                            <li>
                            	Servis freninin 10 km/saat üzeri bir hızda uygulanması.
                            </li>
                            <li>
                            	Aktif vagonda acil durum freninin serbest bırakılması ve yardımcı frenin kullanımda olmaması, ama elektrikle çalışan B4 valfının uyarılmaması.
                            </li>
                            <li>
                            	Aktif vagonda ama elektrikle çalışan B4 valfının sürekli uyarılmış durumda olması.
                            </li>
                            <li>
                            	Normal moda çalışırken, aktif vagonda B9 uyarılmazsa, BP sadece B12 kolu ile modüle edilebilir. Bu durumda, SICAS çekiş devresini açar ve SICAS HMI’de sürücüye treni yardımcı moda geçirmesini ve saatte 100 km’den yüksek olmayan bir hızda servisten çıkarmasını bildiren bir mesaj görüntülenir.
                            </li>
                        </ul>
                        Kabin tavan sol panelinde bulunan CER AKTİF baypas anahtarı (22S01), SICAS’dan izinler dahil çekiş devresindeki koşulların iptal edilmesini sağlar. Bu, acil durumlarda trenin hareket ettirilmesine imkan verir
                    </p>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default CerActiveBypass;