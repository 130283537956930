import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderNav from '../HeaderNav';
import Navbar from '../Navbar';
import LeftSide from './LeftSide';
import { setTopFaultList } from '../../../store/actions';
import SubFaultList from './SubFaultList';
import Footer from '../Footer';

class SubFault extends Component {

    componentDidMount = async(e) => {
        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/list")
        .then(res => this.props.setTopFaultList(res.data)).catch(()=>{
            window.location.replace("/login")
        })
    }

    render() {
        return (
            <div>
                <Navbar pageWrapId={"page-wrap"} />

                <div id="page-wrap">
                    <HeaderNav />
                    <div>
                        <div className='page-header'>
                            Alt Arızalar
                        </div>

                        <div className='users'>
                            <LeftSide />
                            <SubFaultList />
                        </div>
                    </div>
                </div >
                <Footer />
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setTopFaultList
        },dispatch)
    )
)
export default connect(null,mapDispatchToProps)(SubFault);