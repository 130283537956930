import React, { Component } from 'react'
import Footer from './Footer';
import Navbar from './Navbar'

class IPPrograms extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2>
                        CAF TRENLERİNDE KULLANILAN IPLER VE PROGRAMLAR
                    </h2>
                    <ul>
                        <li>
                            <b>UCMCTK: </b>SICAS kayıt alma ve analiz programı 192.168.0.1
                        </li>
                        <li>
                            <b>MON 200TCDD: </b>Yardımcı konvertör izleme ve kayıt indirme analiz programı.
                        </li>
                        <li>
                            <b>ANA3TK: </b>CESİS analiz programı
                        </li>
                        <li>
                            <b>UCESTK: </b>CESİS indirme programı  <br />
                            169.254.0.1   Kullanıcı adı: admin <br />
                            Parola: 2808
                        </li>
                        <li>
                            <b>CMC: </b>Windowsun izleme programı MVB Arızalarının analizinde kullanılır.
                            <ul>
                                <li>
                                    PC IP : 192.168.0.7
                                </li>
                                <li>
                                    Telnet 192.168.0.5
                                </li>
                                <li>
                                    Kullanıcı adı: showmem
                                </li>
                                <li>
                                    Parola: yok (Enter ile geç)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Footer />
            </div>
        )
    }
}
export default IPPrograms;