import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar';
import HeatingTest from '../../Images/heating-test.PNG';
import CoolingTest from '../../Images/cooling-test.PNG';

class HeatingCooling extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="w-75 mx-auto p-3">
          <h2>
            HVAC(KLİMA SİSTEMİ)
          </h2>

          <h5>SOĞUTMA TESTİNİN YAPILMASI:</h5>
          <div className='w-75'>
            <img src={CoolingTest} alt={CoolingTest} title={CoolingTest} />
          </div>

          <h5>ISITMA TESTİNİN YAPILMASI:</h5>
          <div className='w-75'>
            <img src={HeatingTest} alt={HeatingTest} title={HeatingTest} />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
export default HeatingCooling;