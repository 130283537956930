import React, { Component } from 'react'
import Footer from '../Footer'
import HeaderNav from '../HeaderNav'
import Navbar from '../Navbar'
import AddUser from './AddUser'
import UsersList from './UsersList'

class Users extends Component {
    render() {
        return (
            <div>
                <Navbar pageWrapId={"page-wrap"} />

                <div id="page-wrap">
                    <HeaderNav />
                    <div>

                        <div className='page-header'>
                            Kullanıcılar
                        </div>

                        <div className='users'>
                            <AddUser />
                            <UsersList />
                        </div>
                    </div>
                </div >
                <Footer />
            </div >
        )
    }
}
export default Users