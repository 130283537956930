import React,{useRef, useState, useEffect, useContext} from 'react'
import HeaderNav from './HeaderNav';
import Navbar from './Navbar';
import UserContext from '../../context/UserContext';
import Footer from './Footer';
import axios from 'axios';

function ChangePassword() {
    let oldPassword = useRef();
    let newPassword = useRef();
    let newPasswordAgain = useRef();
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const { user, getUser } = useContext(UserContext);

    useEffect(() => {
        if(!user){
            return;
        }
        const getUserID = async () =>{
            await getUser();
        }
    
        getUserID();
    }, [user]);

    

    const onClickSave = async (e) => {
        e.preventDefault();
        await axios.put(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/passwordchange/${user}`,{
            oldpassword:oldPassword.current.value,
            password:newPassword.current.value,
            passwordVerify:newPasswordAgain.current.value
        })
        .then(res => {
            setMessage(res.data.Message);
            setErrorMessage("");
            oldPassword.current.value="";
            newPassword.current.value="";
            newPasswordAgain.current.value="";
        })
        .catch(err => {
            setErrorMessage(err.response.data.errorMessage)
            setMessage("")
        })
    }
    return (
        <div>
            <Navbar pageWrapId={"page-wrap"} />
            <div id="page-wrap">
                <HeaderNav />
                <div>
                    <div className='page-header'>
                        Şifre Değiştir
                    </div>

                    <div className='users'>
                        <div className='fault-back'>
                            <div className='my-fault'>
                                <div className='part-header'>
                                    Şifreni Değiştir
                                </div>
                                <div className='part-body'>

                                    <form onSubmit={onClickSave}>
                                        {message ? <div className="alert alert-success">{message}</div> : <div></div>}
                                        <div className="form-group row">
                                            <label htmlFor="OldPassword" className="col-sm-3 col-form-label">
                                                Eski Şifre:
                                            </label>
                                            <div className="col-sm-8 mt-1">
                                                <input type="password" className="form-control" id="OldPassword" name="OldPassword" 
                                                ref={oldPassword} required />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="NewPassword" className="col-sm-3 col-form-label">
                                                Yeni Şifre:
                                            </label>
                                            <div className="col-sm-8 mt-1">
                                                <input type="password" className="form-control" id="NewPassword" name="NewPassword" 
                                                ref={newPassword} required />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="NewPasswordAgain" className="col-sm-3 col-form-label">
                                                Yeni Şifre(Tekrar):
                                            </label>
                                            <div className="col-sm-8 mt-1">
                                                <input type="password" className="form-control" id="NewPasswordAgain" name="NewPasswordAgain"
                                                ref={newPasswordAgain} required />
                                            </div>
                                        </div>


                                        {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : <div></div>}
                                        <div className=" row text-right mt-4">
                                            <div className="col-sm-11">
                                                <button type="submit" className="btn right-part-button">
                                                    Kaydet
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </div >
    )
}
export default ChangePassword;