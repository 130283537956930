import React, { Component } from 'react'
import { slide as Menu } from "react-burger-menu";
import { Link } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAuthority } from '../../store/selectors';
import axios from 'axios';

class Navbar extends Component {
    static contextType = UserContext;

    render() {
        const { getUser } = this.context;
        return (
            <Menu {...this.props}>
                <Link to="/">Ana Sayfaya Dön</Link>
                <Link to="/bilgilerim">Bilgilerim</Link>
                {this.props.authority === "Manager" ?
                    <Link to="/users">Kullanıcılar</Link>
                    : null
                }
                {
                    this.props.authority === "Manager" ?
                        <Link to="/users/statistics">Kullanıcı İstatistikleri</Link>
                        : null
                }
                {this.props.authority === "Manager" ?
                    <Link to="/trains">Trenler</Link>
                    : null
                }
                {this.props.authority === "Manager" ?
                    <Link to="/services">Servisler</Link>
                    : null
                }
                {this.props.authority === "Manager" ?
                    <Link to="/topfaults">Üst Arıza</Link>
                    : null
                }
                {this.props.authority === "Manager" ?
                    <Link to="/subfaults">Alt Arıza</Link>
                    : null
                }
                {this.props.authority === "Manager" ?
                    <Link to="/faults-back">Arızalar</Link>
                    : null
                }
                <Link to="/changepassword">Şifre Değiştir</Link>
                <Link to="/login" onClick={async () => {
                    await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/authentication/logOut");
                    await getUser;
                }}>
                    Çıkış Yap
                </Link>
            </Menu>

        )

    }
}

const mapStateToProps = createStructuredSelector({
    authority: selectAuthority(),
})
export default connect(mapStateToProps)(Navbar);
