import React, { Component } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'

class WSPBCUFaults extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h4>
                    WSP TESTİ:
                    </h4>
                    <ul>
                        <li>
                        Damp valflerin uygun çalışıp çalışmadığını kontrol eder.
                        </li>
                        <li>
                        Yön kolunu ileri alınız.
                        </li>
                        <li>
                        Park freni AÇIK konuma alınız.
                        </li>
                        <li>
                        Test yapılacak vagon BCU da; S2 düğmesine 2 sn basınız
                        </li>
                        <li>
                        Ekranda 8888 görünür sonra 89 çıkar.
                        </li>
                        <li>
                        Sonra 4 teker dump valflerin sıra ile açıp kapandığını ve hava tahliyesini dinleyiniz. Tahliye durumunda balatalar diski serbest bırakmalıdır.
                        </li>
                        <li>
                        İşlem bitince BCU ekranı 9999 olacak.
                        </li>
                    </ul>

                    <h4>
                    BCU DA ARIZA TAKİBİ
                    </h4>
                    <ul>
                        <li>
                        S1 düğmesine 3sn basınız.
                        </li>
                        <li>
                        Kayıtlı arızaların kodları sıra ile ekrana gelecektir.
                        </li>
                        <li>
                        Karşılığını TA31636/614 nolu katalogdan bakınız.
                        </li>
                    </ul>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default WSPBCUFaults