import * as constants from './constants'

const initialState = {
    trainlist: [],
    topfaultlist: [],
    faultlist: [],
    popupInfo: null,
    filterbytrain: [],
    userId: null,
    trainId: null,
    serviceId: null,
    topFaultId: null,
    subFaultId: null,
    authority: "",
    login: false,
    userIstatistics:[],
    faultId: null,
}

export default function store(state = initialState, action) {
    const actions = action;

    switch (actions.type) {
        case constants.SET_TRAIN_LIST: {
            return {
                ...state,
                trainlist: action.payload
            }
        }

        case constants.SET_TOPFAULT_LIST: {
            return {
                ...state,
                topfaultlist: action.payload
            }
        }

        case constants.SET_FAULT_LIST: {
            return {
                ...state,
                faultlist: action.payload
            }
        }

        case constants.SET_POPUP_INFO: {
            return {
                ...state,
                popupInfo: action.payload
            }
        }

        case constants.SET_FILTER_BY_TRAIN: {
            return {
                ...state,
                filterbytrain: action.payload
            }
        }

        case constants.SET_USER_ID: {
            return {
                ...state,
                userId: action.payload
            }
        }

        case constants.SET_TRAIN_ID: {
            return {
                ...state,
                trainId: action.payload
            }
        }

        case constants.SET_SERVICE_ID: {
            return {
                ...state,
                serviceId: action.payload
            }
        }

        case constants.SET_TOPFAULT_ID: {
            return {
                ...state,
                topFaultId: action.payload
            }
        }

        case constants.SET_SUBFAULT_ID: {
            return {
                ...state,
                subFaultId: action.payload
            }
        }

        case constants.SET_USER_AUTHORITY: {
            return {
                ...state,
                authority: action.payload
            }
        }

        case constants.SET_LOGIN: {
            return {
                ...state,
                login: action.payload
            }
        }

        case constants.SET_FAULT_ID: {
            return {
                ...state,
                faultId: action.payload
            }
        }

        case constants.SET_USERS_ISTATISTICS: {
            return {
                ...state,
                userIstatistics: action.payload
            }
        }

        case constants.SET_DEFAULT: {
            return {
                ...state,
                popupInfo: null,
                filterbytrain: [],
                userId: null,
                trainId: null,
                serviceId: null,
                topFaultId: null,
                subFaultId: null,
                authority: "",
                userIstatistics: [],
                login: false,
            }
        }

        default:
            return state;
    }
}