import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar';
import HeaderNav from '../HeaderNav';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectFaultId } from '../../../store/selectors';
import axios from 'axios';
class FaultDetail extends Component {
    state = {
        "fault": [],
    }

    componentDidMount = async () => {

        await axios.get(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/list/${this.props.faultId}`)
            .then(res => this.setState({
                fault: res.data
            }))

    }

    render() {
        const { fault } = this.state;
        return (
            <div>
                <Navbar pageWrapId={"page-wrap"} />
                <div id="page-wrap">
                    <HeaderNav />
                    <div>
                        <div className='page-header'>
                            Arıza Detayı
                        </div>

                        <div className='users'>
                            <div className='fault-back'>
                                <div className='my-fault'>
                                    <div className='part-header'>
                                        Arıza Detayı
                                    </div>
                                    <div className='part-body'>

                                        {fault ? fault.map(f => (
                                            <div className='p-3'><p><b>Kullanıcı Adı : </b>{`${f.userInformation[0].name} ${f.userInformation[0].surname}`}</p>
                                                <p><b>Tren Numarası : </b>{f.trainInformation[0].name}</p>
                                                <p><b>Servis Numarası : </b>{`${f.serviceInformation[0].name}`}</p>
                                                <p><b>Üst Arıza : </b>{f.topFaultInformation[0].name}</p>
                                                <p><b>Alt Arıza : </b>{f.subFault}</p>
                                                <p><b>Aktif Kabin : </b>{f.vagon}</p>
                                                <p><b>Km : </b>{f.rotaKm}</p>
                                                <p><b>Tarih: </b>{f.date}</p>
                                                <p><b>Açıklama : </b>{f.explaining}</p> </div>
                                        )) : <p>Veriler yükleniyor</p>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    faultId: selectFaultId(),
})

export default connect(mapStateToProps)(FaultDetail);