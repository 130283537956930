import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTrainId } from '../../../store/selectors'

class TrainUpdate extends Component {

    state = {
        "name": "",
    }

    componentDidMount = async () => {
        if (this.props.trainId) {
            await axios.get(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/train/list/${this.props.trainId}`)
                .then(res => {
                    this.setState({
                        name: res.data.name
                    })
                })
                .catch(err => console.log(err))
        }
    }

    onChange = e => {
        this.setState({
            name: e.target.value,
            ErrorMessage: ""
        });
    }

    onClickSave = async (e) => {
        e.preventDefault();
        await axios.put(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/train/update/${this.props.trainId}`, this.state).then(() => {
            window.location.reload()
        })
    }

    render() {
        const { name } = this.state;
        return (

            <div className='train-part mt-5'>
                <div className='part-header'>
                    Tren Güncelle
                </div>

                <div className='part-body'>
                    <form onSubmit={this.onClickSave}>
                        <div className="form-group row">
                            <label htmlFor="TrainName" className="col-sm-3 col-form-label">
                                Tren Adı:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control" id="TrainName" name="name" value={name}
                                    onChange={this.onChange} required />
                            </div>
                        </div>

                        <div className=" row text-right mt-4">
                            <div className="col-sm-12">
                                <button type="submit" className="btn right-part-button">
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}
const mapStateToProps = createStructuredSelector({
    trainId: selectTrainId(),
})

export default connect(mapStateToProps)(TrainUpdate);