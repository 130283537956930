import ShootingProcessFile from '../assets/acil-durum-unite-cekim-islemleri.pdf'
import Navbar from './Navbar'
export const ShootingProcess = () => {
    return (
        <>
            <Navbar />
            <div className='w-75 mx-auto p-3 text-center'>
                <h3 className='text-center'>BKM-TUR-TLM-083-V01 ACİL DURUMLARDA ÜNİTENİN ÇEKİLMESİ UYGULAMALARI</h3>
                <a href={ShootingProcessFile} download={"BKM-TUR-TLM-083-V01 ACİL DURUMLARDA ÜNİTENİN ÇEKİLMESİ UYGULAMALARI"} >Dosyayı İndir</a>
            </div>
        </>
    )
}