import React, { Component } from 'react'
import Footer from '../../Footer';
import Navbar from '../../Navbar'

class TotmanMode extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                <h2>
                    TOTMAN MODÜLÜ
                </h2>
                <ul>
                    <li>
                        Trenin tüm hareketlerini ve makinisti kontrol eder.
                    </li>
                    <li>
                        Aktif kabinde Hız 3 km/h olduğunda devreye girer.
                    </li>
                    <li>
                        Pedale 30 sn den fazla basılı tutulursa uyarı verir ve fren uygular.
                    </li>
                    <li>
                        Hareket halinde pedale basılmazsa uyarı verir ve fren uygular
                    </li>
                    <li>
                        <b>
                            TOTMAN butonları:
                        </b>
                        <ul>
                            <li>
                                Ayak pedalı
                            </li>
                            <li>
                                Yanlarda ( kırmızı ) buton şeklindedir
                            </li>
                        </ul>
                    </li>
                </ul>

                <h2 className="text-center">
                OTOMATİK FREN TESTİ
                </h2>
                <p>
                Tren servise verilmeden önce her gün aktif kabinde Otomatik Fren Testi (ABT) yapılmalıdır. ABT’nin amacı fren sisteminin işleyişini kontrol etmektir.
                <br/>
                <b>ABT şunları kontrol eder:</b>
                <ul>
                    <li>
                    	BP sızıntısı
                    </li>
                    <li>
                    	BP sürekliliği
                    </li>
                    <li>
                    	Aktif kabinde ve son vagonda B8.1 ve B8.2 SIFA valfları BP’yi besleyebiliyor mu?
                    </li>
                    <li>
                    	BCU tren hattı emniyet devresini açabiliyor mu?
                    </li>
                </ul>
                ABT’yi hızlandırmak için, BP süreklilik kontrolü ve SIFA valfları kontrolü eşzamanlı yapılır. 
                <br/>
                Bu nedenle, ABT’de üç kısmi test, yani BP sızıntı testi, SIFA – BP süreklilik testi ve tren hattı emniyet devresinde BCU müdahalesi testi vardır.
                </p>

                <h2 className="text-center">
                        MANUEL FREN TESTLERİ
                    </h2>
                    <p>
                        Manüel fren testleri farklı fren işlevlerini kontrol eder ve farklı koşullarda yapılmalıdır. Bunlardan üçü her gün ABT ile birlikte ve biri de sadece azaltılmış mod kullanılacaksa yapılır.
                        <br />
                        <b> Bu textler şunlardır:</b>
                        <ul>
                            <li>
                                Manuel fren testi B37 –  Cer / fren kolu ile yapılır SICAS tan takip edilir.
                            </li>
                            <li>
                                Manuel fren testi B15 – Acil durum mantar düğme.
                            </li>
                            <li>
                                Manuel fren testi B12 – Yardımcı Fren Modu.
                            </li>
                            <li>
                                Manuel fren testi B37 – Azaltılmış Mod
                            </li>
                            <li>
                                Manuel test P3 – P4 Korna
                            </li>
                        </ul>
                    </p>
                    </div>
                    <Footer/>
            </div>
        )
    }
}
export default TotmanMode;