import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar'

class Pnomatiksystem extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2>
                        PNÖMATİK SİSTEM AZALTILMIŞ HALDE ÇALIŞTIRMA
                    </h2>
                    <h4>
                        MP kırılması :
                    </h4>
                    <ul>
                        <li>
                            Hem etkilenen vagonun arka ucunda hem de ara vagonun bitişik ucunda, B56 yalıtım valfını kapatarak etkilenen vagonda arızalı MP’yi pnömatik olarak izole edin.
                        </li>
                        <li>
                            Borunun koptuğu vagon başka bir üniteden başka bir vagona bağlıysa, koşum takımı uçlarında B17 yalıtım valfını kapatın.
                        </li>
                        <li>
                            Park fren devresinde basınç olmaması için fren panelindeki (Z2) B65 yalıtım valfını kapatın.
                        </li>
                        <li>
                            C10 veya C11 kollarını kullanarak etkilenen vagonda park frenini manüel olarak serbest bırakın.
                            <ul>
                                <li>
                                    Kopan borunun olduğu vagondaki B10 basınç anahtarı MP’de düşük basınç gösterir ve emniyet devresini açar.
                                </li>
                                <li>
                                    Emniyet devresi açıldığında acil durum freninin uygulanmasını önlemek için, ACİL DURUM KAPI KONTROL baypas anahtarını (61S03) kullanın.
                                </li>
                                <li>
                                    Arızanın olduğu vagon aktif vagonsa, vagon değiştirin çünkü bu durumda çalıştırılmaz.
                                </li>
                                <li>
                                    Aktif vagonda, B13 basınç göstergesinde MP basıncının 7,5 bar üzerine çıktığını kontrol edin.
                                </li>
                            </ul>
                        </li>
                        <li>
                            İkinci süspansiyon devre dışı olduğundan Servis hızını 80 km'ye düşürün.
                        </li>
                    </ul>

                    <h2 className="text-center">
                        BP ARIZASI
                    </h2>
                    <ul>
                        <li>
                            BP borusunda kırılma meydana geldiyse ilgili vagonun körük kısmındaki B59 valfini kapatın.
                        </li>
                        <li>
                            Emniyet devresi açıldığında acil durum freninin uygulanmasını önlemek için, ACİL DURUM KAPI KONTROL baypas anahtarını (61S03) kullanın.
                        </li>
                        <li>
                            Arızanın olduğu vagon aktif vagonsa, vagon değiştirin çünkü bu durumda çalıştırılmaz.
                        </li>
                        <li>
                            Aktif vagonda, B14 basınç göstergesinde BP basıncının cer/fren kolu komutlarına uygun olduğunu kontrol edin.
                        </li>
                        <li>
                            Yavaşça sonraki istasyona sürün ve treni servisten çıkartın.
                        </li>
                    </ul>
                    <h4>
                        İkincil Süspansiyonda Arıza:
                    </h4>
                    <ul>
                        <li>
                            İkincil süspansiyon hava yayında bir arıza olursa vagon gövdesinin ağırlığı acil durum iç yayları ile desteklenir.
                        </li>
                        <li>
                            Bir arıza olursa, şu işlemleri uygulayın:
                            <ul>
                                <li>
                                    Etkilenen vagonda Z3 yardımcı panelinde L6.1 veya L6.2 yalıtım valfını kapatarak etkilenen boji için ikincil süspansiyona pnömatik beslemeyi kesin.
                                </li>
                                <li>
                                    Yükseklik ayarlama valfı etkilenen bojideki hasar görmemiş pnömatik yayı şişirerek vagon ağırlığını dengeler.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Pnomatiksystem;