export const SET_TRAIN_LIST = "SET_TRAIN_LIST"
export const SET_TOPFAULT_LIST = "SET_TOP_FAULT_LIST"
export const SET_FAULT_LIST = "SET_FAULT_LIST"
export const SET_POPUP_INFO = "SET_POPUP_INFO"
export const SET_FILTER_BY_TRAIN = "SET_FILTER_BY_TRAIN"
export const SET_USER_ID = "SET_USER_ID"
export const SET_TRAIN_ID = "SET_TRAIN_ID"
export const SET_SERVICE_ID = "SET_SERVICE_ID"
export const SET_TOPFAULT_ID = "SET_TOPFAULT_ID"
export const SET_SUBFAULT_ID = "SET_SUBFAULT_ID"
export const SET_USER_AUTHORITY = "SET_USER_AUTHORITY"
export const SET_LOGIN = "SET_LOGIN"
export const SET_DEFAULT = "SET_DEFAULT"
export const SET_FAULT_ID = "SET_FAULT_ID"
export const SET_USERS_ISTATISTICS = "SET_USERS_ISTATISTICS"