import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar';
import MaanuelWorking from '../../Images/manuel-work.PNG';

class ManuelWorkandFaults extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2>
                        HVAC(KLİMA SİSTEMİ)
                    </h2>
                    <h5>
                        KLİMANIN MANUEL ÇALIŞTIRILMASI
                    </h5>
                    <p>
                        Normalde manuel çalıştırma prosedürü olmamakla beraber, Bazı durumlarda manuel olarak çalıştırılmaktadır. Kontrol ve iletişim kartlarında arıza olduğunda bu tarz çalışma yapılmaktadır.
                    </p>
                    <p className='text-danger'>Bunun için kompresörlerde gaz olması ve kondanserde buzlanma olmaması gerekmektedir.</p>

                    <h6>İşlem sırası:</h6>
                    <ul>
                        <li>
                            72V.DC sigortasını kapatınız
                        </li>
                        <li>
                            Ekranlı kontrol kartını vidalarını gevşetip dışarı çekiniz. 72v. DC sigortayı açınız.Bagaj bölmesindeki termostatların ayarlı 24derece sıcaklık değerlerine kadar soğutma yapar.
                        </li>
                    </ul>
                    <div className='w-75'>
                        <img src={MaanuelWorking} alt={MaanuelWorking} title={MaanuelWorking} />
                    </div>

                    <h5>
                        MUHTELİF KLİMA ARIZALARI:
                    </h5>
                    <h6 className='text-danger'>
                        Sistemde gaz kalmamış:
                    </h6>
                    <p>
                        Bu tür arızada klima soğutma yapmayacaktır.
                    </p>
                    <p>
                        Sıkılmamış rekor, kırık bakır boru gibi donanımlarda oluşur. Alçak /Yüksek basınç siviçleri çalışmıyor olabilir. Kontrol kartı sistemi çalıştırmıyor olabilir. Onarım yapılmalıdır.
                    </p>
                    <h6 className='text-danger'>
                        EVAPTA buzlanma:
                    </h6>
                    <p>
                        HVAC ünitesinin dış ortamdan sıcak hava çekmesi neticesinde Evap ünitesinde buzlanma meydana gelir. Buzlanma sonunu hava akışı kesileceğinden soğutma yapılamayacaktır.
                    </p>
                    <p>
                        Sigortalar kapatılarak buzun erimesi sağlanmalıdır.
                    </p>
                </div>
                <Footer />
            </div>
        )
    }
}
export default ManuelWorkandFaults;