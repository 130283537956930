import React, { Component } from 'react'
import ByTrainPie from './ByTrainPie';
import FaultList from './FaultList';
import Filter from './Filter';

class ByTrain extends Component {
    render() {
        return (
            <div className='fault-right'>
                <div className='number-parts'>
                    <div className="parts-header">
                        Trene Göre Analiz
                    </div>
                    <Filter/>
                    <FaultList/>
                    <ByTrainPie/>
                </div>
            </div>
        )
    }
}
export default ByTrain;