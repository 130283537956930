import React, { Component } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'

class BrakeFaultsOne extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>FREN ARIZALARI</h2>
                    <h5>
                        MGS2 Sistem Devre Dışı/DNRA Sistem Devre Dışı
                    </h5>
                    <ul>
                        <li>
                            Bu iki arıza çoğunlukla aynı anda gelir. Genellikle tekerlerde apleti olur.
                        </li>
                        <li>
                            BCU da A7-A8 fren kartları değiştirilir. Dump valfler kontrol edilir. Kaliperin tekeri tutup tutmadığı kontrol edilir.
                        </li>
                        <li className="text-danger">
                            Hız sensör ve kablolaması kontrol edilir. Genellikle sensör veya kablosundaki kopukluktan kaynaklanır.
                        </li>
                        <li>
                            A9 kontrol kartı arızayı hafızadan silmez ise yeniden programlanır. Hala silmez ise kart değiştirilir.
                        </li>
                        <li>
                            Tren park halinde ise SICAS a arıza düşmez. Onarım sonunda tren mutlaka hareket ettirilmelidir.
                        </li>
                    </ul>

                    <h5>
                    VAGON DOLAYLI FRENDE KALIYOR
                    </h5>
                    <ul>
                        <li>
                        İlgili akslarda Dinamik fren uygulanmayacağı anlamına gelir. Dinamik frende akslar doğrudan havalı frene geçer. Bu aksların tekerlerinde apleti olma durumu vardır.
                        </li>
                        <li>
                        Z2 panelinde B41 ve B 45 valfler kontrol edilmeli gerekirse değiştirilmelidir.
                        </li>
                        <li >
                        BCU B41 valflerdeki mekanik siviçle durum takibi yapar. B45 ler ise hava basınç değerlerini okur.
                        </li>
                    </ul>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default BrakeFaultsOne