import React, { Component } from 'react'
import ImageOne from '../Images/ImagePassengerDoor-1.PNG'
import ImageTwo from '../Images/ImagePassengerDoor-2.PNG'
import ImageThree from '../Images/ImagePassengerDoor-3.PNG'
import ImageFour from '../Images/ImagePassengerDoor-4.PNG'
import ImageFive from '../Images/ImagePassengerDoor-5.PNG'
import ImageSix from '../Images/ImagePassengerDoor-6.PNG'
import Footer from './Footer'
import Navbar from './Navbar'

class PassengerDoorSwitch extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>YOLCU KAPILARI SWİTCHLER</h2>
                    <div className="d-flex">
                        <div className="w-50">
                            <img src={ImageOne} alt={ImageOne} />
                            <p>
                                <b>Kapı kanadı kontrol anahtarı</b>
                                <ul>
                                    <li>
                                        X36 (S06)
                                    </li>
                                    <li>
                                        ÜRETİCİ: MOELLER
                                    </li>
                                    <li>
                                        PARÇA NO: IEC947/EN 60947 VDE 0660
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <div className="w-50">
                            <img src={ImageTwo} alt={ImageTwo} />
                            <p>
                                <b>Kapı kapısı izole anahtarı</b>
                                <ul>
                                    <li>
                                        İzole Anahtarı: 2 ADET S 05
                                    </li>
                                    <li>
                                        COD: 714-0019-301
                                    </li>
                                    <li>
                                        S847 W3B2e103
                                    </li>
                                    <li>
                                        ÜRETİCİ: SCHALTBAU
                                    </li>
                                </ul>
                            </p>
                        </div>
                        </div>
                        <div className="d-flex">
                        <div className="w-50">
                            <img src={ImageThree} alt={ImageThree} />
                            <p>
                                <b>Kapı kilidi kontrol anahtarı</b>
                                <ul>
                                    <li>
                                    X34 (S07)-X35(S08) alt ve üst kilit üzerindedir.
                                    </li>
                                    <li>
                                        2 ADET
                                    </li>
                                    <li>
                                    ÜRETİCİ COD: 608.8171.016
                                    </li>
                                    <li>
                                    ÜRETİCİ: BERNSTEIN
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <div className="w-50">
                            <img src={ImageFour} alt={ImageFour} />
                            <p>
                                <b>Açık kapı sonlandırma anahtarı</b>
                                <ul>
                                    <li>
                                    X18 –X19
                                    </li>
                                    <li>
                                        2 ADET
                                    </li>
                                    <li>
                                    X18 ACİL AÇMA MEKANİZMASINDA . X19 MOTORUN YANINDA 
                                    </li>
                                    <li>
                                    COD: S 847    W 402e
                                    </li>
                                </ul>
                            </p>
                        </div>
                        </div>
                        <div className="d-flex">
                        <div className="w-50">
                            <img src={ImageFive} alt={ImageFive} />
                            <p>
                                <b>Basamak açma anahtarı</b>
                                <ul>
                                    <li>
                                   S11
                                    </li>
                                    <li>
                                    Cod: 714-0023 – 301
                                    </li>
                                    <li>
                                    ÜRETİCİ: SCHALTBAU
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="w-50">
                            <img src={ImageSix} alt={ImageSix} />
                            <p>
                                <b>Basamak kapandı anahtarı</b>
                                <ul>
                                    <li>
                                    X30  S10 BASAMAK KATLAMA(KAPANDI) KONTROL
                                    </li>
                                    <li>
                                    ÜRETİCİ COD: 608.8171.016
                                    </li>
                                    <li>
                                    ÜRETİCİ: BERNSTEIN
                                    </li>
                                </ul>
                            </p>
                            <p>
                                <b>Basamak sensörü</b>
                                <ul>
                                    <li>
                                    TYPE: NBN8-12GM50-E2
                                    </li>
                                    <li>
                                    PART NO: 088242
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default PassengerDoorSwitch;