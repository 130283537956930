import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TMS from '../Images/tms.png'
import UserContext from '../context/UserContext';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAuthority } from '../store/selectors';

class Navbar extends Component {

    static contextType = UserContext;
    render() {
        const { getUser } = this.context;
        return (
            <nav className="navbar navbar-expand-lg navbar-dark " style={{ backgroundColor: "#042e5a" }}>
                <Link to="/" className="navbar-brand">
                    <img src={TMS} alt={TMS} style={{ width: "45%" }} />
                </Link> <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
                
                <div className="collapse navbar-collapse" id="navbarColor02">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item"> <Link to="/" className="nav-link">Ana Sayfa <span className="sr-only">(current)</span></Link> </li>
                        <li className="nav-item"> <Link to="/tren-genel-yapi" className="nav-link">Trenin Genel Yapısı</Link> </li>
                        <li className="nav-item"> <Link to="/faults" className="nav-link"> Arızalar </Link> </li>
                     {this.props.authority === "Manager" ?   <li className='nav-item'> <Link to="/fault-analyst" className='nav-link'>Arıza Analiz Sayfası</Link> </li>:null }
                    </ul>
                </div>
                
                <form className="d-flex input-group w-auto">
                    <Link to="/bilgilerim" className='nav-link'>Arka Plana Git</Link>
                    <Link to="/login" className="nav-link" onClick={async () => {
                        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/authentication/logOut");
                        await getUser;
                    }}>Çıkış Yap</Link>
                </form>
            </nav>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    authority:selectAuthority(),
})

export default connect(mapStateToProps)(Navbar)