import React, { Component } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUsersIstatistics } from '../../../store/selectors';

class UserIstatisticsList extends Component {

  render() {
    return (
      <div className='parts-body'>
        <div className="parts-body-parts">
          <table className="table table-striped" >
            <thead className="thead">
              <tr>
                <th scope="col">Kullanıcı</th>
                <th scope="col">Arıza Sayısı</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {
                this.props.userIstatistics.map(user => (
                  <tr key={user.userName}>
                    <td>{user.userName} </td>
                    <td>{user.faultCount}</td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  userIstatistics: selectUsersIstatistics()
});

export default connect(mapStateToProps)(UserIstatisticsList);