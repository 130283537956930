import React, { Component } from 'react'
import Image from '../Images/ImageExternalSupply.PNG'
import Footer from './Footer'
import Navbar from './Navbar'
class ExternalSupply extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h3>
                        HARİCİ BESLEME
                    </h3>
                    <p>
                        Tren çalıştırıldığında (kabin devrede veya kabin değişimi), TCB, MIF1B veya TCF vagonlarının şase altına yerleştirilen 3 harici 400 Vac soketinden (30 X 01) herhangi birisi bağlı durumda ise, ilgili vagonların AC yükü bağlanacaktır. Bu şekilde, üç harici soket aynı zamanda bağlanırsa, tren AC yüklerinin hepsi bağlanacaktır.
                        <br />
                        Soğuk Park modunda ise sadece bazı AC yükleri bağlanacaktır.
                        <br />
                        Tren kapatıldığında, harici soketlerden herhangi birisi bağlanırsa, iki vagonunun AC yükleri bağlanmayacaktır; sadece aküler akü şarj aygıtlarından şarj olacaktır.
                        <br />
                        MIF1B vagonu şase altına monte edilen harici soketten (30 X 01), trenin kapalı olması şartıyla, MIF1A, MIF1B ve MIF2 vagonlarının akülerini şarj etmek mümkündür.

                    </p>
                    <img src={Image} alt={Image} />

                    <h5>HARİCİ BESLEME SOKETİ</h5>
                    <p>Katenersiz ortamda, Akülerin şarj edilmesinde ve Klimaların çalıştırılmasında kullanılır.</p>
                </div>
                <Footer />
            </div>
        )
    }
}
export default ExternalSupply