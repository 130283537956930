import React, { useState } from 'react'
import {Link} from 'react-router-dom'
const USERS = [
    { id: "caf-tren-testleri-abt", name: 'CAF TREN TESTLERİ - ABT' },
    { id: "servis-ve-acil-fren-testi", name: 'SERVİS FRENİ TESTİ - ACİL FREN (B15 MANTAR BUTON)' },
    { id: "yardimci-dinamik-holding-fren-testi", name: 'YARDIMCI FREN TESTİ - DİNAMİK FREN TESTİ - HOLDİNG BREAK(TUTMA FRENİ)' },
    { id: "wsp-bcu-ariza", name: 'WSP TESTİ-BCU DA ARIZA TAKİBİ' },
    { id: "aracin-calistirilmasi-ariza", name: 'ARACIN ÇALIŞTIRILMASI VE ARIZA TESPİTİ' },
    { id: "uyari-tasit-kabin-emniyet", name: 'UYARILAR İKAZLAR - TAŞITIN ÇALIŞTIRILMASINDA ÖNCELİKLER - KABİNDE GÖSTERGELER - EMNİYET DÜZENLEMELERİ' },
    { id: "topraklama", name: 'TOPRAKLAMA' },
    { id: "kabini-aktif-soguk-park", name: 'KABİNİ AKTİF (SICAK) YAPMAK - SOĞUK PARK' },
    { id: "calistirmadan-önce-genel-muayene", name: 'ÇALIŞTIRMADAN ÖNCE GENEL MUAYENE' },
    { id: "pantograf-hscb", name: 'PANTOGRAF-HSCB' },
    { id: "tren-surulmesi", name: 'TRENİN SÜRÜLMESİ' },
    { id: "tren-surulmesi-1", name: 'YARDIMCI FREN MODU - AZALTILMIŞ MOD BYPASS - PARK FRENİ' },
    { id: "tren-surulmesi-2", name: 'TOTMAN MODÜLÜ - OTOMATİK FREN TESTİ - MANUEL FREN TESTİ' },
    { id: "tren-surulmesi-3", name: 'ABT UYGULAMASI - ACİL DURUM ÇALIŞTIRMA' },
    { id: "kapi-acil-durum-fren-devresi", name: 'KAPI DEVRESİ - ACİL DURUM FREN DEVRESİ - YOLCU ACİL DURUM ALARM DEVRESİ' },
    { id: "birincil-ikincil-emniyet-devresi", name: 'BİRİNCİL-EMNİYET-DEVRESİ - İKİNCİL-EMNİYET-DEVRESİ' },
    { id: "acil-atms-bypass", name: 'TREN SÜRÜLMESİ AZALTILMIŞ MOD BYPASS - ATMS BAYPAS - ACİL DURUM MODU' },
    { id: "cer-aktif-bypass", name: 'CER AKTİF BYPASS' },
    { id: "yardimci-redresör-batarya-sarj-arizasi", name: 'YARDIMCI REDRESÖR ARIZASI - BATARYA ŞARJ CİHAZI ARIZASI - BCU ARIZALARI' },
    { id: "mp-kirilma-bp-arizasi", name: 'PNÖMATİK SİSTEM AZALTILMIŞ HALDE ÇALIŞTIRMA (Mp Kırılma) - BP ARIZASI(İkincil Süspansiyonda Arıza)' },
    { id: "mvb", name: 'MVB VE MVB ARIZALARI' },
    { id: "ip-program", name: '65000 DE KULLANILAN IPLER VE PROGRAMLAR' },
    { id: "harici-besleme", name: 'HARİCİ BESLEME' },
    { id: "atms-ariza", name: 'ATMS ARIZALARI' },
    { id: "sicaklik-esikleri", name: 'SICAKLIK EŞİKLERİ' },
    { id: "fren-arizalari-1", name: 'MGS2 Sistem Devre Dışı/DNRA Sistem Devre Dışı - VAGON DOLAYLI FRENDE KALIYOR' },
    { id: "fren-arizalari-2", name: 'DCL BASINÇ ARIZASI - KONDİVİT BASINCI YÜKSELMİYOR - ABT TESTİNİ GEÇMİYOR' },
    { id: "kaliper-yolcu-kapi-izole", name: 'KALİPER SONSUZ DİŞLİ ve YOLCU KAPILARI İZOLE KARESİ' },
    { id: "yolcu-giris-kapi-arizalari", name: 'YOLCU GİRİŞ KAPILARI VE ARIZALARI' },
    { id: "yolcu-kapi-switch", name: 'YOLCU KAPILARI VE SWİTCHLER' },
    { id: "wc-egitim", name: 'WC EĞİTİMİ(Ledlerin Durumu)' },
    { id: "wc-egitim-ariza", name: 'WC EĞİTİMİ ARIZALARIN NEDENLERİ VE GİDERME YÖNTEMLERİ' },
    { id: "set-soguk", name: 'SETİN SOĞUK ÇEKİLMESİ' },
    { id: "imdat-modu", name: 'İMDAT MODU' },
];

function Footer() {
  const [name, setName] = useState('');
  const [foundUsers, setFoundUsers] = useState(USERS);
  const [yol, setYol] = useState("")

  const filter = (e) => {
      const keyword = e.target.value;

      if (keyword !== '') {
          const results = USERS.filter((user) => {
              return user.name.indexOf(keyword.toUpperCase()) >= 0
          });

          setFoundUsers(results);
      } else {
          setFoundUsers(USERS);
      }
      setName(keyword);
  };

  const onClickInput = (n, y) => {
      setName(n)
      setYol(y)
  }

  const onChangeSelect = (e) => {
      setYol(e.target.value)
  }
    return (
        <div className="footer">
           <div className="filter-part">
                <div className="container">
                    <input
                        type="search"
                        value={name}
                        onChange={filter}
                        className="input form-control mr-sm-2"
                        placeholder="Filtre"
                    />
                    <div className={name === "" ? "d-none" : "user-list"}>

                        {foundUsers && foundUsers.length > 0 ? (
                            foundUsers.map((user) => (
                                <li key={user.name} className="user" >
                                    <span className="user-name" onClick={() => onClickInput(user.name, user.id)}>{user.name}</span>
                                </li>
                            ))
                        ) : (
                            <p>Sonuç Bulunamadı!</p>
                        )}
                    </div>
                </div>

                <select className="select form-control mr-sm-2" name="cars" id="cars" value={yol} onChange={onChangeSelect}>
                    <option value="">65000 ile ilgili konu başlıklarını seçiniz</option>
                    {
                        USERS.map(user => {
                            return (
                                <option value={user.id} key={user.id} >{user.name}</option>
                            )

                        })
                    }
                </select>

                <Link to={"/" + yol} className="btn button-footer">Ara</Link>
            </div>
            <div className="text-center" style={{fontSize:"15px"}}>
                    Bir sorun ile karşılaşırsanız lütfen depo yetkilisini arayınız.
            </div>
          <marquee className="text-center w-100" style={{fontSize:"14px"}}>
          Copyright © 2022 TMS, Tüm Hakları Saklıdır. Made by TMS AR-GE.
          </marquee>
       
      </div>
    )
}
export default Footer;