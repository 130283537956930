import React, { Component } from 'react'
import ByFaultsPie from './ByFaultsPie';
import FaultList from './FaultList';
import Filter from './Filter';

class ByFault extends Component {
    render() {
        return (
            <div className='fault-left'>
                <div className='number-parts'>
                    <div className="parts-header">
                        Arızaya Göre Analiz
                    </div>

                    <Filter />
                    <FaultList />
                    <ByFaultsPie />
                </div>
            </div>
        )
    }
}
export default ByFault;