import React, { Component } from 'react'
import Train from '../../Images/Train.png'
import Footer from '../Footer';
import Navbar from '../Navbar'

class VehicleWorking extends Component {
    render() {
        return (
            <div >
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2>
                        ARACIN ÇALIŞTIRILMASI VE ARIZA TESPİTİ
                    </h2>
                    <div className="w-75 mx-auto text-center">
                        <img src={Train} alt={Train} />
                    </div>
                    <h3 className="w-100 text-center">
                        Taşıta Genel Bakış
                    </h3>
                    <h4>
                        Genel Bakış
                    </h4>
                    <p>
                        Operatör, Hızlı Tren Setini kullanmadan önce ünite hakkında yeterli bilgiye sahip olmalıdır. Bu bölümde, operatör bunları kontrol edebilecek
                        veya ayarlayabilecek konumda olmasa bile, yine de hakkında temel bilgi sahibi olması gereken taşıtın en önemli özellikleri açıklanmaktadır.
                    </p>
                    <h4>
                        Operatör Ekipmanı
                    </h4>
                    <p>
                        Operatör tüm operatör ekipmanı hakkında bilgi sahibi olmalıdır. kumandaların, sayaçların, göstergelerin ve anahtarların konumları,işlevleri ve kullanımlarını bilmelidir.<br />
                        Operatör, Hızlı Tren Setinin geçerli çalıştırma işlevleri hakkında yeterince eğitim görmüş olmalıdır.
                    </p>
                    <h4>
                        Acil Durum Çalıştırma
                    </h4>
                    <p>
                        Operatör, Hızlı Tren Setinin acil durumda çalıştırılması konusunda yeterince eğitim görmüş olmalıdır.<br />
                        Operatör, hızlı trenin güç-hız eğrisi ve fren eğrisi ile trenin yapabileceği maksimum hızlar hakkında bilgi sahibi olmalıdır.
                    </p>
                </div>
                <Footer />
            </div>
        )
    }
}
export default VehicleWorking;