import React, { Component } from 'react'
import AddTrain from './AddTrain';
import TrainUpdate from './TrainUpdate';

class LeftSide extends Component {
    render() {
        return (
            <div className='left-train-part'>
                <AddTrain/>
                <TrainUpdate/>
            </div>
        )
    }
}
export default LeftSide;