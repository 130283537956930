import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar';
import FuseTable from '../../Images/fusetable.PNG';
import FuseTableTwo from '../../Images/fusetable2.jpg';
import ControlCard from '../../Images/control-card.PNG';

class HvacFirstPart extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2>
                        HVAC(KLİMA SİSTEMİ)
                    </h2>
                    <p>
                        HVAC sistemi HSCB tuttuğunda otomatik olarak çalışmaya başlar. Vagon sıcaklıkları 23 dereceye ayarlanmıştır. Vagonlarda soğutma ve ısıtma kontrolü sistem tarafından yapılmaktadır. Manuel olarak dereceleri değiştirmek mümkün değildir.
                    </p>
                    <p>
                        Dış ortam sıcaklığı için HVAC ünitesinde, ortam sıcaklığı için bagaj bölmesine ve temiz hava sıcaklığı için sensörler konulmuştur.
                    </p>
                    <p>
                        Her vagonda yolcu giriş kapılarının üzerinde monteli salonlar için 2 bağımsız soğutma devresi içeren kompakt HVAC ünitesi mevcuttur. Ünitelerden biri arızalandığında diğeri %50 kapasite ile havalandırmaya devam eder. Üniteye bağlı kanallar yardımı ile soğutulan ve ısıtılan hava vagonlara dağıtılır.
                    </p>
                    <p>
                        Salonların orta kısmında kirli havayı dışarı atmak için Ekzos üniteleri vardır. Kirli havanın bir kısmı dış ortama atıldığından dışarıdan %30 oranında temiz hava içeri çekilmektedir. Vagon içerisindeki bir kısım hava bagaj bölmesinden emilerek temizlenir soğutulur tekrar salona döner.
                    </p>
                    <p>
                        HVAC ünitesinde 35kw,platformlarda 2kw,wc lerde 1kw ve zemin ısıtıcılarda 3,5kw lık ısıtıcılar mevcuttur.
                    </p>

                    <h5>SOĞUTMA DÖNGÜSÜ (özet)</h5>
                    <p>
                        Klima sisteminde R-407C soğutucu akışkan gaz kullanılmaktadır. Soğutucu akışkan kompresör vasıtasıyla sisteme basılırken ısınır buharlaşır. Buharlaşan gaz evaparatördeki bakır borulardan geçer. Bu geçiş anında Evaparatör ızgaralarından geçen hava akımı Gazı sıvı hale getirirken havanın hızla soğumasına neden olur. Soğutulan hava Evaparatör fan motoru vasıtasıyla salona gönderilir.
                    </p>
                    <p>
                        Sistemin en üstünde Kondanser fan motorları mevcuttur. Bu motorlar kondanser ve kompresörlerin soğutulmasını sağlar. Sistemde gaz yoksa çalışmazlar. Devreye girip geri çıkarlar.
                    </p>
                    <p>
                        HVAC ünitesinde iki adet kontrol ve iletişim kartı bulunmaktadır. İletişim kartı salon sıcaklıklarını SICAS a bildirir. Kontrol kartı sistemin çalışmasını sağlar.
                    </p>
                    <h5>SİGORTA PANOSU:</h5>
                    <img className='w-75' src={FuseTable} title={FuseTable} alt={FuseTable}/>
                    <img className='w-50 mt-2' src={FuseTableTwo} title={FuseTableTwo} alt={FuseTableTwo}/>

                    <h5>KONTOL KARTLARINDAN SİSTEMİN İZLENMESİ</h5>
                    <img className='w-50 mt-2' src={ControlCard} title={ControlCard} alt={ControlCard}/>
                    <p>
                    Klimanın iyi çalışabilmesi için gaz kaçağı olmaması ve yeterli miktar gazın klimaya verilmiş olması gerekmektedir.
                    </p>
                </div>
                <Footer />
            </div>
        )
    }
}
export default HvacFirstPart;