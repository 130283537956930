import { createSelector } from 'reselect';

const domain = (state) => state;

export const selectTrainList = () => createSelector(
    domain,
    substate => substate.trainlist,
)

export const selectTopFaUltList = () => createSelector(
    domain,
    substate => substate.topfaultlist,
)

export const selectFaultList = () => createSelector(
    domain,
    substate => substate.faultlist,
)

export const selectPopupInfo = () => createSelector(
    domain,
    substate => substate.popupInfo,
)

export const selectFilterByTrain = () => createSelector(
    domain,
    substate => substate.filterbytrain,
)

export const selectUserId = () => createSelector(
    domain,
    substate => substate.userId,
)

export const selectTrainId = () => createSelector(
    domain,
    substate => substate.trainId,
)

export const selectServiceId = () => createSelector(
    domain,
    substate => substate.serviceId,
)

export const selectTopFaultId = () => createSelector(
    domain,
    substate => substate.topFaultId,
)

export const selectSubFaultId = () => createSelector(
    domain,
    substate => substate.subFaultId,
)

export const selectAuthority = () => createSelector(
    domain,
    substate => substate.authority,
)

export const selectLogin = () => createSelector(
    domain,
    substate => substate.login,
)

export const selectUsersIstatistics = () => createSelector(
    domain,
    substate => substate.userIstatistics,
)

export const selectFaultId = () => createSelector(
    domain,
    substate => substate.faultId,
)
