import React, { Component } from 'react'
import Footer from './Footer';
import Navbar from './Navbar'

class PassengerEntranceDoors extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>YOLCU GİRİŞ KAPILARI VE ARIZALARI</h2>
                    <ul>
                        <li>
                    	KAPI KANADI VE BASAMAKTAN OLUŞMAKTADIR.
                        </li>
                        <li>
                        KAPI KANADI DCU YA BAĞLI BİR MOTORLA HAREKET ETTİRİLİR VE HAVAYLA KİLİTLENİR.
                        </li>
                        <li>
                        	BASAMAK HAVAYLA KİLİTLENİR. 6-7 bar
                        </li>
                        <li>
                        	KAPI KANADINDAKİ ÜST PÖNOMATİK VALF KAPI KANADINI KİTLER.
                        </li>
                        <li>
                        	ALTTAKİ  VALF BASAMAĞI KİTLER.
                        </li>
                        <li>
                        	KAPI ÇEŞİTLİ GÜVENLİK MALZEMELERİYLE DONATILMIŞTIR.
                        </li>
                        <li>
                        	YEREL KUMANDA KARESİ TABİR EDİLEN MEKANİK KAPATMA MEKANİZMASI BULUNMAKTADIR.
                            <ul>
                                <li>
                                1 DİĞER KAPILARI KAPATIP YÖNLERİ ALIR
                                </li>
                                <li>
                                2 KENDİ KAPISINI KAPATIR.
                                </li>
                            </ul>
                        </li>
                        <li>
                        	DIŞARDAN VE İÇERDEN ACİL AÇMA MEKANİZMASI VARDIR. ACİL AÇMA MEKANİZMASI KAPI RAYININ ÜSTÜNDE BİR SİVİCE BASARAK 0(SIFIR)HIZ DEVRESİNİ KESER VE KAPININ KİLİTTEN KURTULUP AÇILMASINI SAĞLAR.
                        </li>
                        <li className="text-danger">
                        	KAPI AÇIKKEN SIFIR HIZ SİVİÇİNİN BAĞLANTISI KESİLİRSE KAPI KAPANMAYA BAŞLAR. TEHLİKELİDİR!!!
                        </li>
                    </ul>

                    <h4 className="text-danger">
                        ARIZALAR VE ÇÖZÜM YOLLARI
                    </h4>
                    <p className="text-danger">Arıza: <br/>
                        Kapı kapanıyor, kendiliğinden geri açılıyor. Bu işlemi 3 defa yapıyor ve en son açık kalıyor.
                    </p>
                    <p> <span className="text-danger">Sebepler;</span>
                        <ul>
                            <li>
                            Kapı kapandığında engel belirliyordur.
                            </li>
                            <li>
                            Acil açma kolu çekili kalmıştır. Sıfır hız siviçi boşa çıkmamıştır
                            </li>
                            <li>
                            Kapı kilit pistonlarının çekme halatı gergin kalıp sivici bırakmıyordur.
                            </li>
                        </ul>
                    </p>

                    <p className="text-danger">Arıza: <br/>
                        Basamak çekilmedi.
                    </p>
                    <p> <span className="text-danger">Sebepler;</span>
                        <ul>
                            <li className="text-danger">
                            Basamak önce çekiliyor sonra bıraktığı için aç-kapa butonları flaş yapar.
                            </li>
                            <li>
                            Basamak sensörü arızalı olabilir. 
                            </li>
                            <li>
                            Basamak çekildi siviçi arızalı olabilir.
                            </li>
                        </ul>
                    </p>

                    <p className="text-danger">Arıza: <br/>
                        Kapı açılırken kalıyor. Motor geriye tam sürmüyor
                    </p>
                    <p> <span className="text-danger">Sebepler;</span>
                        <ul>
                            <li>
                            Üst ve alt kilit siviçlerinden bir tanesi arızalıdır. Uygun çalışmıyordur.
                            </li>
                        </ul>
                    </p>

                    <p className="text-danger">Arıza: <br/>
                    Kritik kapı arızası
                    </p>
                    <p> <span className="text-danger">Sebepler;</span>
                        <ul>
                            <li>
                            Kapı ve basamak siviçleri ayarsız veya arızalıdır.
                            </li>
                        </ul>
                    </p>

                    <p className="text-danger">Arıza: <br/>
                    Cer gelmiyor
                    </p>
                    <p> <span className="text-danger">Sebepler;</span>
                        <ul>
                            <li>
                            Yolcu kapılarını sırayla izole ediniz. Kabinde yeşil lambanın yandığı andaki kapı DCU arızalı olabilir. Kapı izole sivici kırık olabilir.
                            </li>
                        </ul>
                    </p>
                    </div>
                    <Footer/>
            </div>
        )
    }
}
export default PassengerEntranceDoors;