import React, { Component } from 'react'
import AddSubFault from './AddSubFault';
import UpdateSubFault from './UpdateSubFault';

class LeftSide extends Component {
    render() {
        return (
            <div className='left-train-part'>
                <AddSubFault />
                <UpdateSubFault />
            </div>
        )
    }
}
export default LeftSide;