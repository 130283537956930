import React, { Component } from 'react'
import Footer from '../../Footer';
import Navbar from '../../Navbar'

class ABTApplication extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                <h2 >
                    ABT UYGULAMASI
                </h2>
                <ul>
                    <li>
                        Servis öncesi bir kabinden mutlaka yapılmalıdır.
                    </li>
                    <li>
                        Yardımcı fren N konumda olacak.
                    </li>
                    <li>
                        MP basıncı 6,5 bardan yüksek olacak.
                    </li>
                    <li>
                        Yön kolu FORVET/REV e alınız.
                    </li>
                    <li>
                        Gaz kolu N e alınız.
                    </li>
                    <li>
                        Manometrede BP ve MP basıncı 5 bar olacak.
                    </li>
                    <li>
                        Sıcas'ta menü 9 ve çıkan ekranda 2'ye basınız.
                    </li>
                    <li>
                        ABT başlat sanal butona basınız.
                    </li>
                    <li>
                        Eğer test başarılı olursa sanal buton YEŞİL olacaktır.
                    </li>
                    <li>
                        Test başarısız olursa sanal buton  KIRMIZI olacaktır.
                    </li>
                </ul>

                <h2 >
                        ACİL DURUM ÇALIŞTIRMA
                    </h2>
                    <ul>
                        <li>
                            Sıfır Hız/ Çekiş Devresi
                        </li>
                        <li>
                            Kapıların açılabilmesi için tren durdurulmalıdır(Hız {"<"} 3 km/saat).
                        </li>
                        <li>
                            Tren istasyondayken sıfır hız sinyali çalışmazsa, vagon tavan panelinde bulunan 0 HIZ SİNYAL İPTAL baypas anahtarı (15S01) kapıları açmak için kullanılabilir.
                        </li>
                    </ul>
                    </div>
                    <Footer/>
            </div>
        )
    }
}
export default ABTApplication;