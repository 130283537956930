import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar'

class CabinActive extends Component {
    render() {
        return (
            <div className="font-weight-bold">
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                     <h2 >
                        KABİNİ AKTİF (SICAK)YAPMAK
                    </h2>
                    <ul>
                        <li>Kabin aktifleştirme ana kumandada (14A01) bulunan anahtar ile yapılır.</li>
                        <li>
                            Kabin aktifleştirme anahtarı AÇIK konuma getirildiğinde, tüm trenin bataryaları bağlanır ve SICAS sistemi aktifleştirilen kabini kalan işlevler için tren komutası olarak tanır.
                        </li>
                        <li>
                        Acil durum ışıklandırma devrededir
                        </li>
                        <li>
                        SICAS bağlı ve HMI devrededir. SICAS birincil emniyet devresinin hızlı bir testini yapar.
                        </li>
                        <li>
                        PIS, CCTV ve eğlence sistemi 1 dakika sonra bağlanır.
                        </li>
                        <li>
                        Trende bir kabin aktifleştirildiğinde, trendeki tüm düşük yüklere  bataryalardan güç sağlanır.
                        </li>
                        <li>
                        ATS açılır.
                        </li>
                        <li>
                        ERTMS devreye girer,yolcu kapılarına yön verilebilir.
                        </li>
                        <li>
                        Trende bir kabin aktif edildiğinde başka kabin aktif edilemez.
                        </li>
                        <li>
                        Uzun süre bataryadan enerji verilmemelidir.
                        </li>
                    </ul>

                     <h2 >
                        SOĞUK PARK
                    </h2>
                    <ul>
                        <li>
                            Soğuk Park modu trenin durdurulması ve uzun süre servis dışı bırakılması anlamına gelir.
                        </li>
                        <li>
                            Kabin tavanı sağ orta paneldeki SOĞUK PARK düğmesine (11S03) basın.
                        </li>
                        <li>
                            Kabin aktifleştirme anahtarının bağlantısını keserek kabini devre dışı bırakın.
                        </li>
                        <li>
                            Bu durumda iken :
                            <ul>
                                <li>
                                	Yardımcı redresörler çalışmayı sürdürür. 
                                </li>
                                <li>
                                Ana kompresör trende hava basıncını korumak için çalışmayı sürdürür
                                </li>
                                <li>
                                	HVAC sistemi yedekte bekleme modunda çalışmayı sürdürür ve araçların içinde sıcaklık 5 ºC altına düşmez.
                                </li>
                                <li>
                                	Tren içinde yetkisiz kişileri kaydetmek için CCTV sistemi aktif kalır.
                                </li>
                                <li>
                                	Trende gece ışıklandırması, yani flüorsan lambalar ve acil durum halojen lambalar açık kalır.
                                </li>
                                <li>
                                	Okuma lambaları çalışmayı sürdürür.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default CabinActive;