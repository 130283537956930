import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { setFaultList } from '../../../store/actions';
import { selectTrainList, selectTopFaUltList } from '../../../store/selectors';
import axios from 'axios';
class LeftUp extends Component {

    state = {
        "train": "",
        "topfault": "",
        "subfaultlist": [],
        "subfault": "",
        "faultlist": [],
        "date1": "",
        "date2": "",
        "rotaKmDurum": false,
        "rotaKm1": "",
        "rotaKm2": "",
        "serviceRota": "",
        "serviceList": [],
        "vagon": "-",
    }


    componentDidMount = async () => {

        await Promise.all([
            axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/list").then(res => {
                this.setState({
                    faultlist: res.data
                });
                this.props.setFaultList(res.data)
            }).catch(() => {
                window.location.replace("/login")
            }),
            axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/service/list")
                .then(res => this.setState({
                    serviceList: res.data
                }))
                .catch(() => {
                    window.location.replace("/login")
                })
        ])

    }

    onChangeTrain = e => {
        this.setState({
            train: e.target.value
        });
    }

    onChangeTopFault = e => {
        let subf = this.props.topfaultlist.filter(uf => uf._id === e.target.value);
        if (subf[0]) {
            subf[0].name === "ERTMS" || subf[0].name === "ATS" ?
                this.setState({
                    rotaKmDurum: true
                })
                :
                this.setState({
                    rotaKmDurum: false,
                    rotaKm1: "",
                    rotaKm2: "",
                    vagon: "-"
                })
        }
        else {
            this.setState({
                rotaKmDurum: false,
                rotaKm1: "",
                rotaKm2: ""
            })
        }

        this.setState({
            topfault: e.target.value,
            subfaultlist: subf
        });
        if (e.target.value === "") {
            this.setState({
                subfault: ""
            })
        }
    }

    onChangeSubFault = e => {
        this.setState({
            subfault: e.target.value
        })
    }

    onChangeDate = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onClearDateFields = () => {
        this.setState({
            date1: "",
            date2: "",
            train: "",
            topfault: "",
            subfault: "",
            rotaKmDurum: false,
            rotaKm1: "",
            rotaKm2: "",
            serviceRota: "",

        })
    }

    onChangeRotaKm = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeServiceRota = e => {
        this.setState({
            serviceRota: e.target.value
        })
    }

    onChangeVagon = e => {
        this.setState({
            vagon: e.target.value
        })
    }

    onClickSave = async (e) => {
        e.preventDefault();
        const { train, subfault, topfault, date1, date2, serviceRota, rotaKm1, rotaKm2, vagon } = this.state;
        let filterFaultList;

        if (rotaKm1 === "" && rotaKm2 !== "")
            alert("Lütfen Rota Km değerlerini doldurunuz.")
        else if (rotaKm2 === "" && rotaKm1 !== "")
            alert("Lütfen Rota Km değerlerini doldurunuz.")
        else {
            if (vagon !== "-") {
                filterFaultList = this.state.faultlist.filter(f => f.vagon === vagon);
                if (date1 !== "" && date2 !== "" && serviceRota !== "" && rotaKm1 !== "" && rotaKm2 !== "") {
                    filterFaultList = filterFaultList.filter(f => serviceRota === f.serviceInformation[0]._id && parseInt(rotaKm1) <= parseInt(f.rotaKm) && parseInt(f.rotaKm) <= parseInt(rotaKm2))

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime()
                            && serviceRota === f.serviceInformation[0]._id)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime()
                            && serviceRota === f.serviceInformation[0]._id)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime()
                            && serviceRota === f.serviceInformation[0]._id)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime()
                            && serviceRota === f.serviceInformation[0]._id)
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 === "" && date2 === "" && serviceRota !== "" && rotaKm1 === "" && rotaKm2 === "") {
                    filterFaultList = filterFaultList.filter(f => f.serviceInformation[0]._id === serviceRota);

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 === "" && date2 === "" && serviceRota === "" && rotaKm1 !== "" && rotaKm2 !== "") {

                    filterFaultList = filterFaultList.filter(f => parseInt(rotaKm1) <= parseInt(f.rotaKm) && parseInt(f.rotaKm) <= parseInt(rotaKm2));

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 !== "" && date2 !== "" && serviceRota === "" && rotaKm1 !== "" && rotaKm2 !== "") {

                    filterFaultList = filterFaultList.filter(f => parseInt(rotaKm1) <= parseInt(f.rotaKm) && parseInt(f.rotaKm) <= parseInt(rotaKm2));

                    filterFaultList = filterFaultList.filter(f =>
                        new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 !== "" && date2 !== "" && serviceRota !== "" && rotaKm1 === "" && rotaKm2 === "") {

                    filterFaultList = filterFaultList.filter(f => serviceRota === f.serviceInformation[0]._id);

                    filterFaultList = filterFaultList.filter(f =>
                        new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 !== "" && date2 !== "" && serviceRota === "" && rotaKm1 === "" && rotaKm2 === "") {

                    filterFaultList = filterFaultList.filter(f =>
                        new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 === "" && date2 === "" && serviceRota === "" && rotaKm1 === "" && rotaKm2 === "") {
                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList()
                    }
                }

                else {
                    alert("Lütfen başlangıç tarihini ve bitiş tarihini seçin.")
                }
            }
            else {
                if (date1 !== "" && date2 !== "" && serviceRota !== "" && rotaKm1 !== "" && rotaKm2 !== "") {
                    filterFaultList = this.state.faultlist.filter(f => serviceRota === f.serviceInformation[0]._id && parseInt(rotaKm1) <= parseInt(f.rotaKm) && parseInt(f.rotaKm) <= parseInt(rotaKm2))
                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime()
                            && serviceRota === f.serviceInformation[0]._id)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime()
                            && serviceRota === f.serviceInformation[0]._id)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime()
                            && serviceRota === f.serviceInformation[0]._id)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        filterFaultList = filterFaultList.filter(f =>
                            new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime()
                            && serviceRota === f.serviceInformation[0]._id)
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 === "" && date2 === "" && serviceRota !== "" && rotaKm1 === "" && rotaKm2 === "") {
                    filterFaultList = this.state.faultlist.filter(f => f.serviceInformation[0]._id === serviceRota);

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 === "" && date2 === "" && serviceRota === "" && rotaKm1 !== "" && rotaKm2 !== "") {

                    filterFaultList = this.state.faultlist.filter(f => parseInt(rotaKm1) <= parseInt(f.rotaKm) && parseInt(f.rotaKm) <= parseInt(rotaKm2));

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 !== "" && date2 !== "" && serviceRota === "" && rotaKm1 !== "" && rotaKm2 !== "") {

                    filterFaultList = this.state.faultlist.filter(f => parseInt(rotaKm1) <= parseInt(f.rotaKm) && parseInt(f.rotaKm) <= parseInt(rotaKm2));

                    filterFaultList = filterFaultList.filter(f =>
                        new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 !== "" && date2 !== "" && serviceRota !== "" && rotaKm1 === "" && rotaKm2 === "") {

                    filterFaultList = this.state.faultlist.filter(f => serviceRota === f.serviceInformation[0]._id);

                    filterFaultList = filterFaultList.filter(f =>
                        new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 !== "" && date2 !== "" && serviceRota === "" && rotaKm1 === "" && rotaKm2 === "") {

                    filterFaultList = this.state.faultlist.filter(f =>
                        new Date(date1).getTime() <= new Date(f.date.split("-").reverse().join("-")).getTime() && new Date(f.date.split("-").reverse().join("-")).getTime() <= new Date(date2).getTime())

                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = filterFaultList.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(filterFaultList)
                    }
                }

                else if (date1 === "" && date2 === "" && serviceRota === "" && rotaKm1 === "" && rotaKm2 === "") {
                    if (topfault === "" && subfault === "" && train !== "") {
                        filterFaultList = this.state.faultlist.filter(fault => fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault === "" && topfault !== "") {
                        filterFaultList = this.state.faultlist.filter(fault => fault.topFault === topfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train === "" && subfault !== "" && topfault !== "") {
                        filterFaultList = this.state.faultlist.filter(fault => fault.topFault === this.state.topfault && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault === "" && topfault !== "") {
                        filterFaultList = this.state.faultlist.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else if (train !== "" && subfault !== "" && topfault !== "") {
                        filterFaultList = this.state.faultlist.filter(fault => fault.topFault === this.state.topfault && fault.trainNumber === train && fault.subFault === subfault)
                        await this.props.setFaultList(filterFaultList);
                    }

                    else {
                        await this.props.setFaultList(this.state.faultlist)
                    }
                }

                else {
                    alert("Lütfen başlangıç tarihini ve bitiş tarihini seçin.")
                }
            }
        }
    }

    render() {
        const { train, topfault, subfault, subfaultlist, date1, date2, rotaKm1, rotaKm2, rotaKmDurum, serviceRota, serviceList, vagon } = this.state;

        return (
            <div className='p-3'>
                <div className="form-group row">
                    <label htmlFor="LeftTrain" className="col-sm-3 col-form-label">
                        Tren Numarası:
                    </label>
                    <div className="col-sm-8" >
                        <select className="col-sm-12 form-select p-2" id="LeftTrain" name="LeftTrain"
                            value={train} onChange={this.onChangeTrain} >
                            <option value="">Tüm trenler</option>
                            {
                                this.props.trains.map(train => (
                                    <option key={train._id} value={train._id}>{train.name}</option>
                                ))
                            }

                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="LeftTop" className="col-sm-3 col-form-label">
                        Üst Arıza:
                    </label>
                    <div className="col-sm-8" >
                        <select className="col-sm-12 form-select p-2" id="LeftTop" name="LeftTop"
                            value={topfault} onChange={this.onChangeTopFault}  >
                            <option value="">Tüm Üst Arızalar</option>
                            {
                                this.props.topfaultlist.map(fault => (
                                    <option key={fault._id} value={fault._id}>{fault.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="LeftSub" className="col-sm-3 col-form-label">
                        Alt Sorun:
                    </label>
                    <div className="col-sm-8" >
                        <select className="col-sm-12 form-select p-2" id="LeftSub" name="LeftSub"
                            value={subfault} onChange={this.onChangeSubFault}>

                            <option value="">Tüm alt sorunlar</option>
                            {
                                subfaultlist[0] ? subfaultlist[0].subIssue.map(sI => (
                                    <option key={sI._id} value={sI.name}>{sI.name}</option>
                                )) : null
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="serviceRota" className="col-sm-3 col-form-label">
                        Rota:
                    </label>
                    <div className="col-sm-8" >
                        <select className="col-sm-12 form-select p-2" id="serviceRota" name="serviceRota"
                            value={serviceRota} onChange={this.onChangeServiceRota}>

                            <option value="">Tüm rotalar</option>
                            {
                                serviceList.map(sI => (
                                    <option key={sI._id} value={sI._id}>{sI.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                {
                    rotaKmDurum ? <div className="form-group row">
                        <label htmlFor="Vagon" className="col-sm-3 col-form-label">
                            Vagon:
                        </label>
                        <div className="col-sm-8 mt-1">
                            <select className="col-sm-12 form-select p-2" id="Vagon"
                                required value={vagon} onChange={this.onChangeVagon} >
                                <option value="-">Tüm Vagonlar</option>
                                <option key="1" value="TCB">TCB</option>
                                <option key="2" value="TCF">TCF</option>
                            </select>
                        </div>
                    </div> : null
                }

                {
                    rotaKmDurum ? <div className="form-group row">
                        <label htmlFor="RotaKm" className="col-sm-3 col-form-label">
                            Km Aralığı:
                        </label>
                        <div className="col-sm-8 mt-1 d-flex">
                            <input type="text" className="form-control col-sm-6" id="RotaKm" name="rotaKm1" value={rotaKm1}
                                onChange={this.onChangeRotaKm} maxLength="3" minLength="1" required placeholder='Başlangıç Km' />
                            <input type="text" className="form-control col-sm-6" id="RotaKm" name="rotaKm2" value={rotaKm2}
                                onChange={this.onChangeRotaKm} maxLength="3" minLength="1" required placeholder='Bitiş Km' />
                        </div>
                    </div> : null
                }

                <div className="form-group row">
                    <label htmlFor="date1" className="col-sm-3 col-form-label">
                        Başlangıç Tarihi:
                    </label>
                    <div className="col-sm-8" >
                        <input type="date" max={date2} className="col-sm-12" rows="6" name="date1" value={date1} onChange={this.onChangeDate} required />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="date2" className="col-sm-3 col-form-label">
                        Bitiş Tarihi:
                    </label>
                    <div className="col-sm-8" >
                        <input type="date" min={date1} className="col-sm-12" rows="6" name="date2" value={date2} onChange={this.onChangeDate} required />
                    </div>
                </div>

                <div className=" row text-right mt-4">
                    <div className="col-sm-11">
                        <button className="btn right-part-clear-date mr-2 " onClick={this.onClearDateFields}>
                            Filtreleri Temizle
                        </button>
                        <button className="btn right-part-button" onClick={this.onClickSave}>
                            Filtrele
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    trains: selectTrainList(),
    topfaultlist: selectTopFaUltList(),
});

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({ setFaultList }, dispatch)
    )
)

export default connect(mapStateToProps, mapDispatchToProps)(LeftUp);