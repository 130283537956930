import React, { Component } from 'react'
import axios from 'axios';
class AddTopFaults extends Component {

    state = {
        "name": "",
        "ErrorMessage": ""
    }

    onChange = e => {
        this.setState({
            name: e.target.value,
            ErrorMessage: ""
        });
    }

    onClickSave = async (e) => {
        e.preventDefault();

        await axios.post("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/add/", {
            name: this.state.name
        }).then(() => {
            window.location.reload()
        }).catch(err => {
            this.setState({
                ErrorMessage: err.response.data.errorMessage
            })
        })
    }

    render() {
        const { name, ErrorMessage } = this.state;
        return (
            <div className='train-part'>
                <div className='part-header'>
                    Üst Arıza Ekle
                </div>

                <div className='part-body'>
                    <form onSubmit={this.onClickSave}>
                        <div className="form-group row">
                            <label htmlFor="TopFault" className="col-sm-3 col-form-label">
                                Arıza Adı:
                            </label>
                            <div className="col-sm-9 mt-1">
                                <input type="text" className="form-control" id="TopFault" name="name" value={name}
                                    onChange={this.onChange} required />
                            </div>
                        </div>

                        {ErrorMessage ? <div className="alert alert-danger">{ErrorMessage}</div> : <div></div>}

                        <div className=" row text-right mt-4">
                            <div className="col-sm-12">
                                <button type="submit" className="btn right-part-button">
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default AddTopFaults;