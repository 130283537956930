import React, { Component } from 'react'
import Footer from '../../../Footer';
import Navbar from '../../../Navbar'

class EmergencyBypass extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                <h4>
                    Azaltılmış Mod Baypas:
                </h4>
                <p>
                    MVB veri yolu çalışmazsa, tren hareket edemez, çünkü hareket veya fren komutu olmaz. Böyle durumlarda, tren Azaltılmış Moda girerek hareket ettirilebilir veya servis dışına alınabilir.
                    <br />
                    Azaltılmış Mod kabin tavan sol panelinde bulunan AZALTILMIŞ MOD anahtarı (14S01) B pozisyonuna getirilerek seçilir.
                    <br />
                    <b>Bu modda ;</b>
                    <ul>
                        <li>
                            Elektrik fren devre dışı kalır ve sadece pönomatik fren çalışır.
                        </li>
                        <li>
                            SINIRLI HIZ anahtarı ile seçilir.
                        </li>
                        <li>
                            Hız 100km ile sınırlıdır.
                        </li>
                    </ul>
                </p>
                <h4>
                    ATMS Baypas:
                </h4>
                <p>
                    Kabin tabanı sol panelinde bulunan  YATAK SICAKLIK İKAZI İPTAL baypas anahtarı (93S01) aktive edilerek ATMS sisteminde bulunan ve birincil emniyet devresi açık olduğu için acil durum freninin uygulanmasına neden olan bir alarmı önlemek için kullanılabilir.
                    <br />
                    Bu KOŞULLARDA TREN ATMS CİHAZLARINDAN EMNİYET OLMADAN ÇALIŞABİLİR.
                </p>

                <h4>
                    Acil Durum Modu:
                </h4>
                <ul>
                    <li>
                        Üç batarya şarj cihazından ikisi çalışmadığında trende bir acil durum oluşur; bunlar çalışmazsa bunlara güç verecek değişken voltaj ya da trende yüksek voltaj yoktur.
                    </li>
                    <li>
                        Acil durum 10 dakikadan fazla sürerse, flüoresan lambalar açıksa otomatik olarak kapatılır ve hedef ekranlara ve eğlence sistemine verilen güç de kesilir.
                    </li>
                    <li>
                        Bu, bataryalar üzerindeki en büyük yükleri kaldırır ve daha uzun dayanmalarını sağlar.
                    </li>
                    <li>
                        Acil durum 60 dakikadan fazla sürerse, acil olmayan halojen lambalar açıksa kapatılır. Bu andan itibaren batarya voltajı izleme sistemi aktif olur.
                    </li>
                    <li>
                        Bataryalar açık kalır, ama durumları batarya şarj cihazları tarafından izlenir ve trenin tekrar çalışmasını sağlayan seviyenin altına düşerlerse bağlantıları kesilir.
                    </li>
                </ul>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default EmergencyBypass;