import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar'

class ServiceBraketestEmerBrake extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h3>
                        SERVİS FRENİ TESTİ
                    </h3>
                    <ul>
                        <li>
                            ERTMS'yi kur.
                        </li>
                        <li>
                            MP basıncı 6,5 bardan fazla olacak.
                        </li>
                        <li>
                            Park freni devrede olacak.
                        </li>
                        <li>
                            Holding break ( tutma freni)devrede olacak.
                        </li>
                        <li>
                            Sıcas da B41 valfleri <b>DOĞRUDAN</b> frende olacak.
                        </li>
                        <li>
                            Fren /gaz kolunu N getirin.
                        </li>
                        <li>
                            BP manometresinde kırmızı BP göstergesi 5 bar a çıkacak.
                        </li>
                        <li>
                            SICAS menü 2 de fren basınçları 0 bar a düşecek.
                        </li>
                        <li>
                            SICAS menü 2de tüm vagonların B23 basınç valflerinin freni serbest bıraktığını kontrol edin.
                        </li>
                        <li>
                            Fren/Gaz kolunu max. fren konumuna getirin ve BP basıncının 3,5 bara düştüğünü görün.
                        </li>
                        <li>
                            Fren/gaz kolunu tekrar N çekin BP basıncının 5 bara çıktığını görün.
                        </li>
                        <li>
                            Fren /gaz kolunu hızlı şekilde EB ye çekin, Güvenlik döngüsünün açıldığını acil fren uygulandığını ve B41 valflerin <b>DOLAYLI</b> frene geçtiğini görün
                        </li>
                    </ul>

                    <h3>
                        ACİL FREN (B15 MANTAR BUTON)
                    </h3>
                    <ul>
                        <li>
                            Yön kolu ilerde, gaz fren kolu N de BP basıncı 5 barda olacak.
                        </li>
                        <li>
                            Mantar butona(B15) basın.
                        </li>
                        <li>
                            Güvenlik devresi açılacak Kodivit boşalacak.
                        </li>
                        <li>
                            Manometre göstergesi 3 bara çıkacak.
                        </li>
                    </ul>
                </div>
                <Footer />
            </div>

        )
    }
}
export default ServiceBraketestEmerBrake;