import React, { Component } from 'react'
import AddService from './AddService';
import ServiceUpdate from './ServiceUpdate';

class ServiceLeft extends Component {
    render() {
        return (
            <div className='left-train-part'>
                <AddService />
                <ServiceUpdate />
            </div>
        )
    }
}
export default ServiceLeft;