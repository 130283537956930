import React, { useEffect, useState } from 'react'
import { MDBDataTable } from 'mdbreact';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserId } from '../../../../store/selectors';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { setFaultId } from '../../../../store/actions';

function UserFaults(props) {
    const [fault, setFault] = useState([]);

    useEffect(() => {
        if (!props.userId) {
            setFault([]);
            return;
        }
        const getFault = async () => {

            await axios.get(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/list/user/${props.userId}`)
                .then(res => {
                    setFault(res.data.sort((a, b) => {
                        return new Date(b.date.split("-").reverse().join("-")) - new Date(a.date.split("-").reverse().join("-"))
                    }))
                })
        }
        getFault();
    }, [props.userId])

    const onClickDetail = async (id) => {
        await props.setFaultId(id);
        window.location.replace("/fault-detail/" + id);
    }



    const data = {
        columns: [
            {
                label: 'Tren Adı',
                field: 'trainname',
                sort: 'asc',

            },
            {
                label: 'Üst Arıza',
                field: 'topfault',
                sort: 'asc',
            },
            {
                label: 'Alt Arıza',
                field: 'subfault',
                sort: 'asc',
            },
            {
                label: 'Tarih',
                field: 'date',
                sort: 'asc',
            },
            {
                label: 'İşlemler',
                field: 'operation',
                sort: 'asc',
            },
        ],

        rows: fault.map(i => {
            return {
                trainname: i.trainInformation[0].name,
                topfault: i.topFaultInformation[0].name,
                subfault: i.subFault,
                date: i.date,
                operation: <button className='btn update-button mr-2' onClick={() => onClickDetail(i._id)}>
                    Detay
                </button>
            }
        })

    };


    return (
        <div className='back-right'>
            <div className='part-header'>
                Arızalar
            </div>
            <div className='part-body'>
                <MDBDataTable
                    entriesOptions={[5, 10, 25]}
                    hover
                    striped
                    bordered
                    data={data ? data : null}
                    searchLabel='Filtrele'
                    entrieslabel='aasdasdasd'
                    infoLabel={["", "-", "sonuç", ""]}
                    paginationLabel={["Geri", "İleri"]}
                    noRecordsFoundLabel='Sonuç bulunamadı'
                    noBottomColumns={true}
                />
            </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    userId: selectUserId(),
})

const mapDispatchToProps = dispatch => (
    bindActionCreators(
        { setFaultId }, dispatch
    ))
export default connect(mapStateToProps, mapDispatchToProps)(UserFaults);