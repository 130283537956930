import React, { Component } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'

export default class BrakeFaultsTwo extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2 >FREN ARIZALARI</h2>
                    <h5>
                    DCL BASINÇ ARIZASI
                    </h5>
                    <ul>
                        <li>
                        B45 lerdeki basınç sensörü  Kalipere uygulanacak hava basıncını takip eder. Bu hava B41 üzerinden gelmektedir.
                        </li>

                        <li>
                        B41 ve B45 lerdeki hava basıncı ölçülmeli gerekiyorsa valfler değiştirilmelidir.
                        </li>
                        <li>
                        Hava kaybı olabilir.
                        </li>
                    </ul>

                    <h5>
                    KONDİVİT BASINCI YÜKSELMİYOR
                    </h5>
                    <ul>
                        <li>
                        Trenin yürümesi için kondivit basıncının 5 bar olması gerekiyor.
                         </li>

                        <li>
                        ERTMS kurulmamış olabilir.
                        </li>
                        <li>
                        Güvenlik devresi testleri yapılmamış olabilir.
                        </li>
                        <li>
                        Z1 panelde B9 arızalı olabilir.
                        </li>
                        <li>
                        Aktif kabin BCU yu resetlemek gerekiyor olabilir.
                        </li>
                        <li>
                        Kondivit hattında kaçak olabilir.
                        </li>
                        <li>
                        B3 arızalı olabilir.
                        </li>
                    </ul>

                    <h5>
                    ABT TESTİNİ GEÇMİYOR
                    </h5>
                    <ul>
                        <li>
                        Z1 panellerde hava kaybı olabilir.
                         </li>

                        <li>
                        Kondivit hattında ve körük altı küresel vanalarda hava kaybı olabilir.
                        </li>
                        <li>
                        Güvenlik devresi testleri yapılmamış olabilir.
                        </li>
                        <li>
                       <span className="text-danger">5 BAR KONDİVİT BASINCINDA DALGALANMA VE FRENLER AÇIK/KAPALI OLUYOR</span> <br/>
                       Her hangi bir vagonun Z2 panel B20 distribütöründe hava kaybı vardir. Hangi vagondan kaynaklandığı tespit edilip değiştirilmelidir. 
                        </li>
                       
                    </ul>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
