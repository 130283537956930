import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar'


class AuxiliaryrRectifierBattery extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2 >
                        YARDIMCI REDRESÖR ARIZASI
                    </h2>
                    <ul>
                        <li>
                            CVS arızası olarak görünür.
                        </li>
                        <li>
                            A sınıfı bir arızadır.
                        </li>
                        <li>
                            Sadece bir yardımcı redresör arızalanırsa tren kısıtlamasız çalışabilir.
                        </li>
                        <li>
                            İkinci redresörün arızalanması durumunda HSBC'ler devre dışı kalır. Tren fren uygular.
                        </li>
                    </ul>

                    <h2>
                        BATARYA ŞARJ CİHAZI ARIZASI
                    </h2>
                    <ul>
                        <li>
                            Sadece bir yardımcı şarj cihazı arızalanırsa tren kısıtlamasız çalışabilir.
                        </li>
                        <li>
                            Üç batarya şarj cihazından ikisi arızalanırsa tren  acil durum moduna geçer.
                        </li>
                        <li>
                            Not : Acil Durum Modu
                        </li>
                    </ul>

                    <h3 className="text-center">
                        BCU ARIZALARI
                    </h3>
                    <ul>
                        <li>
                            Fren arızaları
                        </li>
                        <li>
                            Pnömatik sistem arızaları
                        </li>
                        <li>
                            Aks sıcaklık arızaları
                        </li>
                        <li>
                            MGS2 sistemi devre dışı
                        </li>
                        <li>
                            DNRA sistemi devre dışı
                        </li>
                        Şeklinde SICAS ekranında değişik kodlarda görülür.Sıcas kayıt eder.Bir BCU arızalanırsa, C2 elektrik dolaplarında bulunan baypas anahtarı (60S01) ile baypas yapılmalıdır.

                    </ul>

                    <h4>
                        Aktif vagonda BCU arızası:
                    </h4>
                    <ul>
                        <li>
                            Dolaylı pnömatik fren çalışmaz
                        </li>
                        <li>
                            Bu vagonda baypas anahtarı (60S01) aktive edilir.
                        </li>
                        <li>
                            Sürücü masasında bulunan anahtar (60S02) ile yedek fren moduna geçilir.
                        </li>
                    </ul>

                    <h4>
                        Aktif olmayan  vagonda BCU arızası:
                    </h4>

                    <ul>
                        <li>
                            Bu vagonda baypas anahtarı (60S01) aktive edilir.
                        </li>
                    </ul>
                </div>
                <Footer />
            </div>
        )
    }
}
export default AuxiliaryrRectifierBattery;