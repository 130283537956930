import React, { Component } from 'react'
import ImageOne from '../Images/ColdSet-1.png'
import ImageTwo from '../Images/ColdSet-2.jpg'
import ImageThree from '../Images/ColdSet-3.png'
import ImageFour from '../Images/ColdSet-4.png'
import ImageFive from '../Images/ColdSet-5.png'
import ImageSix from '../Images/ColdSet-6.png'
import ImageSeven from '../Images/ColdSet-7.png'
import ImageEight from '../Images/ColdSet-8.png'
import ImageNine from '../Images/ColdSet-9.png'
import Footer from './Footer'
import Navbar from './Navbar'
class ColdSet extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3">
                    <h2>
                        SETİN SOĞUK ÇEKİLMESİ
                    </h2>

                    <div>
                        <img src={ImageOne} alt={ImageOne} />
                        <p> Kuplör kapağı otomati açılmaz ise:</p>
                        <ul>

                            <li>
                                Sağ taraf yandaki havaları kapatın .Alt kapağı hafif yukarı kaldırırken Üst kapaktaki kareyi sola çevirip alt kapağı bırakın.
                            </li>
                            <li>
                                Alt kapak kilit mekanizmasından kurtulduğunda aşağı iner.
                            </li>
                            <li>
                                Üst kapağı yukarı kaldırın.
                            </li>
                        </ul>
                    </div>
                    <div >
                        <img src={ImageTwo} alt={ImageTwo} />
                        <p> Ön taraf sağdaki hava anahtarlarından üstteki anahtarı kapatın.</p>
                    </div>

                    <div>
                        <img src={ImageThree} alt={ImageThree} />
                        <p>Üst kapak yukarı kaldırılırken bir kişinin yardımıyla Kuplörün sol köşesindeki: 1 numaralı kol sola doğru bastırılır ve 2 numaralı kol yukarı doğru itilir.2 numaralı kol itilirken kapak
                            mekanizmasının da geriye doğru gitmesi gerekir. Mekanizma tam geriye dayandığında 1 numaralı kol bırakılır. Sağ köşede üst kapak tutma kilidinin kapak
                            koluna bağlanması gerekir.
                        </p>
                    </div>

                    <div>
                        <img src={ImageFour} alt={ImageFour} />
                        <p>Seti çekecek Dizel makina sete 3-4m kadar yaklaşır. <br />
                            Yardımcı kuplörün takılacağı çeki kancası görülmektedir .
                        </p>
                    </div>

                    <div>
                        <img src={ImageFive} alt={ImageFive} />
                        <ul>
                            <li>
                                1 yardımcı kuplör sabitleme kancası
                            </li>
                            <li>
                                2 sete bağlı durumdaki yardımcı kuplör
                            </li>
                            <li>
                                Eğer dizel makinada yardımcı kuplör yok ise:
                                <ul>
                                    <li>
                                        Yardımcı kuplörün sabitleme kancası sağ tarafınıza gelecek şekilde tutunuz. kuplörü kaldırıp altındaki boşluğu dizelin çeki
                                        kancasına takınız. sabitleme kancasının çeki okunun ucuna geçtiğinden emin olunuz. Kuplörü sola aşağı doğru yatırınız
                                        yere paralel durması gerekir.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <img src={ImageSix} alt={ImageSix} />
                        <ul>
                            <li>
                                Dizel setin üzerine geldiğinde kuplörler birbirine yapışır. Ve kilitlenmesi gerekir.
                            </li>
                            <li>
                                BP hortumunu dizel makinada sol taraftaki sarı hortumla birleştirin.
                            </li>
                            <li>
                                MP hortumunu dizel makinadaki mavi hortumla birleştirin.
                            </li>
                            <li>
                                1 hava hortumu birleştirme aparatı.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <img src={ImageSeven} alt={ImageSeven} />
                        <ul>
                            <li>
                                Kabinin sol tarafındaki BP ve MP vanaları kapalıysa açık konuma getirin( Kollar dik konumda olmalıdır).
                            </li>
                            <li>
                                Dizel üzerindeki mavi hava vanasını açın.
                            </li>
                        </ul>
                    </div>

                    <div>
                        <img src={ImageEight} alt={ImageEight} />
                        <ul>
                            <li>
                                B8.1 ve B8.2 SIFA valflerinin kollarını yukarı kaldırın
                            </li>
                            <li>
                                <b>
                                    SIFA VALFİ 3 YOLLU BİR VALFDİR.
                                </b>
                            </li>
                            <li>
                                AŞAĞI POZİSYON: Ana tanktan gelen BP yi geçirir
                            </li>
                            <li>
                                YUKARI POZİSYON: Dizel makinadan gelen BP yi geçirir.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <img src={ImageNine} alt={ImageNine} />
                        <ul className="text-danger">
                            <li>
                                Dizel makinadaki MAVİ kolu açarak sete hava uygulayın.
                            </li>
                            <li>
                                Z2 panelde B65 valfini kapatın Bojilerin üstündeki park fren kancalarını çekip bırakın.
                            </li>
                            <li>
                                Soğuk trende park frenleri devreye gireceğinden treni hareket ettiremezsiniz. Yavaş hareket ettiriniz balata sürtme sesi olup olmadığını dinleyiniz.
                            </li>
                            <li>
                                Çekilecek trene BP ve MP hava uygulandığında vagonun yan tarafındaki indikatörler YEŞİL e döner. Dizel makine fren uyguladığında KIRMIZI’ya dönmesi gerekir.
                            </li>
                            <li>
                                Treni çekme işlemi tamamlandığında seti Dizel makinadan ayırmadan 1. Vagonun Z2 panelinden B65 valfini açın ve seti sıcak yapın. Park freni devreye girecektir.
                            </li>
                            <li>
                                SIFA valfi kollarını aşağı yöne çevirin.
                            </li>
                            <li>
                                Setin bağlantısını kesmek için kuplörün yan tarafındaki kırmızı kolu kuvvetli çekiniz. Kuplör kilitleri açılır.
                            </li>
                            <li>
                                Dizel makinayı geri çekin.
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default ColdSet;