import React, { Component } from 'react'
import ImageOne from '../Images/Image-1.PNG'
import ImageTwo from '../Images/Image-2.PNG'
import Footer from './Footer';
import Navbar from './Navbar';
class TrainStructure extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                <h2>
                    65000 SERİSİ YHT SETLERİ
                </h2>
                <p>
                    Altı vagondan oluşmaktadır. Çift yönlü çalıştırılabilmektedir.350 ton ağırlığında olup 450 yolcu kapasitelidir.3 Erli 2 gruptan oluşmaktadır. Vagonların ön tarafı E1 arka tarafı E2 olarak anılmaktadır. Setin TCB ye göre sağ tarafı J sol tarafı K ile gösterilmektedir. Vagonların E1 taraf sol C dolabında DC sigortaları,E2 taraf Sol C dolabında AC sigortaları vardır. MIFC vagonda farklı olup E1 taraf sol C dolabında DC sigortaları Sağ C dolabında AC sigortaları vardır.
                </p>
                <div className="w-100 d-flex">
                    <img className="w-50" src={ImageOne} alt={ImageOne} />
                    <img className="w-50" src={ImageTwo} alt={ImageTwo} />
                </div>
                <h4 >
                    ANA DONANIMLAR
                </h4>
                <ul>
                    <li>
                        <b>Z1 FREN PANELİ:</b> YEŞİL Kondivit basıncını üretir.
                    </li>
                    <li>
                        <b>Z2 FREN PANELİ:</b> MAVİ Fren talebine göre tekerlere fren basıncı uygular.
                    </li>
                    <li>
                        <b>Z3 YARDIMCI PANEL:</b> Vagon altında dır. Kumlama sistemi, Hava yastıkları. Yolcu kapısı ve wc lerin havasını verir.
                    </li>
                    <li>
                        <b>Z4 YARDIMCI PANEL:</b> TCB-TCF kabin konsolun sağ tarafındadır.Boden yağlama, makinist koltuğu ,kornalar ve Şaftungberg in havasını verir.
                    </li>
                    <li>
                        <b>Z5 PANTOGRAF PANEL:</b> TCB-TCF vagon altında yardımcı kompresör yanındadır.
                    </li>
                    <li>
                        <b>ANA KOMPRESÖR:</b> TURUNCU Setin 10bar hava ihtiyacını karşılar. MIFC sürekli yedektedir. Hava yetmediğinde veya diğer kompresörlerdeki arıza durumunda devreye girer.
                    </li>
                    <li>
                        <b>PANTOGRAF:</b> TCB VE TCF vagonlardadır. GENİŞ ve DAR olarak iki çeşittir.
                    </li>
                    <li>
                        <b>CER KONVERTÖRLERİ:</b> MIFC-MIF1A-MIF1B ve MIF2 vagonlardadır. Motorlara cer gücü ve fren kontrolü sağlar.
                    </li>
                    <li>
                        <b>CER MOTORU:</b> Trenin hareketini sağlayan ve dinamik fren de seti yavaşlatan yıldız bağlı 3 fazlı AC motordur.
                    </li>
                    <li>
                        <b>200KW YARDIMCI KONVERTÖR:</b> Trenin 400V AC ve 72V DC gücünü karşılayan cihazdır.
                    </li>
                    <li>
                        <b>ANA TRAFO:</b> Katenerden gelen 25000V AC gerilimi cer konvertörleri için 1500V AC ve yardımcı konvertörler için 400V AC'ye dönüştüren yağ soğutmalı ünitedir.
                    </li>
                </ul>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default TrainStructure;