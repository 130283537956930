import * as constants from './constants';

export const setTrainList = state => ({
    type:constants.SET_TRAIN_LIST, payload:state
});

export const setTopFaultList = state => ({
    type:constants.SET_TOPFAULT_LIST, payload:state
});

export const setFaultList = state => ({
    type:constants.SET_FAULT_LIST, payload:state
})

export const setPopupInfo = state => ({
    type:constants.SET_POPUP_INFO, payload:state
})

export const setFilterByTrain = state => ({
    type:constants.SET_FILTER_BY_TRAIN, payload:state
})

export const setUserId = id => ({
    type:constants.SET_USER_ID, payload:id
})

export const setTrainId = id => ({
    type:constants.SET_TRAIN_ID, payload:id
})

export const setServiceId = id => ({
    type:constants.SET_SERVICE_ID, payload:id
})

export const setTopFaultId = id => ({
    type:constants.SET_TOPFAULT_ID, payload:id
})

export const setSubFaultId = id => ({
    type:constants.SET_SUBFAULT_ID, payload:id
})

export const setUserAuthority = authority => ({
    type:constants.SET_USER_AUTHORITY, payload:authority
})

export const setLogin = login => ({
    type:constants.SET_LOGIN, payload:login
})

export const setDefault = () => ({
    type:constants.SET_DEFAULT
})

export const setFaultId = id => ({
    type:constants.SET_FAULT_ID, payload:id
})

export const setUserIstatistics = userIstatistics => ({
    type:constants.SET_USERS_ISTATISTICS, payload: userIstatistics
})