import React, { Component } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'

class ATMSFaults extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>ATMS ARIZALARI</h2>
                    <p>
                        Sıcaklıklar ve akselerasyonlar izleme sisteminin fonksiyonu mil rulmanlarında ve motor redüksiyon rulmanlarının sıcaklığı ile bogilerin yanal akselerasyonunun ölçülmesi ve izlenmesidir.
                        <br />
                        Anormal olarak sınıflandırılan sıcaklık ve akselerasyonun tespit edilmesi durumunda, sistem makinisti bildirir ve bunların, makinistin herhangi bir müdahalesi olmaksızın önceden tanımlanmış bir dizi eşiği aşması durumunda sistem kendi başına trenin durmasına neden olur.
                        <br />
                        Aks yağ kutularının içinde dingil rulman sıcaklıklarını takip etmek için 2 sensör ve hızı takip etmek için 1-3. Tekere hız sensörü yerleştirilmiştir.
                        <br />
                        Motor mili rulmanlarında sıcaklığı izlemek için redüktöre 2 ilave sensör yerleştirilmiştir. Bunlar SICAS  da görünmez. Bu sıcaklığı cer konvertörü takip eder.
                        Dişli kutularına şaft rulman ve aks rulman sıcaklığını izlemek için 2 sensör konulmuştur.
                        <br/>
                        Tüm sensörler yedeklidir.
                        <br/>
                        Her bojiye 1 adet ivme sensörü konulmuştur.
                        <br/>
                        Boji üstündeki tüm sensörlerin kablolaması boji üstündeki ATMS barasında toplanır.
                        <br/>
                        Sol baş ATMS1,orta ATMS2,sağ baş ATMS3 olarak adlandırılmıştır.
                        <br/>
                        Her bojide sıcaklık değerlerini takip eden ve SICAS a bildiren ATMS cihazı vardır.
                        <br/>
                        Hız sensörleri ATMS 3 kablosu ile doğrudan BCU ya bağlıdır.
                    </p>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default ATMSFaults