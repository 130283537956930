import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TMS from './Images/tms.png';
import Train from './Images/login.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDefault } from './store/actions';

function PasswordRemember(props) {
    const [Username, setUsername] = useState("")
    const [Message, setMessage] = useState("")
    const [style, setStyle] = useState("")

    useEffect(() => {
        props.setDefault();
    }, [])

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            onClickSend();
        }
    }
    const onChangeText = (e) => {
        setUsername(e.target.value)
        setMessage(null)
    }

    const onClickSend = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/passwordremember/`, { username: Username.trim() })
                .then(res => {
                    setMessage(res.data.message);
                    setStyle("alert alert-success");
                    setUsername("");
                    
                }).catch(err => {
                    if (err.response) {
                        if (err.response.data.errorMessage) {
                            setMessage(err.response.data.errorMessage);
                            setStyle("alert alert-danger");
                            console.clear();
                        }
                    }
                });
        }
        catch (err) {
            console.log(err)
        }

    }

    return (
        <div className="w-100" style={{ backgroundImage: `url("${Train}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            <div className="logindiv">
                <div style={{ height: "15%" }}>
                    <img src={TMS} alt="TMS" />
                </div>
                <form onSubmit={onClickSend}>
                    <input type="text" value={Username} onChange={onChangeText} placeholder="Kullanıcı adı" onKeyPress={handleKeypress} required /><br /><br />
                    <button className="btn btn-send-password w-100" value="Gönder" >Gönder</button> <br />
                    <Link className="login-link" to="/login">Giriş Yap</Link>
                    {Message ? <div className={style}>{Message}</div> :
                        <div></div>}
                </form>
                <p >TMS ONBOARD</p>
            </div >
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    (
      bindActionCreators({ setDefault }, dispatch)
    )
  )

export default connect(null,mapDispatchToProps)(PasswordRemember);