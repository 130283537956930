import React, { Component } from 'react'
import ImageOne from '../Images/Wctrain.png'
import ImageTwo from '../Images/WcTrain-2.png'
import ImageThree from '../Images/WcTrain-3.png'
import ImageFour from '../Images/WcTrain-4.png'
import ImageFive from '../Images/WcTrain-5.png'
import Navbar from './Navbar'
import Footer from './Footer'
class WCTrain extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>WC EĞİTİMİ</h2>
                    65000 setlerin wc sistemleri:
                    <ul>
                        <li>
                            Vakum sistemi <span className="text-danger">6 bar da çalışır</span>.
                        </li>
                        <li>
                            Su sistemi
                        </li>
                        <li>
                            Kontrol birimi
                        </li>
                        <li>
                            Atık depolama biriminden oluşmaktadır.
                        </li>
                    </ul>
                    Wc arızalarında klozetlerin sökülmesi en sona bırakılmalıdır. Son zamanlarda arkadaşların arızalara müdahale ettiği ancak  arıza giderilemediği gibi parçalara
                    hasar verildiği kabloların karıştırıldığı görülmektedir. <br />
                    Özellikle yeni katılan personel yanında eski personel olmadan kesinlikle wc klozetlerini sökmeyecek. Emin olmadığınız ve şema gerektiren arızalara müdahale etmeyiniz.
                    <h5>LEDLERİN DURUMU</h5>
                    <ul>
                        <li>
                            <b>Waste tank full:</b> Atık tankı dolu
                        </li>
                        <li>
                            <b>No compr.air:  </b> Hava yok. Hava vanası kapalı olabilir. Klozet altında hava hortumu kırılmış olabilir. Hava sıkışmış olabilir.
                        </li>
                        <li>
                            <b>Pressure vac - pressure start:</b> Klozette tıkanıklık olabilir. Başlatılamıyor. SU tankı üzerindeki basınç valfi arızalı olabilir.
                        </li>
                        <li>
                            <b>Bowl full:</b> Klozet su ile dolu olabilir.
                        </li>
                        <li>
                            <b>No fresh water:</b> Temiz su yok
                        </li>
                        <li>
                            Engelli WC'de otomatik kapı arızası da  WC'nin servis dışı olmasına neden olur. Engelli WC de kapıya müdahale etmeden önce acil çağrı butonunun basılı olup olmadığını kontrol ediniz
                        </li>
                        <li>
                            Vakumla ilgili arızalarda çoğunlukla üst kırpıcı ve alt kırpıcının çalışmadığı görülmektedir.
                        </li>
                        <li>
                            Sökülen klozetler yerine oturtulurken kırpıcıların konumuna dikkat ediniz.
                        </li>
                        <li>
                            Su giriş rekoru ve hava giriş rekorunun yerlerine dikkat ediniz.
                        </li>
                    </ul>


                    <div className="d-flex text-center">

                        <div className="w-50">
                            <img src={ImageOne} alt={ImageOne} />
                        </div>

                        <div className="w-50">
                            <img src={ImageTwo} alt={ImageTwo} />
                            <p><b>SU TAHLİYE SİSTEMİ</b></p>
                        </div>
                    </div>

                    <div className="d-flex text-center">
                        <div className="w-50">
                            <img src={ImageThree} alt={ImageThree} />
                           <p><b>RAY DERİVASYON SİSTEMİ</b></p> 
                            <p>50KM altındaki hızlarda lavabo suyunun raya akmasını önler.Y5 valfi damperlerle uyumlu çalışarak basınç oluşmasını önler.</p>
                        </div>
                        <div className="w-50">
                            <img src={ImageFour} alt={ImageFour} />
                           <p> <b>HAVA VE SİGORTA PANELİ</b></p>
                        </div>
                    </div>


                    <div className="text-center">
                        <img src={ImageFive} alt={ImageFive} />
                       <p><b>ENGELLİ WC OTOMATİK KAPI VE (1) HAVA İZALASYON VANASI</b></p> 
                    </div>
                    </div>
                    <Footer/>
            </div>
        )
    }
}

export default WCTrain;