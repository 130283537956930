import React, { Component } from 'react'
import Footer from '../Footer';
import HeaderNav from '../HeaderNav';
import Navbar from '../Navbar';
import LeftSide from './LeftSide';
import TopFaultList from './TopFaultList';

class TopFaults extends Component {
    render() {
        return (
            <div>
                <Navbar pageWrapId={"page-wrap"} />

                <div id="page-wrap">
                    <HeaderNav />
                    <div>
                        <div className='page-header'>
                            Üst Arızalar
                        </div>

                        <div className='users'>
                           <LeftSide />
                           <TopFaultList />
                        </div>
                    </div>
                </div >
                <Footer/>
            </div>
        )
    }
}
export default TopFaults;