import React from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar'

function Pantograf() {
    return (
        <div>
            <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2 >
                    PANTOGRAF ÖN/ARKA  ve PANTOGRAF BÜYÜK/KÜÇÜK
                    </h2>
                    <ul>
                        <li>
                        (12S02) anahtarları ile aktive edilecek pantograf konumunu ve tipini seçin.
                        </li>
                        <li>
                        Seçilen pantografı kaldırmak için PANTOGRAF kolunu (12S03) ileri konuma getirin.
                        </li>
                        <li>
                        İki ünite koşumla bağlandığı takdirde, pantograflara aktif kabinden komuta edilir. Aktif kabinden seçilen pantograf bağlanan trende de kalkar.Pantografların konumu aktif kabine göredir, yani ön pantograf, vagonun TCB veya TCF olmasına bakılmaksızın aktif kabine en yakın olandır.
                        </li>
                        <li>
                        Tüm HSCB’ leri kapatmak için YÜKSEK HIZLI DEVRE KESİCİ kolunu (13S01) ileri konuma getirin.
                        </li>
                        <li>
                        Pantografın kalktığını ve HSBC nin tuttuğunu SICAS HMI dan takip edin.
                        </li>
                        <li>
                        HSCB’ ler kapatılınca cer konvertörleri (20A01) ve dört yardımcı konvertörün üçü (16A01) otomatik çalışır.
                        </li>
                        <li>
                        Çalışacak 3 yardımcı redresörün seçimi SICAS tarafından yapılır. SICAS sistem 400 V.ac beslemenin harici soketlerden (30X01) biri ile iletildiğini algılarsa yardımcı redresörlerin bağlantısı yapılmaz
                        </li>
                    </ul>

                    <h4>
                        <b>
                            PANTOGRAFIN KALKMASI İÇİN :
                        </b>
                    </h4>
                    <ul>
                        <li>
                            MP basıncı 7 bar dan az olmamalıdır.
                        </li>
                        <li>
                            MP basıncı 7 bardan az ise yardımcı kompresör devreye girer ve basınç 7,5 bara çıkıncaya kadar çalışır.
                        </li>
                        <li>
                            Yardımcı kompresör manuel olarakta çalıştırılabilir. Ancak 3dk'dan fazla çalıştırılmamalıdır.
                        </li>
                    </ul>
                    <h4 >
                        <b>
                        HSCB nin TUTMASI İÇİN :
                        </b>        
                    </h4>
                    <ul>
                        <li>
                        Havai hatta yüksek voltaj olmalıdır.
                        </li>
                        <li>
                        	Ana transformatör devrede olmalı, aşırı sıcaklık ya da aşırı akım algılanmış olmamalı ve soğutma Tamam olmalıdır.
                        </li>
                        <li>
                        Cer konvertörleri devrede olmalıdır.
                        </li>
                    </ul>
                    </div>
                    <Footer/>
                </div>
    )
}
export default Pantograf;