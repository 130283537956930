import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'

export default function TrainDriving() {
    return (
        <div>
            <Navbar/>
                <div className="w-75 mx-auto p-3">
                <h2>
                    TREN SÜRÜLMESİ
                </h2>
                <ul>
                    <li>
                        Kapılar kapalı ve basamaklar kapanmalıdır. Kapı kapalı sinyali gelmelidir.(Yeşil lamba yanacak)
                    </li>
                    <li>
                        CER aktif sinyali gelmelidir.(Mavi lamba )
                    </li>
                    <li>
                        Yön kolu FOR veya REV durumuna alınmalıdır.
                    </li>
                    <li>
                        Trenin normal çalışması sırasında, cer ve fren komutları ana kumandada 14A01) cer/fren kolu ile verilir.
                    </li>
                    <li>
                        Ana kumanda, kolun pozisyonuna bağlı olarak, hem çekiş hem de fren için komut sinyalini oluşturur ve SICAS’a iletir. SICAS komut sinyallerini MVB veri
                        yolu ile tüm cer ve fren ekipmanına iletir.
                    </li>

                    <li>
                        <b>
                            Cer/Fren Kolu Konumu Komut Sinyali:
                        </b>
                        <ul>
                            <li>
                                +45 maximum cer
                            </li>
                            <li>
                                +5 minimum cer
                            </li>
                            <li>
                                0 boşta
                            </li>
                            <li>
                                -5 minimum fren
                            </li>
                            <li>
                                -45 maximum fren
                            </li>
                            <li>
                                -57 acil durum freni
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>
                            Sınırlı Hız Modu:
                        </b>
                        <ul>


                            <li>
                                Sürücü masası anahtarlar panelindeki SINIRLI HIZ düğmesi (14S02), basılı tutularak tren hızı 5 km/saat ile sınırlanır
                            </li>
                            <li>
                                Sınırlı Hız Modu yıkama, tünel, koşum, atölye ve manevra işlemleri sırasında kullanılır.(Acil durumlar dışında kullanmayınız
                            </li>
                        </ul>
                    </li>
                </ul>
                </div>
                <Footer/>
            </div>
    )
}
