import React, { Component } from 'react'
import Image from '../Images/ImageKaliper.png'
import ImageOne from '../Images/ImageKaliper-1.png'
import ImageTwo from '../Images/ImageKaliper-2.jpg'
import Navbar from './Navbar'
import Footer from './Footer'


class KaliperPassenger extends Component {
    render() {
        return (
            
            <div className="kaliper">
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2 >KALİPER SONSUZ DİŞLİ ve YOLCU KAPILARI İZOLE KARESİ</h2>
                    <img src={Image} alt={Image} />
                    <p>
                        Kaliper sonsuz dişlinin (körüklü  parça)kenarındadır.  Balatları değiştirmek için veya balata kitlerse açmak için kullanılır. Açtıktan sonra tekrar sıkmaya gerek yoktur. Hava basıncıyla yerine gelir.
                    </p>
                    <p className="text-danger">24 anahtarlar TCB kabinde TOTMAN modülünün olduğu dolabın içindedir.</p>

                    <h5>YOLCU KAPISI İZOLE KARESİNİN BAKIMI</h5>
                    <div>
                        <img src={ImageOne} alt={ImageOne} />
                        <p className="text-danger">KARENİN VAGON İÇİNDEN GÖRÜNÜMÜ</p>
                    </div>
                    <div>
                        <img src={ImageTwo} alt={ImageTwo} />
                        <p className="text-danger">KARENİN DIŞTAN GÖRÜNÜMÜ</p>
                    </div>

                    <ul className="text-danger">
                        <li>
                            Yolcu kapısı karelere dıştan yağ basmak/wd 40 sıkmak bir işe yaramaz.
                        </li>
                        <li>
                            Karelerin içinde O ring olduğundan, kuruduğunda sıkışmaya neden olmaktadır. Sökülerek O ringler yağlanmalıdır.
                        </li>
                        <li>
                            Kapının iç tarafındaki karenin ortasındaki vidayı sökünüz.
                        </li>
                        <li>
                            Kareyi pense ile kendinize çekiniz.
                        </li>
                        <li>
                            Yuvada kalan parçayı vurarak dışarı itekleyiniz. Dışarı çıkan parçanın üzerindeki O ringi yağlayıp yerine takınız.
                        </li>
                    </ul>
                    </div>
                    <Footer/>
            </div>
        )
    }
}
export default KaliperPassenger;