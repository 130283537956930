import React, { Component } from 'react'
import { MDBDataTable } from 'mdbreact';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  setTrainId } from '../../../store/actions';
import axios from 'axios';

class TrainList extends Component {

    state = {
        "trains": [],
        "Message": "",
    }

    componentDidMount = async () => {
        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/train/list/")
            .then(async (res) => {
                this.setState({
                    trains:res.data
                })
            }).catch(()=>{
                window.location.replace("/login")
            })
    }

    onClickDelete = async (id) => {
        axios.delete(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/train/delete/${id}`)
            .then(res => {
                this.setState({
                    ...this.state,
                    trains: this.state.trains.filter(u => u._id !== id),
                    Message: res.data.Message
                })
            })
    }

    onClickUpdate = async (id) => {
        this.props.setTrainId(id)
        window.location.reload();
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Tren Adı',
                    field: 'trainname',
                    sort: 'asc',

                },
                {
                    label: 'İşlemler',
                    field: 'operation',
                    sort: 'asc',
                }
            ],

            rows: this.state.trains.map(i => {
                return {
                    trainname: i.name,
                    operation: <>
                        <button className='btn delete-button mr-2' onClick={this.onClickDelete.bind(this, i._id)}>
                            Sil
                        </button>
                        <button className='btn update-button mr-2' onClick={this.onClickUpdate.bind(this, i._id)}>
                            Tren Seç
                        </button>
                    </>
                }
            })
        };

        return (
            <div className='back-right'>
                <div className='my-fault'>
                    <div className='part-header'>
                        Tren Listesi
                    </div>
                    <div className='part-body'>
                        {this.state.Message ? <div className="alert alert-success">{this.state.Message}</div> : <div></div>}
                        <MDBDataTable
                            entriesOptions={[5, 10, 25]}
                            hover
                            striped
                            bordered
                            data={data}
                            searchLabel='Filtrele'
                            entrieslabel='aasdasdasd'
                            infoLabel={["", "-", "sonuç", ""]}
                            paginationLabel={["Geri", "İleri"]}
                            noRecordsFoundLabel='Sonuç bulunamadı'
                            noBottomColumns={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => (
    (
        bindActionCreators(
            { setTrainId }, dispatch
        )
    )
)
export default connect( null,mapDispatchToProps)(TrainList);