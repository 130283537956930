import React from 'react'
import HeaderNav from '../HeaderNav';
import Navbar from '../Navbar';
import ServiceLeft from './ServiceLeft';
import ServiceList from './ServiceList';

function Service() {
    return (
        <div>
            <Navbar pageWrapId={"page-wrap"} />

            <div id="page-wrap">
                <HeaderNav />
                <div>
                    <div className='page-header'>
                        Tren Rotaları
                    </div>
                    
                    <div className='users'>
                       <ServiceLeft />
                       <ServiceList />
                    </div>
                </div>
            </div >
            
        </div>
    )
}
export default Service;