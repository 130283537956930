import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectFilterByTrain, selectTopFaUltList } from '../../../store/selectors'
import Chart from 'react-google-charts';

class ByTrainPie extends Component {

    pie = () => {
        let dat = [['Fault', 'Fault Counts'],]
        let count = "";
        this.props.topfaults.forEach(tfault => {
          count = this.props.faultlist.filter(fault => fault.topFault === tfault._id).length
          dat.push([tfault.name,count])
        })
        return dat
    }

    render() {
        return (
            <div className='px-3 pb-3'>
            <Chart
               width={'100%'}
               height={'300px'}
               chartType="PieChart"
               loader={<div>Loading Chart</div>}
               data ={
                   this.pie()
               }
               
               options={{
                   title: 'Analiz Grafiği',
                   is3D: true,
               }}
               rootProps={{ 'data-testid': '2' }}
           />
       </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    faultlist:selectFilterByTrain(),
    topfaults:selectTopFaUltList(),
})

export default connect(mapStateToProps)(ByTrainPie)