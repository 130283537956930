import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TMS from './Images/tms.png';
import axios from 'axios';
import UserContext from './context/UserContext';
import Train from './Images/login.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserAuthority, setLogin, setDefault } from './store/actions';

function Login(props) {

  let userName = useRef();
  let password = useRef();
  let history = useHistory();

  const [message, setMessage] = useState("");

  const { getUser } = useContext(UserContext);

  useEffect(() => {
    props.setDefault();
    axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/authentication/logOut")
    .catch(()=>{
      console.clear();
    })
  }, []);

  const handleKeypress = e => {
    if (e.keyCode === 13) {
      onClickLogin();
    }
  }

  const onClickLogin = async (e) => {
    e.preventDefault();

    const loginData = {
      username: userName.current.value.trim(),
      password: password.current.value.trim()
    }

    try {
      await axios.post("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/authentication/login", loginData).then(
        async () => {
          await props.setLogin(true)
          await getUser();
          history.push("/")
        }
      ).catch(err => {
        setMessage(err.response.data.errorMessage);
        console.clear();
      })
    }

    catch (err) {
      if (err.response) {
        if (err.response.data.errorMessage) {
          console.log(err.response.data.errorMessage)
        }
      }
    }
  }

  return (
    <div style={{ backgroundImage: `url("${Train}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
      <div className="logindiv">
        <div style={{ height: "15%" }}>
          <img src={TMS} alt="loginimg" />
        </div>
        <form onSubmit={onClickLogin}>
          <input type="text" ref={userName} placeholder="Kullanıcı adı" onKeyPress={handleKeypress} required />
          <br /><br />
          <input type="password" ref={password} placeholder="Şifre" onKeyPress={handleKeypress} required />
          <br /><br />
          <button className="btn btn-send-password w-100" >Giriş</button>
          <Link className="login-link" to="/sifremi-hatirla">
            Parolamı Unuttum
          </Link>
          {message ? <div className="alert alert-danger">{message}</div> : <div></div>}

        </form>
        <p>
          TMS ONBOARD
        </p>
      </div >
    </div>
  )
}

const mapDispatchToProps = dispatch => (
  (
    bindActionCreators({ setUserAuthority, setLogin, setDefault }, dispatch)
  )
)


export default connect(null, mapDispatchToProps)(Login);