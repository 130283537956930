import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectFaultList } from '../../../store/selectors';
import { setPopupInfo } from '../../../store/actions';
import LeftUp from './LeftUp';

class LeftDown extends Component {

    render() {
        return (
            <div className='number-parts'>

                <div className="parts-header">
                    Arızalar
                </div>

                <LeftUp />

                <div className='parts-body'>
                    <div className="parts-body-parts">
                        <table className="table table-striped" >
                            <thead className="thead">
                                <tr>
                                    <th scope="col">Tren Numarası</th>
                                    <th scope="col">Üst Arıza</th>
                                    <th scope="col">Alt Arıza</th>
                                    <th scope="col">Kullanıcı</th>
                                    <th scope="col">Detay</th>
                                </tr>
                            </thead>

                            <tbody className="tbody">
                                {
                                    this.props.faultlist.map(f => (
                                        <tr key={f._id}>
                                            <td>{f.trainInformation[0].name}</td>
                                            <td>{f.topFaultInformation[0].name}</td>
                                            <td>{f.subFault}</td>
                                          <td>{`${f.userInformation[0].name} ${f.userInformation[0].surname}`} </td>
                                            <td>
                                                <button type="button" className="btn btn-primary px-2 py-1" onClick={async () => {
                                                    await this.props.setPopupInfo(f)
                                                }
                                                } data-toggle="modal" data-target={"#o" + f._id}>
                                                    Detay
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    faultlist: selectFaultList(),
});

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({ setPopupInfo }, dispatch)
    )
)

export default connect(mapStateToProps, mapDispatchToProps)(LeftDown);