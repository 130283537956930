import React, { Component } from 'react'
import Footer from './Footer';
import Navbar from './Navbar';

class WCTrainFaults extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h2>
                        WC EĞİTİMİ
                    </h2>
                    <h5>Arızaların nedenleri ve giderme yöntemleri:</h5>
                    <ul>
                        <li>
                            Atık tankı dolu olabilir
                        </li>
                        <li>
                            Gider hattı tıkalı olabilir
                        </li>
                        <li>
                            Elektrik arızası olabilir
                        </li>
                        <li>
                            Temiz su bitmiş olabilir
                        </li>
                    </ul>
                    <p>
                        Temiz su bitmiş olabilir
                    </p>
                    <h6>Gider hattı tıkalı olabilir:</h6>
                    <ul>
                        <li>
                            Gider hattı çoğunlukla peçete atılması sonucu tıkanmaktadır. Sistemi kendi kendine vakum yaptırmak gerekir. Kendisi vakum yapamazsa klozet sökülüp temizlenmelidir.
                        </li>
                    </ul>
                    <h6>Sisteme vakum yaptırmak:</h6>
                    <ul>
                        <li>
                            Elektrik paneli içindeki Kontrol cihazının R ve S tuşuna basılarak ileri veya geri vakum yapılabilir.
                        </li>
                        <li>
                            Önce S tuşuna 2 sn basınız  klozete su basmadan vakum yapar. S tuşu ile ileri vakum yapamazsa R tuşuna basınız.
                        </li>
                        <li className="text-danger">
                            Geri vakum tehlikeli olacağından kapağı kapalı tutunuz.
                        </li>
                        <li>
                            Vakumla gider hattı açılmaz ise klozet arızaya geçer.
                        </li>
                    </ul>

                    <h6>Elektirik arızası olabilir:</h6>
                    <ul>
                        <li>
                            Elektrik arızalarında çoğunlukla Lavabo altındaki valflerin bobinleri yanmaktadır.
                        </li>
                        <li>
                        Valf bobinlerini elle kontrol ediniz aşırı ısınan ve yanan  varsa elektrik bağlantısını kesiniz. Klozet enerji soketinin yerine tam oturup oturmadığını kontrol ediniz.
                        </li>
                        <li>
                            Elektrik dolabı içindeki Kontrol cihazının ledlerini kontrol ediniz.Ledler arıza ile ilgili yönlendirme yapmaktadır.
                        </li>

                    </ul>
            </div>
            <Footer/>
            </div>
        )
    }
}
export default WCTrainFaults;