import React, { Component } from 'react';
import Left from './Left/Left';
import Right from './Right/Right';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTrainList, setTopFaultList } from '../../store/actions'
import { createStructuredSelector } from 'reselect';
import { selectAuthority } from '../../store/selectors';
import axios from 'axios';


class Faults extends Component {

    componentDidMount = async () => {
        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/train/list").then(res => {
            this.props.setTrainList(res.data)
        }).catch(()=>{
            window.location.replace("/login")
        })

        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/list").then(res => {
            this.props.setTopFaultList(res.data)
        }).catch(()=>{
            window.location.replace("/login")
        })
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="fault">
                    <Left />
                    <Right />
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    authority : selectAuthority()
})

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setTrainList, setTopFaultList
        }, dispatch)
    )
)

export default connect(mapStateToProps, mapDispatchToProps)(Faults);