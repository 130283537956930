import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ATMSFaults from './Components/ATMSFaults/ATMSFaults';
import HeatThreshold from './Components/ATMSFaults/HeatThreshold';
import BrakeFaultsOne from './Components/BrakeFaults/BrakeFaultsOne';
import BrakeFaultsTwo from './Components/BrakeFaults/BrakeFaultsTwo';
import AuxilaryDynamicHoldingBrakeTest from './Components/CafTrainTests.js/AuxilaryDynamicHoldingBrakeTest';
import CafTrainTest from './Components/CafTrainTests.js/CafTrainTest';
import ServiceBraketestEmerBrake from './Components/CafTrainTests.js/ServiceBraketestEmerBrake';
import WSPBCUFaults from './Components/CafTrainTests.js/WSPBCUFaults';
import ColdSet from './Components/ColdSet';
import ExternalSupply from './Components/ExternalSupply';
import Faults from './Components/Faults/Faults';
import HelpMode from './Components/HelpMode';
import HomePage from './Components/HomePage';
import IPPrograms from './Components/IPPrograms';
import KaliperPassenger from './Components/KaliperPassenger';
import MVB from './Components/MVB';
import PassengerDoorSwitch from './Components/PassengerDoorSwitch';
import PassengerEntranceDoors from './Components/PassengerEntranceDoors';
import AuxiliaryrRectifierBattery from './Components/Sicas/AuxiliaryrRectifierBattery';
import Pnomatiksystem from './Components/Sicas/Pnomatiksystem';
import TrainStructure from './Components/TrainStructure';
import BeforeWorking from './Components/VehicleWorking/BeforeWorking';
import CabinActive from './Components/VehicleWorking/CabinActive';
import Ground from './Components/VehicleWorking/Ground';
import Pantograf from './Components/VehicleWorking/Pantograf';
import ABTApplication from './Components/VehicleWorking/TrainDriving/ABTApplication';
import AuxiliaryBrakeMode from './Components/VehicleWorking/TrainDriving/AuxiliaryBrakeMode';
import CerActiveBypass from './Components/VehicleWorking/TrainDriving/EmergencyWorking/CerActiveBypass';
import DoorEmergencyBrake from './Components/VehicleWorking/TrainDriving/EmergencyWorking/DoorEmergencyBrake';
import EmergencyBypass from './Components/VehicleWorking/TrainDriving/EmergencyWorking/EmergencyBypass';
import FirstSafetyCircuit from './Components/VehicleWorking/TrainDriving/EmergencyWorking/FirstSafetyCircuit';
import TotmanMode from './Components/VehicleWorking/TrainDriving/TotmanMode';
import TrainDriving from './Components/VehicleWorking/TrainDriving/TrainDriving';
import VehicleWorking from './Components/VehicleWorking/VehicleWorking';
import WarningVehicle from './Components/VehicleWorking/WarningVehicle';
import WCTrain from './Components/WCTrain';
import WCTrainFaults from './Components/WCTrainFaults';
import Login from './Login';
import NotFoundOne from './NotFoundOne';
import NotFoundTwo from './NotFoundTwo';
import PasswordRemember from './PasswordRemember';
import FaultAnalyst from './Components/FaultAnalyst/FaultAnalyst';
import { UserContextProvider } from './context/UserContext';
import axios from 'axios';
import MyInformation from './Components/BackPage/Information/MyInformation';
import Users from './Components/BackPage/Users.js/Users';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAuthority, selectFaultId, selectLogin, selectUserId } from './store/selectors';
import UserUpdate from './Components/BackPage/Users.js/UserUpdate/UserUpdate';
import Train from './Components/BackPage/Train/Train';
import Service from './Components/BackPage/Services/Service';
import TopFaults from './Components/BackPage/TopFaults/TopFaults';
import SubFault from './Components/BackPage/SubFault/SubFault';
import FaultsBack from './Components/BackPage/Faults/FaultsList';
import ChangePassword from './Components/BackPage/ChangePassword';
import ERTMSLedControl from './Components/ERTMS/ERTMSLedControl';
import CafHtEvc from './Components/ERTMS/CafHtEvc';
import FaultDetail from './Components/BackPage/Faults/FaultDetail';
import FaultUpdate from './Components/BackPage/Information/FaultUpdate';
import HvacFirstPart from './Components/HVAC/HvacFirstPart';
import HeatingCooling from './Components/HVAC/HeatingCooling';
import ManuelWorkandFaults from './Components/HVAC/ManuelWorkandFaults';
import UsersStatistics from './Components/BackPage/UsersStatistics/UsersStatistics';
import ConnectorConnection from './Components/ERTMS/ConnectorConnection';
import EVCSave from './Components/ERTMS/EVCSave';
import ErtmsOnBoard from './Components/ERTMS/ErtmsOnBoard';
import EquipmentControls from './Components/ERTMS/EquipmentControls';
import { ShootingProcess } from './Components/ShootingProcess';

axios.defaults.withCredentials = true;

function App(props) {
  
  return (
    <UserContextProvider>
      <Router>
        <Switch>
          {
            props.login ?
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/sifremi-hatirla" component={PasswordRemember} />
                <Route exact path="/tren-genel-yapi" component={TrainStructure} />
                <Route exact path="/aracin-calistirilmasi-ariza" component={VehicleWorking} />
                <Route exact path="/uyari-tasit-kabin-emniyet" component={WarningVehicle} />
                <Route exact path="/topraklama" component={Ground} />
                <Route exact path="/kabini-aktif-soguk-park" component={CabinActive} />
                <Route exact path="/calistirmadan-önce-genel-muayene" component={BeforeWorking} />
                <Route exact path="/pantograf-hscb" component={Pantograf} />
                <Route exact path="/tren-surulmesi" component={TrainDriving} />
                <Route exact path="/tren-surulmesi-1" component={AuxiliaryBrakeMode} />
                <Route exact path="/tren-surulmesi-2" component={TotmanMode} />
                <Route exact path="/tren-surulmesi-3" component={ABTApplication} />
                <Route exact path="/kapi-acil-durum-fren-devresi" component={DoorEmergencyBrake} />
                <Route exact path="/birincil-ikincil-emniyet-devresi" component={FirstSafetyCircuit} />
                <Route exact path="/acil-atms-bypass" component={EmergencyBypass} />
                <Route exact path="/cer-aktif-bypass" component={CerActiveBypass} />
                <Route exact path="/yardimci-redresör-batarya-sarj-arizasi" component={AuxiliaryrRectifierBattery} />
                <Route exact path="/mp-kirilma-bp-arizasi" component={Pnomatiksystem} />
                <Route exact path="/caf-tren-testleri-abt" component={CafTrainTest} />
                <Route exact path="/servis-ve-acil-fren-testi" component={ServiceBraketestEmerBrake} />
                <Route exact path="/yardimci-dinamik-holding-fren-testi" component={AuxilaryDynamicHoldingBrakeTest} />
                <Route exact path="/wsp-bcu-ariza" component={WSPBCUFaults} />
                <Route exact path="/mvb" component={MVB} />
                <Route exact path="/ip-program" component={IPPrograms} />
                <Route exact path="/harici-besleme" component={ExternalSupply} />
                <Route exact path="/atms-ariza" component={ATMSFaults} />
                <Route exact path="/sicaklik-esikleri" component={HeatThreshold} />
                <Route exact path="/fren-arizalari-1" component={BrakeFaultsOne} />
                <Route exact path="/fren-arizalari-2" component={BrakeFaultsTwo} />
                <Route exact path="/kaliper-yolcu-kapi-izole" component={KaliperPassenger} />
                <Route exact path="/yolcu-giris-kapi-arizalari" component={PassengerEntranceDoors} />
                <Route exact path="/yolcu-kapi-switch" component={PassengerDoorSwitch} />
                <Route exact path="/wc-egitim" component={WCTrain} />
                <Route exact path="/wc-egitim-ariza" component={WCTrainFaults} />
                <Route exact path="/set-soguk" component={ColdSet} />
                <Route exact path="/imdat-modu" component={HelpMode} />
                <Route exact path="/faults" component={Faults} />
                <Route exact path="/bilgilerim" component={MyInformation} />
                <Route exact path="/changepassword" component={ChangePassword} />
                <Route exact path="/ertmsledcontrol" component={ERTMSLedControl} />
                <Route exact path="/connector-connection" component={ConnectorConnection} />
                <Route exact path="/evc-save" component={EVCSave} />
                <Route exact path="/ertms-onboard" component={ErtmsOnBoard} />
                <Route exact path="/equipment-controls" component={EquipmentControls} />
                <Route exact path="/cafht65000evclog" component={CafHtEvc} />
                <Route exact path="/hvac-sogutma-dongusu-sigorta-kontrol" component={HvacFirstPart} />
                <Route exact path="/hvac-sogutma-isitma" component={HeatingCooling} />
                <Route exact path="/hvac-manuel-faults" component={ManuelWorkandFaults} />
                <Route exact path={"/fault-detail/" + props.faultId} component={FaultDetail} />
                <Route exact path={"/fault-update/" + props.faultId} component={FaultUpdate} />
                <Route exact path="/emergency-status-shooting-process" component={ShootingProcess} />
                {props.authority === "Manager" ?
                  <>
                    <Route exact path="/fault-analyst" component={FaultAnalyst} />
                    <Route exact path="/users" component={Users} />
                    <Route exact path={"/user/update/" + props.userId} component={UserUpdate} />
                    <Route exact path="/users/statistics" component={UsersStatistics} />
                    <Route exact path={"/trains"} component={Train} />
                    <Route exact path="/services" component={Service} />
                    <Route exact path="/topfaults" component={TopFaults} />
                    <Route exact path="/subfaults" component={SubFault} />
                    <Route exact path="/faults-back" component={FaultsBack} />
                  </> : null}

                <Route exact component={NotFoundOne} />
              </Switch> :
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/sifremi-hatirla" component={PasswordRemember} />
                <Route exact component={NotFoundTwo} />
              </Switch>
          }

        </Switch>
      </Router>
    </UserContextProvider>

  );
}

const mapStateToProps = createStructuredSelector({
  userId: selectUserId(),
  authority: selectAuthority(),
  login:selectLogin(),
  faultId:selectFaultId(),
});

export default connect(mapStateToProps)(App);
