import React, { Component } from 'react'
import Footer from '../Footer';
import HeaderNav from '../HeaderNav';
import Navbar from '../Navbar';
import LeftSide from './LeftSide';
import TrainList from './TrainList';

class Train extends Component {
    render() {
        return (
            <div>
            <Navbar pageWrapId={"page-wrap"} />

            <div id="page-wrap">
                <HeaderNav />
                <div>
                    <div className='page-header'>
                        Trenler
                    </div>
                    
                    <div className='users'>
                       <LeftSide />
                       <TrainList />
                    </div>
                </div>
            </div >
            <Footer/>
        </div >
        )
    }
}
export default Train;