import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectFaultId } from '../../../store/selectors'
import Footer from '../Footer';
import HeaderNav from '../HeaderNav';
import Navbar from '../Navbar';

function FaultUpdate(props) {
    const [trainlist, setTrainList] = useState([]);
    const [faultlist, setTopFaultList] = useState([]);
    const [subFault, setSubFault] = useState([]);
    const [services, setServices] = useState([]);
    const [sFault, setSFault] = useState("");
    const [service, setService] = useState("");
    const [train, setTrain] = useState("");
    const [tFault, setTFault] = useState("");
    const [explain, setExplain] = useState("");
    const [userName, setUserName] = useState("");
    const [message, setMessage] = useState("");
    const [date, setDate] = useState("");
    const [rotakm, setRotaKm] = useState("0");
    const [rotakmdurum, setRotaKmDurum] = useState(false);
    const [vagon, setVagon] = useState("-");
    const [addMessage, setAddMessage] = useState(false);
    let sub = [];
    useEffect(() => {
        Services();
    }, []);

    const Services = async () => {
        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/service/list")
            .then(res => setServices(res.data))
            .catch(() => {
                window.location.replace("/login")
            });

        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/topIssue/list").then(res => {
            setTopFaultList(res.data)
            sub = res.data
        }).catch(() => {
            window.location.replace("/login")
        });

        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/train/list").then(res => {
            setTrainList(res.data)
        }).catch(() => {
            window.location.replace("/login")
        });


        await axios.get(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/list/${props.faultId}`)
            .then(res => {
                setTrain(res.data[0].trainNumber);
                setService(res.data[0].serviceNumber);
                setTFault(res.data[0].topFault);

                sub = sub.filter(uf => uf._id === res.data[0].topFault);

                if (sub[0]) {
                    sub[0].name === "ERTMS" || sub[0].name === "ATS" ? setRotaKmDurum(true) : setRotaKmDurum(false); setRotaKm("0"); setVagon("-")
                    setSubFault(sub);
                }

                setSFault(res.data[0].subFault);
                setExplain(res.data[0].explaining);
                setDate(res.data[0].date.split("-").reverse().join("-"));
                setRotaKm(res.data[0].rotaKm);
                setVagon(res.data[0].vagon);
                setUserName(res.data[0].userName);
            })
    }

    const onChangeTrain = e => {
        setTrain(e.target.value)
    }

    const onChangeTopFault = e => {
        setTFault(e.target.value);
        let subf = faultlist.filter(uf => uf._id === e.target.value);
        if (subf[0]) {
            subf[0].name === "ERTMS" || subf[0].name === "ATS" ? setRotaKmDurum(true) : setRotaKmDurum(false); setRotaKm("0"); setVagon("-");
            setSubFault(subf);
        }
        else {
            return null
        }
    }

    const onChangeSubFault = e => {
        setSFault(e.target.value);
    }

    const onChangeService = e => {
        setService(e.target.value);
    }

    const onChangeExplain = e => {
        setExplain(e.target.value);
    }

    const onChangeDate = e => {
        setDate(e.target.value);
    }

    const onChangeRotaKm = e => {
        setRotaKm(e.target.value);
    }

    const onChangeVagon = e => {
        setVagon(e.target.value);
    }

    const saveFault = async (e) => {
        e.preventDefault();
        await axios.put(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/fault/update/${props.faultId}`, {
            userName: userName,
            topFault: tFault,
            subFault: sFault,
            serviceNumber: service,
            trainNumber: train,
            date: date.split("-").reverse().join("-"),
            vagon,
            rotaKm: rotakm,
            explaining: explain,
        }).then(() => {
            setAddMessage(true);
        }).catch(err => {
            if (err.response.data.errorMessage === "Hata") {
                window.location.replace("/login")
            }
            else {
                setMessage("Arıza güncellenemedi.")
            }
        });
    }

    return (
        <div>
            <Navbar pageWrapId={"page-wrap"} />
            <div id="page-wrap">
                <HeaderNav />
                <div>
                    <div className='page-header'>
                        Arıza Güncelle
                    </div>

                    <div className='users'>
                        <div className='fault-back'>
                            <div className='my-fault'>
                                <div className='part-header'>
                                    Arıza Güncelle
                                </div>
                                <div className='part-body'>

                                    <form onSubmit={saveFault}>
                                        {addMessage ? <div className="alert alert-success mt-3">Arıza başarıyla güncellenmiştir.</div> : <div></div>}
                                        <div className="form-group row">
                                            <label htmlFor="Train" className="col-sm-3 col-form-label">
                                                Tren Numarası :
                                            </label>
                                            <div className="col-sm-8" >
                                                <select className="col-sm-12 form-select p-2" id="Train"
                                                    value={train} onChange={onChangeTrain} required>
                                                    <option value="">Seçiniz</option>
                                                    {
                                                        trainlist.map(train => {
                                                            return (
                                                                <option key={train._id} value={train._id}>{train.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="TopFault" className="col-sm-3 col-form-label">
                                                Üst Arıza :
                                            </label>
                                            <div className="col-sm-8" >
                                                <select className="col-sm-12 form-select p-2" id="TopFault"
                                                    value={tFault} onChange={onChangeTopFault} required>
                                                    <option value="">Seçiniz</option>
                                                    {
                                                        faultlist.map(fault => {
                                                            return (
                                                                <option key={fault._id} value={fault._id}>{fault.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="sFault" className="col-sm-3 col-form-label">
                                                Alt Arıza :
                                            </label>
                                            <div className="col-sm-8" >
                                                <select className="col-sm-12 form-select p-2" id="sFault"
                                                    onChange={onChangeSubFault} value={sFault} required>
                                                    <option value="">Seçiniz</option>
                                                    {
                                                        subFault[0] ? subFault[0].subIssue.map(sI => (
                                                            <option key={sI._id} value={sI.name}>{sI.name}</option>
                                                        )) : null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            rotakmdurum ? <div className="form-group row">
                                                <label htmlFor="RotaKm" className="col-sm-3 col-form-label">
                                                    Km:
                                                </label>
                                                <div className="col-sm-8 mt-1">
                                                    <input type="text" className="form-control" id="RotaKm" name="rotakm" value={rotakm}
                                                        onChange={onChangeRotaKm} maxLength="3" minLength="1" required />
                                                </div>
                                            </div> : null
                                        }

                                        {
                                            rotakmdurum ? <div className="form-group row">
                                                <label htmlFor="Vagon" className="col-sm-3 col-form-label">
                                                    Aktif Kabin:
                                                </label>
                                                <div className="col-sm-8 mt-1">
                                                    <select className="col-sm-12 form-select p-2" id="Vagon"
                                                        required value={vagon} onChange={onChangeVagon} >
                                                        <option value="-">Seçiniz</option>
                                                        <option key="1" value="TCB">TCB</option>
                                                        <option key="2" value="TCF">TCF</option>
                                                    </select>
                                                </div>
                                            </div> : null
                                        }

                                        <div className="form-group row">
                                            <label htmlFor="Service" className="col-sm-3 col-form-label">
                                                Servis Rotası :
                                            </label>
                                            <div className="col-sm-8" >
                                                <select className="col-sm-12 form-select p-2" id="Service"
                                                    required value={service} onChange={onChangeService} >
                                                    <option value="">Seçiniz</option>
                                                    {
                                                        services.map(service => (
                                                            <option key={service._id} value={service._id}>{service.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="Explain" className="col-sm-3 col-form-label">
                                                Açıklama :
                                            </label>
                                            <div className="col-sm-8" >
                                                <textarea className="col-sm-12" rows="6" value={explain} onChange={onChangeExplain} required />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="Date" className="col-sm-3 col-form-label">
                                                Tarih :
                                            </label>
                                            <div className="col-sm-8" >
                                                <input type="date" className="col-sm-12" rows="6" value={date} onChange={onChangeDate} required />
                                            </div>
                                        </div>

                                        <div className=" row text-right mt-4">
                                            <div className="col-sm-11">
                                                <button type='submit' className="btn right-part-button">
                                                    Kaydet
                                                </button>
                                            </div>
                                        </div>
                                        {message ? <div className="alert alert-danger mt-3">{message}</div> : <div></div>}
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </div >

    )
}
const mapStateToProps = createStructuredSelector({
    faultId: selectFaultId(),
});

export default connect(mapStateToProps)(FaultUpdate);