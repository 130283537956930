import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectPopupInfo } from '../../../store/selectors';

function LeftPopup(props) {
    const { fault } = props
    return (
        <div className="modal fade" id={fault ? "o" + fault._id : ""} role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                    <div className="modal-header mx-auto modal-header-part">
                        <span className="modal-header-part-span">
                            <i className="fa fa-subway mr-1 ml-0" style={{ fontSize: "20px" }} />
                            Sorun Detayı
                        </span>

                        <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                            ×
                        </button>
                    </div>

                    <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>
                        {
                            fault ? <div className='p-3'>
                                <p><b>Kullanıcı Adı : </b>{`${fault.userInformation[0].name} ${fault.userInformation[0].surname}`}</p>
                                <p><b>Tren Numarası : </b>{fault.trainInformation[0].name}</p>
                                <p><b>Servis Numarası : </b>{`${fault.serviceInformation[0].name}`}</p>
                                <p><b>Üst Arıza : </b>{fault.topFaultInformation[0].name}</p>
                                <p><b>Alt Arıza : </b>{fault.subFault}</p>
                                <p><b>Aktif Kabin : </b>{fault.vagon}</p>
                                <p><b>Km : </b>{fault.rotaKm}</p>
                                <p><b>Tarih: </b>{fault.date}</p>
                                <p><b>Açıklama : </b>{fault.explaining}</p>
                            </div> : <p>Veriler yükleniyor.</p>
                        }
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">
                            Kapat
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = createStructuredSelector({

    fault: selectPopupInfo(),
})

export default connect(mapStateToProps)(LeftPopup);