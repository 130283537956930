import React, { Component } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar'

class BeforeWorking extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="w-75 mx-auto p-3 font-weight-bold">
                    <h2>
                        ÇALIŞTIRMADAN ÖNCE GENEL MUAYENE
                    </h2>
                    <ul>
                        <li>
                            Trenin yakınında çalışan kimse olmadığından emin olun.
                        </li>
                        <li>
                            Tekerleklerde sıkışma ve trenin altında raylarda engel olup olmadığını kontrol edin.
                        </li>
                        <li>
                            Vagonların altında yağ sızıntısı olup olmadığını kontrol edin.
                        </li>
                        <li>
                            Vagonların dış tarafında hasar ve pencerelerin iyi durumda olup olmadığını kontrol edin.
                        </li>
                        <li>
                            Bojinin mekanik elemanlarını görsel olarak kontrol edin. yan kısımların açıldığında gevşek olmadığını ve iyi durumda olduğunu doğrulayın.
                        </li>
                        <li>
                            Fren çenelerinde gevşek veya kırık parçalar olup olmadığını kontrol edin. Dişli ünitelerinde yağ seviyesini (görsel olarak) kontrol edin
                        </li>
                        <li>
                            Özellikle soğuk veya yağmurlu havalarda, kumlama kutularının dolu olduğunu kontrol edin. Değilse, yeniden doldurun.
                        </li>
                        <li>
                            Flanş yağ tanklarında yağ seviyesini kontrol edin, gerekiyorsa tekrar doldurun.
                        </li>
                        <li>
                            Tüm yan etekler ve otomatik kapağın kapalı ve istek dışı açılmayacak şekilde kilitli olduğunu kontrol edin.
                        </li>
                        <li>
                            Tüm iç kapaklar ve dolapların kapalı ve istek dışı açılmayacak şekilde kilitli olduğunu kontrol edin.
                        </li>
                    </ul>
                </div>
                <Footer />
            </div>
        )
    }
}
export default BeforeWorking;
