import { useEffect } from 'react'

export default function NotFoundOne() {
    useEffect(()=>{
        window.location.replace("/")
    },[])
    return (
       null
    )
}
