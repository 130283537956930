import React, { Component } from 'react'
import Footer from '../../../Footer';
import Navbar from '../../../Navbar'


class DoorEmergencyBrake extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="w-75 mx-auto p-3">
                    <h4>
                        Kapı Devresi:
                    </h4>
                    <ul>
                        <li>
                        Kapı devresi trendeki tüm kapıların durumu hakkında bilgi toplar.
                        </li>
                        <li>
                        Trenin harekete geçebilmesi için tüm kapılar kapalı ve katlanır basamaklar çekilmiş pozisyonda olmalıdır. Sürücü masası ATS panelinde bulunan KAPILAR KAPALI göstergesi (53H01) devreye sokulmalıdır.
                        </li>
                        <li>
                        Kapı devresi çalışmazsa yani kapı kapatılamazsa, acil bir durumda harekete devam için KAPI SİNYAL İPTAL baypas anahtarı (53S01) kullanılabilir.
                        </li>
                    </ul>

                    <h4>
                        Acil Durum Fren Devresi:
                    </h4>
                    <ul>
                        <li>
                        SIFA valfları deaktive edildiğinde, BP boş olur ve acil durum freni tatbik edilir.
                        </li>
                        <li>
                        <b>Acil durum devresi üç ayrı devreden oluşur:</b>
                        <ul>
                            <li>
                            	Birincil emniyet devresi.
                            </li>
                            <li>
                            	İkincil emniyet devresi.
                            </li>
                            <li>
                            	Acil durum freni tren hatları.
                            </li>
                        </ul>
                        </li>
                        <li>
                        Kapı devresi çalışmazsa yani kapı kapatılamazsa, acil bir durumda harekete devam için KAPI SİNYAL İPTAL baypas anahtarı (53S01) kullanılabilir.
                        </li>
                        <li>
                        Tren durduktan ve sevk/fren kolu Bmax pozisyonuna getirildikten sonra, acil durum freni mod seçici kolu N (nötr) pozisyonuna getirilerek ve sonra FOR (ileri) veya REV (geri) pozisyonuna geçirilerek sıfırlanır.
                        </li>
                    </ul>

                    
                    <h4>
                        Yolcu Acil Durum Alarm Devresi:
                    </h4>
                    <ul>
                        <li>
                            Yolcunun sürücü ile iletişimde bulunabilmesi için PEA’ya entegre interkom aktive olur.
                        </li>
                        <li>
                            Alarm çekildikten sonra on saniye içinde alarm iptale basılmalıdır,  basılmazsa acil durum freni tatbik edilir.
                        </li>
                        <li>
                            Alarm İptale bastıktan sonra trenin güvenli şekilde durması için 5 dk süresi vardır.5 dk.sonra fren uygulanır.
                        </li>
                        <li>
                            Yolcu Acil Durum Alarmı aktive edildiğinde tren bir tünelden geçmekteyse, ERTMS acil durum freninin uygulanmasını önler
                        </li>
                    </ul>
                    </div>
                    <Footer/>
                </div>
        )
    }
}
export default DoorEmergencyBrake;