import React, { Component } from 'react'
import { MDBDataTable } from 'mdbreact';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserId } from '../../../store/actions';
import UserContext from '../../../context/UserContext';
import axios from 'axios';
 
class UsersList extends Component {

    static contextType = UserContext;

    state = {
        "users": [],
        "Message": "",
    }

    componentDidMount = async () => {

        await axios.get("https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/list/")
            .then(async (res) => {
                this.setState({
                    users: res.data.filter(u => u._id !== this.context.user).sort((a, b) => {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1
                        }
                        return 0
                    })
                })
            }).catch(()=>{
                window.location.replace("/login")
            })
    }

    onClickDelete = async (id) => {
        axios.delete(`https://tms-common-23a342c1d4d6.herokuapp.com/onboard/user/delete/${id}`)
            .then(res => {
                this.setState({
                    ...this.state,
                    users: this.state.users.filter(u => u._id !== id),
                    Message: res.data.Message
                })
            })
    }

    onClickUpdate = async (id) => {
       await this.props.setUserId(id)
        window.location.replace("/user/update/" + id)
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Kullanıcı Adı',
                    field: 'username',
                    sort: 'asc',

                },
                {
                    label: 'İsim-Soyisim',
                    field: 'name',
                    sort: 'asc',
                },
                {
                    label: 'İşlemler',
                    field: 'operation',
                    sort: 'asc',
                }
            ],

            rows: this.state.users.map(i => {
                return {
                    username: i.username,
                    name: i.name + " " + i.surname,
                    operation: <>
                        <button className='btn delete-button mr-2' onClick={this.onClickDelete.bind(this, i._id)}>
                            Sil
                        </button>
                        <button className='btn update-button mr-2' onClick={this.onClickUpdate.bind(this, i._id)}>
                            Detay
                        </button>
                    </>
                }
            })
        };

        return (
            <div className='back-right'>
                <div className='my-fault'>
                    <div className='part-header'>
                        Kullanıcı Listesi
                    </div>
                    <div className='part-body'>
                        {this.state.Message ? <div className="alert alert-success">{this.state.Message}</div> : <div></div>}
                        <MDBDataTable
                            entriesOptions={[5, 10, 25]}
                            hover
                            striped
                            bordered
                            data={data}
                            searchLabel='Filtrele'
                            entrieslabel='aasdasdasd'
                            infoLabel={["", "-", "sonuç", ""]}
                            paginationLabel={["Geri", "İleri"]}
                            noRecordsFoundLabel='Sonuç bulunamadı'
                            noBottomColumns={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => (
    (
        bindActionCreators(
            { setUserId }, dispatch
        )
    )
)
export default connect( null,mapDispatchToProps)(UsersList);